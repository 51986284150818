import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface Props {
  disabled: boolean;
  isFocused: boolean;
  hasValue: boolean;
  hasBackground: boolean;
  smallPlaceholder: boolean;
  inputSize: "small" | "medium" | "large";
}

export const Box = styled.div`
  position: relative;
`;

export const SearchWrapper = styled.div<{ size: "small" | "medium" | "large" }>(
  ({ size }) => css`
    height: ${size === "small" ? "17px" : size === "medium" ? "24px" : "30px"};
    position: absolute;
    top: ${size === "large" ? "14px" : "6px"};
    left: ${size === "large" ? "16px" : "8px"};
  `
);

export const CustomInput = styled.input<Props>(
  ({ theme, disabled, hasBackground, smallPlaceholder, inputSize }) => css`
    font-size: 13px;
    width: 100%;
    border: none;
    padding: ${inputSize === "large"
      ? "16px 16px 16px 52px"
      : "8px 24px 8px 36px"};
    border-radius: ${inputSize === "large" ? "100px" : "6px"};
    outline: none;
    z-index: 1;

    background-color: ${hasBackground
      ? theme.colors.white
      : theme.colors.grey100};
    color: ${theme.colors.black};
    &::placeholder {
      ${theme.fonts.HelveticaLight}
      color: ${theme.colors.grey600};
      font-size: ${smallPlaceholder ? "11px" : "14px"};
      font-weight: 400;
      line-height: 20px;
    }
    ${disabled &&
    css`
      color: ${theme.colors.grey};
      cursor: not-allowed;
    `}
  `
);
