import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import axiosInstance from "../../serveces/interceptor";
import config from "../../utils/config";
import {
  AccountMappingGroup,
  MappingItemRequest,
  MappingItemType,
  MappingItemWithGroupRequest,
} from "./mapping.types";

export const GET_REPORTING_INFO = "GET_REPORTING_INFO";
export const CREATE_ACCOUNT_MAPPING_GROUP = "CREATE_ACCOUNT_MAPPING_GROUP";
export const CREATE_ACCOUNT_MAPPING_WITH_GROUP =
  "CREATE_ACCOUNT_MAPPING_WITH_GROUP";
export const CREATE_MAPPING_ITEM = "CREATE_MAPPING_ITEM";
export const EDIT_MAPPING_GROUP = "EDIT_MAPPING_GROUP";
export const EDIT_MAPPING_ITEM = "EDIT_MAPPING_ITEM";
export const DELETE_MAPPING_GROUP = "DELETE_MAPPING_GROUP";
export const DELETE_MAPPING_ITEM = "DELETE_MAPPING_ITEM";
export const ORDERING_MAPPING_GROUPS = "ORDERING_MAPPING_GROUPS";
export const ORDERING_MAPPING_ITEMS = "ORDERING_MAPPING_ITEMS";
export const ACCOUNT_AUTO_MAPPING = "ACCOUNT_AUTO_MAPPING";

export const getMappingList = createAsyncThunk(GET_REPORTING_INFO, async () => {
  const response: AxiosResponse = await axiosInstance.get(
    config.api.endpoints.reporting.getList
  );

  return response.data;
});

export const createAccountMappingGroup = createAsyncThunk(
  CREATE_ACCOUNT_MAPPING_GROUP,
  async (data: { name: string; accountMappingTypeId: number }) => {
    const response: AxiosResponse<AccountMappingGroup> =
      await axiosInstance.post(
        config.api.endpoints.reporting.createAccountMappingGroup,
        {
          ...data,
        }
      );
    return response.data;
  }
);

export const accountsAutoMapping = createAsyncThunk(
  ACCOUNT_AUTO_MAPPING,
  async () => {
    const response: AxiosResponse<AccountMappingGroup> =
      await axiosInstance.post(config.api.endpoints.reporting.autoMapping);
    return response.data;
  }
);

export const createAccountMappingWithGroup = createAsyncThunk(
  CREATE_ACCOUNT_MAPPING_WITH_GROUP,
  async (data: MappingItemWithGroupRequest) => {
    const response: AxiosResponse<AccountMappingGroup> =
      await axiosInstance.post(
        config.api.endpoints.reporting.createAccountMappingWithGroup,
        {
          ...data,
        }
      );
    return response.data;
  }
);

export const createMappingItem = createAsyncThunk(
  CREATE_MAPPING_ITEM,
  async (data: MappingItemRequest) => {
    const response: AxiosResponse<MappingItemType> = await axiosInstance.post(
      config.api.endpoints.reporting.createMappingItem,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const editMappingGroup = createAsyncThunk(
  EDIT_MAPPING_GROUP,
  async (data: { id: number; name: string }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.reporting.editMappingGroup,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const editMappingItem = createAsyncThunk(
  EDIT_MAPPING_ITEM,
  async (data: {
    id: number;
    name: string;
    accountId: string;
    creditRePortalMappingId: number | null;
    debitRePortalMappingId: number | null;
    accountMappingGroupId: number | null;
    companyBranchId: number | null;
    companyBusinessLineId: number | null;
    accountMappingCashFlowCashInId: number | null;
    accountMappingCashFlowCashOutId: number | null;
  }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.reporting.editMappingItem,
      {
        ...data,
      }
    );
    return response.data;
  }
);

export const deleteMappingGroup = createAsyncThunk(
  DELETE_MAPPING_GROUP,
  async ({
    accountMappingTypeId,
    groupId,
  }: {
    accountMappingTypeId: number;
    groupId: number;
  }) => {
    await axiosInstance.delete(
      config.api.endpoints.reporting.deleteMappingGroup,
      { data: { id: groupId } }
    );
    return { groupId, accountMappingTypeId };
  }
);

export const deleteMappingItem = createAsyncThunk(
  DELETE_MAPPING_ITEM,
  async ({
    accountMappingTypeId,
    sectionId,
    id,
  }: {
    accountMappingTypeId: number;
    sectionId: number;
    id: number;
  }) => {
    await axiosInstance.delete(
      config.api.endpoints.reporting.deleteMappingItem,
      { data: { id } }
    );

    return { id, sectionId, accountMappingTypeId };
  }
);

// ordering
export const changeGroupsOrder = createAsyncThunk(
  ORDERING_MAPPING_GROUPS,
  async (data: { accountMappingTypeId: number; ids: Array<number> }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.reporting.changeOrderingGroups,
      {
        accountMappingTypeId: data.accountMappingTypeId,
        ids: data.ids,
      }
    );
    return response.data;
  }
);

// Item ordering
export const changeItemsOrder = createAsyncThunk(
  ORDERING_MAPPING_ITEMS,
  async (data: { ids: Array<number>; groupId: number }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.reporting.changeOrderingItems,
      {
        accountMappingGroupId: data.groupId,
        ids: data.ids,
      }
    );
    return response.data;
  }
);
