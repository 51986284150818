import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Language,
  LanguageWrapper,
  Selected,
  Wrapper,
} from "./SwitchLanguageMobile.style";

import { useAppSelector } from "../../../store";
import { Languages } from "../../../enums/enum";
import Icon from "../../shared/icon/Icon";

interface LanguageSwitcherMobileProps {
  handleChangeLanguage: (lang: Languages) => void;
}

const LanguageSwitcherMobile = ({
  handleChangeLanguage,
}: LanguageSwitcherMobileProps) => {
  const { t } = useTranslation();
  const language = useAppSelector((state) => state.user.language);

  const [switchLanguage, setSwitchLanguage] = useState<"en" | "ge">(language);

  return (
    <Wrapper>
      <Selected language={switchLanguage} />
      <LanguageWrapper
        onClick={() => {
          setSwitchLanguage("en");
          handleChangeLanguage(Languages.En);
        }}
      >
        <Icon iconName="en" />
        <Language>{t("Language.English")}</Language>
      </LanguageWrapper>
      <LanguageWrapper
        onClick={() => {
          setSwitchLanguage("ge");
          handleChangeLanguage(Languages.Ge);
        }}
      >
        <Icon iconName="ge" />
        <Language>{t("Language.Georgia")}</Language>
      </LanguageWrapper>
    </Wrapper>
  );
};

export default LanguageSwitcherMobile;
