import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ active: boolean }>(
  ({ theme, active }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-right: 1px solid ${theme.colors.grey300};
    position: relative;
    ${theme.fonts.HelveticaNeue}
    @media (max-width: ${theme.breakpoints.xl}) {
      ${!active && "padding: 24px 20px;"}
    }
  `
);

export const ArrowWrapper = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    position: absolute;
    z-index: 10;
    background-color: ${theme.colors.white};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    right: -17px;
    top: 40px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease-in-out;

    transform: rotate(-90deg);
    &.rotate {
      transform: rotate(90deg);
    }
  `
);

export const SideBarNavigationItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

export const StraightBorder = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.grey300};
    margin-bottom: 15px;
    height: 1px;
    width: 100%;
  `
);
