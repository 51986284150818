import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    overflow: auto;
    height: calc(100% - 81px);
    position: absolute;
    background: ${theme.colors.grey100};
    left: 0;
    top: 81px;
    z-index: 999;
    ${theme.fonts.HelveticaNeue}
    @media (max-width: ${theme.breakpoints.xl}) {
      height: calc(100% - 73px);
      top: 73px;
    }
  `
);

export const ProgressWrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    height: 4px;
    width: 100%;
    background-color: ${theme.colors.grey200};
  `
);
export const ProgressLine = styled.div<{
  totalPageNumber: number;
  progress: number;
}>(
  ({ theme, totalPageNumber, progress }) => css`
    width: calc(${progress}*(100% / ${totalPageNumber}));
    height: inherit;
    background-color: ${theme.colors.primary600};
    transition: width 0.5s ease-in-out;
  `
);

export const Section = styled.div`
  width: 416px;
  margin: auto;
  margin-top: 48px;
  padding-bottom: 20px;
`;
