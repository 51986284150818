import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store";

import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import Loader from "../../../../../components/shared/loader/Loader";
import {
  CustomTable,
  ExportExcelWrapper,
  TableRow,
  TableRowItem,
  Wrapper,
} from "./FinancialDetailed.style";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import {
  downloadFile,
  generatePreviousYearsData,
} from "../../../../../helpers/helper";
import { formatNumber } from "../../../../../helpers/dashbordHelper";
import moment from "moment";
import Button from "../../../../../components/shared/button/Button";
import { useTranslation } from "react-i18next";
import {
  getFinanceStandingDetailedReport,
  getFinanceStandingFileUrl,
} from "../../../../../store/report/reportAction";

const headerArray = [
  "FinancialDetailed.AccountId",
  "FinancialDetailed.AccountName",
  "FinancialDetailed.RePortalMappingName",

  "FinancialDetailed.Amount",
];

const FinanceDetailed = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);

  const [year, setYear] = useState<string>(
    moment().subtract(1, "year").format("YYYY")
  );

  const loading = useAppSelector((state) => state.dashboard.loading);
  const selectCompany = useAppSelector((state) => state.user.selectedCompany);

  const financeDetailedData = useAppSelector(
    (state) => state.report.reportal.financeDetailed
  ).slice(0, 200);

  useEffect(() => {
    dispatch(getFinanceStandingDetailedReport(+year));
  }, [dispatch, year, selectCompany]);

  const handleFileDownload = () => {
    setFileDownloadLoading(true);
    dispatch(getFinanceStandingFileUrl({ year: +year }))
      .then(({ payload }) => {
        downloadFile(payload as string);
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      });
  };

  const submit = ({ year: currentYear }: { year?: number | undefined }) => {
    if (currentYear) {
      setYear(currentYear.toString());
    }
  };

  return (
    <>
      <Wrapper>
        <ReportFilter
          activeYear={+year}
          handleSubmit={submit}
          years={generatePreviousYearsData(1)}
        />
        <ExportExcelWrapper>
          <Button
            isLoading={fileDownloadLoading}
            onClick={handleFileDownload}
            title={t("Export.FileExport")}
          ></Button>
        </ExportExcelWrapper>
        {loading === "pending" ? (
          <Loader />
        ) : (
          <CustomTable>
            <TableHeader headerNames={headerArray} />
            <tbody>
              {financeDetailedData.map((item, index) => (
                <TableRow key={index}>
                  <TableRowItem>{item.accountId}</TableRowItem>
                  <TableRowItem>{item.accountName}</TableRowItem>
                  <TableRowItem>{item.rePortalMappingName}</TableRowItem>
                  <TableRowItem>{formatNumber(item.amount)}</TableRowItem>
                </TableRow>
              ))}
            </tbody>
          </CustomTable>
        )}
      </Wrapper>
    </>
  );
};

export default FinanceDetailed;
