import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.p(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: 20px;
  `
);
