import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface PageNumberProps {
  selected: boolean;
}
interface PageControllerProps {
  disabled: boolean;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    background-color: ${theme.colors.primaryLight};
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin: 10px 0 0;
    gap: 10px;
  `
);
export const PageController = styled.p<PageControllerProps>(
  ({ theme, disabled }) => css`
    ${theme.fonts.AvenirDemi}
    cursor: pointer;
    font-size: 13px;
    padding: 7px 4px;
    color: ${disabled ? theme.colors.grey : theme.colors.black};
  `
);
export const PageNumberWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const PageNumber = styled.div<PageNumberProps>(
  ({ theme, selected }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 13px;
    cursor: pointer;
    padding: 7px 12px;
    background-color: ${selected ? theme.colors.primary : theme.colors.white};
    color: ${selected ? theme.colors.white : theme.colors.black};
    border-radius: 8px;
  `
);
