import React, { useState } from "react";

import { FAQArrayData } from "../FaqQuestionContent";
import {
  Answer,
  Arrow,
  Question,
  TitleWrapper,
  Wrapper,
} from "./QuestionItem.style";

interface FAQQuestionProps {
  data: Array<FAQArrayData>;
}

const QuestionItem = ({ data }: FAQQuestionProps) => {
  const [openIndex, setOpenIndex] = useState(-1);

  const handleClick = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
  };
  return (
    <>
      {data.map((item, index) => (
        <Wrapper
          key={index}
          open={openIndex === index}
          onClick={() => handleClick(index)}
        >
          <TitleWrapper>
            <Question>{item.question}</Question>
            <div>
              <Arrow opened={openIndex === index} />
            </div>
          </TitleWrapper>

          <Answer opened={openIndex === index}>{item.answer}</Answer>
        </Wrapper>
      ))}
    </>
  );
};

export default QuestionItem;
