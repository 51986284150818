import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 43px 0 38px;
  font-size: 14px;
`;
export const EmailWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
export const EmailButtonContainer = styled.div`
  width: 90px;
`;
export const EmailItem = styled.div`
  flex: 1;
`;
export const ErrorMassage = styled.div(
  ({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.orangeBackground};
    display: flex;
    gap: 12px;
    padding: 8px 16px;
    border-radius: 15px;
    align-items: center;
  `
);

export const ErrorMassageText = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.orange};
    ${theme.fonts.AvenirMedium}
    font-size: 12px;
  `
);
export const SvgWrapper = styled.div``;
