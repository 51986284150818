import React, { useEffect } from "react";
import FAQContainer from "../../../components/desktop/faq/FaqQuestionContent";
import { SubTitle, Title, Wrapper } from "./FAQ.style";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchFAQ } from "../../../store/user/userAction";
import Loader from "../../../components/shared/loader/Loader";

const FAQ = () => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.user.loading);

  const faqData = useAppSelector((state) => state.user.FAQ);

  useEffect(() => {
    dispatch(fetchFAQ());
  }, [dispatch]);

  const { t } = useTranslation();

  if (loading === "pending") {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Title>{t("FAQ.Title")}</Title>
      <SubTitle>{t("FAQ.SubTitle")}</SubTitle>
      <FAQContainer faqData={faqData} />
    </Wrapper>
  );
};

export default FAQ;
