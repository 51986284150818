import React from "react";
import { useNavigate } from "react-router-dom";
import { PageURL } from "../../../../constants/router";
import { useAppDispatch } from "../../../../store";
import { logout } from "../../../../store/user/userSlice";
import { useTranslation } from "react-i18next";
import PersonalDataItem from "./personalDataitem/PersonalDataItem";

interface PersonalInfoProps {
  handleClose: () => void;
}

const PersonalInfo = ({ handleClose }: PersonalInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      <PersonalDataItem
        handleClick={() => {
          navigate(PageURL.PersonalInfo.path);
          handleClose();
        }}
        title={t("PersonalData.PersonalInformation")}
        iconName="user"
      />
      <PersonalDataItem
        handleClick={() => {
          navigate(PageURL.CompanyInfo.path);
          handleClose();
        }}
        title={t("PersonalData.CompanyInfo")}
        iconName="company"
      />
      <PersonalDataItem
        handleClick={() => {
          navigate(PageURL.UserManagement.path);
          handleClose();
        }}
        title={t("PersonalData.UserManagement")}
        iconName="people"
      />
      <PersonalDataItem
        handleClick={() => {
          navigate(PageURL.UpdatePassword.path);
          handleClose();
        }}
        title={t("PersonalData.UpdatePassword")}
        iconName="password"
      />
      <PersonalDataItem
        isLogOut
        handleClick={handleLogout}
        title={t("PersonalData.LogOut")}
        iconName="logOut"
      />
    </div>
  );
};

export default PersonalInfo;
