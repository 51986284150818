import React from "react";

import Options from "../../../../shared/options/Options";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Icon from "../../../../shared/icon/Icon";
import { Title, Wrapper } from "./CompanyBranchTable.style";
import { CompanyBranchType } from "../../../../../store/company/company.types";

interface CompanyBranchTableBodyProps {
  companyBranches: Array<CompanyBranchType>;
  handleOpenEditModal: (object: CompanyBranchType) => void;
  handleDeleteModal: (id: number) => void;
  handleOnDragEnd: (value: DropResult) => void;
}
const CompanyBranchTable = ({
  companyBranches,
  handleOpenEditModal,
  handleDeleteModal,
  handleOnDragEnd,
}: CompanyBranchTableBodyProps) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="Branch">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {companyBranches.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided) => (
                  <>
                    <Wrapper
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <div>
                        <Icon iconName="drag" height={17} width={17} />
                      </div>
                      <Title>{item.name}</Title>
                      <Options
                        handleDelete={() => handleDeleteModal(item.id)}
                        handleEdit={() => handleOpenEditModal(item)}
                        showDeleteItem={true}
                        isMobile={false}
                      />
                    </Wrapper>
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CompanyBranchTable;
