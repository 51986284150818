import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 30px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
