import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const SearchWrapper = styled.div(
  ({ theme }) => css`
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.colors.grey200};
  `
);

export const CompanyWrapper = styled.div<{ hasItem: boolean }>(
  ({ hasItem }) => css`
    overflow: scroll;
    padding-left: ${hasItem ? "24px" : "0"};
    height: 450px;
  `
);
interface ActiveProps {
  active: boolean;
}
export const CompanyItem = styled.div<ActiveProps>(
  ({ theme, active }) => css`
    padding: 12px 16px 12px 0;
    border-bottom: 1px solid ${theme.colors.grey200};
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      background-color: ${active ? "inherit" : theme.colors.grey100};
    }
  `
);

export const CompanyContent = styled.p<ActiveProps>(
  ({ theme, active }) => css`
    margin: 0;
    ${theme.fonts.HelveticaLight}
    font-size: 13px;
    max-width: 200px;
    color: ${active ? theme.colors.primary600 : theme.colors.black005};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  `
);
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CheckWrapper = styled.div`
  padding-right: 6px;
`;

export const IconWrapper = styled.div`
  height: 24px;
`;

export const NoResultWrapper = styled.div`
  height: 100%;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const NoResultTitle = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 13px;
    line-height: 20px;
  `
);
export const NoResultSubTitle = styled.h6(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.grey600};
    font-size: 11px;
    line-height: 18px;
    text-align: center;
  `
);
