import * as Yup from "yup";

export const initialValues = {
  email: "",
};

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("ResetPasswordError.EmailError")
    .required("ResetPasswordError.Required"),
});
