import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  max-width: 450px;
`;

export const Title = styled.div(
  ({ theme }) => css`
    font-size: 18px;
    ${theme.fonts.AvenirDemi}
    padding-bottom: 40px;
  `
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: ${theme.breakpoints.xl}) {
      margin: auto;
      max-width: 500px;
    }
  `
);

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;
