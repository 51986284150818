import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: 30px 0 30px 65px;
    @media (max-width: ${theme.breakpoints.xl}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
    }
    & > form {
      @media (max-width: ${theme.breakpoints.xl}) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  `
);

export const Title = styled.h3(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
  `
);

export const SubTitle = styled.h3(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium}
    font-size: 12px;
    color: ${theme.colors.grey};
    margin: 6px 0 60px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 6px 0 40px;
    }
  `
);
