import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 38px;
`;
export const Title = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    font-size: 20px;
    color: ${theme.colors.textPrimary};
    padding: 38px 0 24px;
  `
);
export const Container = styled.div`
  max-width: 800px;
  margin: auto;
`;
