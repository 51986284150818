import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 0 30px;
  `
);
export const TableRow = styled.div(
  ({ theme }) => css`
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyBorder};
  `
);
export const TableWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 1fr;
    }
  `
);
