import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    display: flex;
    flex-direction: column;
    gap: 6px;
  `
);

export const InputWrapper = styled.div`
  position: relative;
`;
export const Label = styled.label<{
  isFocused: boolean;
  isDisabled: boolean;
  hasError: boolean;
}>(
  ({ theme, isFocused, isDisabled, hasError }) => css`
    ${theme.fonts.HelveticaLight}
    cursor: text;
    position: absolute;
    bottom: ${isFocused ? "73%" : "50%"};
    left: 16px;
    transform: translate(0, 50%);
    font-size: ${isFocused ? "9px" : "12px"};
    line-height: 20px;
    transition: bottom 0.3s ease, font-size 0.3s ease, transform 0.3s ease;
    ${hasError
      ? css`
          color: ${theme.colors.negative600};
        `
      : css`
          color: ${isDisabled
            ? theme.colors.grey500
            : theme.colors.textSecondary};
        `}
  `
);

export const CustomInput = styled.input<{
  isDisabled: boolean;
  hasReadOnly: boolean;
  hasError: boolean;
  dotted: boolean;
  hasPlaceholder: boolean;
}>(
  ({ theme, isDisabled, hasReadOnly, hasError, hasPlaceholder, dotted }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${isDisabled ? theme.colors.grey500 : theme.colors.textPrimary};
    outline: none;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    height: ${hasPlaceholder ? "48px" : "56px"};
    padding: ${hasPlaceholder ? "7px 16px 8px" : "20px 16px 8px"};
    border-radius: 8px;
    align-items: center;
    ${dotted
      ? css`
          border: 1px dashed
            ${hasError ? theme.colors.negative600 : theme.colors.greyShade200};
        `
      : css`
          border: 1px solid
            ${hasError ? theme.colors.negative600 : theme.colors.greyShade200};
        `}

    ${hasReadOnly &&
    css`
      background-color: ${theme.colors.greyShade50};
      &:hover {
        background-color: ${theme.colors.greyShade100};
      }
    `}



  ${hasError
      ? css`
          background-color: ${theme.colors.negativeShade50};
          &:hover {
            background-color: ${theme.colors.negativeShade100};
            border: 1px solid ${theme.colors.negative700};
          }
          &:focus {
            border: 1px solid ${theme.colors.negative600};
          }
          &:focus:hover {
            border: 1px solid ${theme.colors.negative700};
          }
        `
      : css`
          background-color: inherit;
        `}
     ${!isDisabled &&
    !hasError &&
    css`
      &:hover {
        border: 1px solid ${theme.colors.greyShade300};
      }
      &:focus {
        border: 1px solid ${theme.colors.primary600}; /* Use border instead of outline */
      }
      &:focus:hover {
        border: 1px solid ${theme.colors.primary800}; /* Adjust for hover during focus */
      }
    `}
  `
);

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const IconWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.negative600};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export const ErrorText = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.negative600};
    font-size: 10px;
    line-height: 14px;
  `
);

export const CheckIconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
