import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    padding: 12px 16px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${theme.colors.grey200};
    border: 1px solid ${theme.colors.grey300};
    cursor: pointer;
    margin-bottom: 16px;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.grey300};
        border: 1px solid ${theme.colors.grey400};
      }
    `}
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    height: 32px;
    padding: 4px;
    background-color: ${theme.colors.white};
    border-radius: 6px;
  `
);

interface ActiveProps {
  active: boolean;
}

export const CompanyShowWrapper = styled.div<ActiveProps>(
  ({ active, theme }) => css`
    padding-left: ${active ? "8px" : "0"};
    overflow: hidden;
    width: ${active ? "209px" : "0"};
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${theme.breakpoints.xl}) {
      width: ${active ? "181px" : "0"};
    }
  `
);

// TODO add Color
export const Title = styled.div(
  ({ theme }) => css`
    color: #5c5c5c;
    font-size: 10px;
    ${theme.fonts.HelveticaLight}
  `
);

export const TitleWrapper = styled.div(
  ({ theme }) => css`
    max-width: 165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${theme.breakpoints.xl}) {
      max-width: 140px;
    }
  `
);

export const SubTitle = styled.span(
  ({ theme }) => css`
    line-height: 20px;
    color: ${theme.colors.black005};
    font-size: 12px;
    font-weight: 600;
  `
);
export const UnfoldMoreIconWrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.grey400};
      }
    `}
  `
);

export const CompanyWrapper = styled.div(
  () => css`
    position: absolute;
    background-color: white;
    z-index: 2;
    overflow-y: scroll;
    width: 324px;
    height: 312px;
    top: 68px;
    left: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  `
);

export const SearchWrapper = styled.div(
  ({ theme }) => css`
    padding: 16px;
    border-bottom: 1px solid ${theme.colors.grey200};
  `
);
export const CompanyItem = styled.div<ActiveProps>(
  ({ theme, active }) => css`
    padding: 12px 16px 12px 0;
    border-bottom: 1px solid ${theme.colors.grey200};
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      background-color: ${active ? "inherit" : theme.colors.grey100};
    }
  `
);

export const CompanyContent = styled.p<ActiveProps>(
  ({ theme, active }) => css`
    margin: 0;
    ${theme.fonts.HelveticaLight}
    font-size: 13px;
    max-width: 200px;
    color: ${active ? theme.colors.primary600 : theme.colors.black005};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  `
);
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckWrapper = styled.div`
  padding-right: 6px;
`;

export const CompanyItemWrapper = styled.div<{ padding: number | null }>(
  ({ theme, padding }) => css`
    height: 237px;
    padding-left: ${padding ? "16px" : "0"};
    background: ${theme.colors.white};
  `
);
export const NoResultWrapper = styled.div`
  height: 100%;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const NoResultTitle = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
  `
);
export const NoResultSubTitle = styled.h6(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.grey600};
    font-size: 11px;
    line-height: 18px;
    text-align: center;
  `
);

export const IconHeightWrapper = styled.div`
  height: 24px;
`;
