import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ReactComponent as LoadingSvg } from "../../../assets/svgs/loadingSvg.svg";

export const LoaderIcon = styled(LoadingSvg)(
  ({ theme }) => css`
    fill: ${theme.colors.primary};
  `
);

export const Wrapper = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.primary};

    .loading-svg {
      margin-right: 3px;
      animation: spin 1s linear infinite;
    }
  `
);
