import { css } from "@emotion/react";
import styled from "@emotion/styled";

// TODO color
export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: #fbfbfb;
  `
);

export const ListItem = styled.div<{ hasBorder: boolean }>(
  ({ theme, hasBorder }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 24px;
    padding: 16px 32px 16px 0;

    ${hasBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.grey003};
    `}
  `
);

export const ListTitle = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    font-size: 14px;
    line-height: normal;
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;
