import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as Dots } from "../../../assets/svgs/dots.svg";
import { IUniversalColors } from "../../../types/theme";

interface EditStyleProps {
  show: boolean;
}

export const Wrapper = styled.div``;

export const DotsWrapper = styled.div<EditStyleProps>(
  ({ theme, show }) => css`
    background-color: ${show ? theme.colors.black : theme.colors.lightGrey};
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: min-content;
    cursor: pointer;
  `
);

export const DotsSvg = styled(Dots)<EditStyleProps>(
  ({ theme, show }) => css`
    fill: ${show ? theme.colors.white : theme.colors.black};
  `
);

export const PopUpContent = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.colors.lightGrey};
    border-radius: 10px;
    padding: 8px 6px;
    z-index: 10;
    ${isMobile &&
    css`
      right: 20px;
    `}
  `
);
export const OptionItemWrapper = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 5px 7px;
    cursor: pointer;
    border-radius: 10px;
    min-width: 95px;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.primaryLight};
      }
    `}
  `
);

export const OptionItem = styled.div<{ color?: keyof IUniversalColors }>(
  ({ theme, color }) => css`
    color: ${color ? theme.colors[color] : theme.colors.black};
  `
);
