import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { chartColors } from "../../../../constants/color";

interface GroupBarChartProps {
  chartData: { data: Array<{ [key: string]: number }>; labels: Array<string> };
}

const renderCustomLabel = (props: any) => {
  const { x, y, width, value } = props;

  const textX = x + width / 2; // Centering the text horizontally
  let textY; // Centering the text vertically

  if (value < 0) {
    textY = y + 10;
  } else {
    textY = y - 10;
  }

  const formattedText = `${value.toFixed(1)}%`;

  if (width < 25) return null;

  return (
    <text
      x={textX}
      y={textY}
      fill="#000"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p className="label">{`Period: ${label}`}</p>
        {payload.map((entry: any, index: number) => (
          <p key={`tooltip-item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value.toFixed(1)}%`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const formatYAxis = (tickItem: number) => `${tickItem.toFixed(1)}%`;

const PercentageBarChart = ({ chartData }: GroupBarChartProps) => {
  if (!chartData?.data) return null;

  return (
    <ResponsiveContainer width={"100%"} height={460}>
      <BarChart width={600} height={460} data={chartData?.data}>
        <XAxis dataKey="label" reversed />
        <YAxis padding={{ top: 20 }} tickFormatter={formatYAxis} />
        <Tooltip content={<CustomTooltip />} />
        {chartData?.labels.map((key: string, index: number) => (
          <Bar
            key={key + index}
            dataKey={key}
            legendType="circle"
            fill={chartColors[0]}
          >
            <LabelList
              fill="#000"
              content={renderCustomLabel}
              dataKey={key}
              position="top"
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PercentageBarChart;
