import React, { MouseEvent } from "react";
import { CustomTextButton } from "./TextButton.style";
import { ReactComponent as download } from "../../../assets/svgs/download.svg";
import { ReactComponent as pluse } from "../../../assets/svgs/plus.svg";
import { ReactComponent as smallPlus } from "../../../assets/svgs/smallPlus.svg";
import { isMobile } from "react-device-detect";

const CustomIcons = {
  download,
  pluse,
  smallPlus,
};

type IconNameOptions = keyof typeof CustomIcons;

interface textButtonProps {
  title: string;
  fontSize?: number;
  isDisable?: boolean;
  iconName?: IconNameOptions;
  handleClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}

const TextButton = ({
  title,
  iconName,
  fontSize = 12,
  isDisable = false,
  handleClick,
}: textButtonProps) => {
  if (iconName) {
    const SVGComponent = CustomIcons[iconName];
    return (
      <CustomTextButton
        isMobile={isMobile}
        fontSize={fontSize}
        type="button"
        disabled={isDisable}
        onClick={handleClick}
      >
        <SVGComponent />
        {title}
      </CustomTextButton>
    );
  }
  return (
    <CustomTextButton
      isMobile={isMobile}
      fontSize={fontSize}
      type="button"
      disabled={isDisable}
      onClick={handleClick}
    >
      {title}
    </CustomTextButton>
  );
};

export default TextButton;
