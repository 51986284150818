import React from "react";
import { LoaderIconWrapper } from "./TableSkeleton.style";
import { ReactComponent as LoaderSvg } from "../../../assets/svgs/tableLoader.svg";
import TableSkeletonRow from "./tableSkeletonRow/TableSkeletonRow";

const TableSkeleton = () => {
  return (
    <>
      <LoaderIconWrapper>
        <tr>
          <td>
            <LoaderSvg className="loading-svg" />
          </td>
        </tr>
      </LoaderIconWrapper>
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
      <TableSkeletonRow />
    </>
  );
};

export default TableSkeleton;
