import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface Clicked {
  clicked: boolean;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: 15px;
    background-color: ${theme.colors.white};
    overflow: hidden;
  `
);
export const List = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    font-size: 14px;
    padding: 20px 14px;
    gap: 20px;
    border-bottom: 1px solid ${theme.colors.lightGrey};
    &:last-child {
      border-radius: 0;
    }
  `
);

export const Item = styled.div<Clicked>(
  ({ theme, clicked }) => css`
    display: flex;
    flex: 1;
    justify-content: space-between;
    border: 1px solid
      ${clicked ? theme.colors.primary : theme.colors.greyBorder};
    border-radius: 15px;
    padding: 13px 12px;
    background-color: ${clicked ? theme.colors.primaryLight : "none"};
    min-width: 130px;
    align-items: center;
  `
);

export const ItemLabel = styled.span`
  overflow: hidden;
`;

export const BottomWrapper = styled.div`
  padding: 14px 14px 20px;
`;
