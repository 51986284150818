import React, { useState } from "react";
import {
  List,
  Item,
  Wrapper,
  BottomWrapper,
} from "./SelectChartTypeMobile.style";
import { RadioButton } from "../../shared/accountItem/AccountItem.style";
import { BottomSheetType, ChartGroupTypes } from "../../../enums/enum";
import Button from "../../shared/button/Button";
import { useTranslation } from "react-i18next";

interface SelectChartTypeMobileProps {
  chartGroupeType?: ChartGroupTypes;
  setChartGroupType: (type: ChartGroupTypes) => void;
  handleClick: (type: BottomSheetType) => void;
}
const SelectChartTypeMobile = ({
  chartGroupeType,
  setChartGroupType,
  handleClick,
}: SelectChartTypeMobileProps) => {
  const { t } = useTranslation();
  const [chartViewType, setChartViewType] = useState(chartGroupeType);
  const [isChanged, setIsChanged] = useState(false);

  return (
    <>
      <Wrapper>
        <List>
          <Item
            clicked={chartViewType === ChartGroupTypes.Grouped}
            onClick={() => {
              setIsChanged(true);
              setChartViewType(ChartGroupTypes.Grouped);
            }}
          >
            {t("ChartFilter.Grouped")}
            <RadioButton
              type="radio"
              id="grouped"
              name="chartType"
              checked={chartViewType === ChartGroupTypes.Grouped}
              onChange={() => {}}
            />
          </Item>
          <Item
            clicked={chartViewType === ChartGroupTypes.Detailed}
            onClick={() => {
              setIsChanged(true);
              setChartViewType(ChartGroupTypes.Detailed);
            }}
          >
            {t("ChartFilter.Detailed")}
            <RadioButton
              type="radio"
              id="detailed"
              name="chartType"
              checked={chartViewType === ChartGroupTypes.Detailed}
              onChange={() => {}}
            />
          </Item>
        </List>
      </Wrapper>
      <BottomWrapper>
        <Button
          isDisabled={!isChanged}
          title={t("ChartFilter.ButtonTitle")}
          onClick={() => {
            if (isChanged && chartViewType) {
              setChartGroupType(chartViewType);
              handleClick(BottomSheetType.FILTER);
            }
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default SelectChartTypeMobile;
