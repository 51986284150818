import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface Props {
  opened?: boolean;
}
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SelectField = styled.div`
  position: relative;
  cursor: pointer;
`;

export const SelectWrapper = styled.div<Props>(
  ({ theme, opened }) => css`
    border: 1px solid ${theme.colors.greyShade200};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    ${opened
      ? css`
          border: 1px solid ${theme.colors.primary600};
          &:hover {
            border: 1px solid ${theme.colors.primary800};
          }
        `
      : css`
          &:hover {
            border: 1px solid ${theme.colors.greyShade300};
          }
        `}
  `
);

export const ActionWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
  `
);

export const HeaderWrapper = styled.div<{ opened: boolean }>(
  ({ theme, opened }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${opened ? "11px 16px 8px" : "20px 16px 8px"};
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.p<{ isDisabled: boolean }>(
  ({ theme, isDisabled }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${isDisabled ? theme.colors.grey500 : theme.colors.textPrimary};
    font-size: 12px;
    line-height: 20px;
    max-width: 318px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const OptionsBorder = styled.div<{
  opened?: boolean;
  reversed?: boolean;
}>(
  ({ opened, reversed, theme }) => css`
    position: absolute;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
    ${reversed
      ? css`
          bottom: 60px;
        `
      : css`
          top: 60px;
        `}

    z-index: 1;
    max-height: 180px;
    width: 100%;
    display: ${opened ? "block" : "none"};
    animation: selectMenu 0.1s ease-in-out forwards;
    transform-origin: center center;
    @keyframes selectMenu {
      0% {
        scale: 0.7;
      }

      100% {
        scale: 1;
      }
    }
  `
);

export const OptionsWrapper = styled.div<Props>(
  ({ opened, theme }) => css`
    overflow-y: auto;
    background: ${theme.colors.white};
    max-height: 180px;
  `
);
export const OptionItemWrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile, theme }) => css`
    padding-left: 16px;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.lightGrey};
      }
    `}
  `
);

export const OptionItem = styled.p<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => css`
    ${theme.fonts.HelveticaLight}
    padding: ${isSelected ? "8px 16px 8px 0" : "14px 16px 14px 0"};
    margin-top: 0px;
    border-bottom: 1px solid ${theme.colors.grey100};
    color: ${isSelected ? theme.colors.primary600 : theme.colors.black005};
    font-size: 13px;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

export const Arrow = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  transition: transform 0.3s ease-in-out;
  &.rotate {
    transform: rotate(-180deg);
  }
`;
export const CloseIconWrapper = styled.div`
  height: 20px;
  padding-top: 1px;
`;

export const Label = styled.label<{
  opened?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
}>(
  ({ theme, isDisabled, hasError, opened }) => css`
    ${theme.fonts.HelveticaLight}
    cursor: pointer;
    font-size: ${opened ? "9px" : "12px"};

    ${hasError
      ? css`
          color: ${theme.colors.negative600};
        `
      : css`
          color: ${isDisabled
            ? theme.colors.grey500
            : theme.colors.textSecondary};
        `}
  `
);

export const TitleText = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: 20px;
  `
);

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const IconWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.negative600};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

export const ErrorText = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.negative600};
    font-size: 10px;
    line-height: 14px;
  `
);

export const SearchInput = styled.input<{ isHidden?: boolean }>(
  ({ isHidden }) => css`
    cursor: ${isHidden ? "none" : "text"};
    background: transparent;
    font-size: 13px;
    width: ${isHidden ? "0px" : "100%"};
    outline: none;
    border: none;
  `
);
export const InputWrapper = styled.div`
  display: flex;
`;
