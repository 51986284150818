import React from "react";
import { User } from "../../../../store/user/user.types";
import Table from "../../../shared/table/Table";
import TableSkeleton from "../../../shared/tableSkeleton/TableSkeleton";
import UserManagementTableBody from "../userManagementTableBody/UserManagementTableBody";

interface UserManagementTableProps {
  data: Array<any>;
  userRoles: Array<{ value: string; label: string }>;
  userStatues: Array<{ value: string; label: string }>;
  loadingIds: Array<number>;
  loading: boolean;
  tableHeaders: Array<string>;
  updateUserRole: (user: User, roleId: string) => void;
  updateUserStatus: (user: User, statusId: string) => void;
}

const UserManagementTable = ({
  data,
  userStatues,
  userRoles,
  loadingIds,
  loading,
  tableHeaders,
  updateUserRole,
  updateUserStatus,
}: UserManagementTableProps) => {
  return (
    <Table headerNames={tableHeaders}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <UserManagementTableBody
          data={data}
          userStatues={userStatues}
          userRoles={userRoles}
          loadingIds={loadingIds}
          updateUserRole={updateUserRole}
          updateUserStatus={updateUserStatus}
        />
      )}
    </Table>
  );
};

export default UserManagementTable;
