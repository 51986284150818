import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 30px 0 60px;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 20px 0;
    }
  `
);

export const Title = styled.h1(
  ({ theme }) => css`
    font-size: 18px;
    ${theme.fonts.AvenirDemi};

    @media (max-width: ${theme.breakpoints.lg}) {
      font-size: 16px;
    }
  `
);
export const UploadFileWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 67px;
    gap: 30px;

    @media (max-width: ${theme.breakpoints.xl}) {
      justify-content: center;
      gap: 20px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      flex-wrap: wrap;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0px 0px 37px;
    }
  `
);

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropDownWrapper = styled.div`
  width: 200px;
`;

export const Results = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 12px;
    color: ${theme.colors.grey};
    @media (max-width: ${theme.breakpoints.lg}) {
      font-size: 10px;
    }
  `
);
