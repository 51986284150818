import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: 32px 174px;
    ${theme.fonts.HelveticaNeue}
  `
);

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  `
);
export const SubTitle = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.grey700};
    font-size: 12px;
    line-height: 18px;
  `
);
export const FooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Action = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 590px;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContentTitle = styled.p(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: 20px;
  `
);
