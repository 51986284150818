import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h4(
  ({ theme }) => css`
    font-size: 16px;
    ${theme.fonts.AvenirDemi};
  `
);
export const Description = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium};
    color: ${theme.colors.grey};
    font-size: 12px;
    margin: 10px 0 23px;
  `
);
export const ButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const AlertWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
