import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h4(
  ({ theme }) => css`
    font-size: 16px;
    ${theme.fonts.AvenirDemi};
  `
);

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const AlertWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
