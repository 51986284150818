import React, { MouseEvent, ReactNode } from "react";
import { Label, Wrapper } from "./Button.style";
import {
  ButtonColorsType,
  ButtonSizesType,
  ButtonType,
  ButtonVariantsType,
} from "./ButtonType";

interface ButtonProps {
  label: string;
  size: ButtonSizesType;
  variant: ButtonVariantsType;
  color?: ButtonColorsType;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  label,
  size,
  type,
  variant,
  color = "success",
  disabled,
  loading,
  startIcon,
  endIcon,
  onClick,
}: ButtonProps) => {
  return (
    <Wrapper
      size={size}
      variant={variant}
      color={color}
      disabled={Boolean(disabled || loading)}
      type={type}
      onClick={onClick}
    >
      {startIcon}
      <Label> {label}</Label>
      {endIcon}
    </Wrapper>
  );
};

export default Button;
