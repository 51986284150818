import { useState } from "react";
import { Wrapper, MainList, MainListItem } from "./Reports.style";

import { useTranslation } from "react-i18next";
import RevenueReports from "./revenueReports/RevenueReports";
import ExpensesReports from "./expensesReports/ExpensesReports";
import ReportalReports from "./reportalReports/ReportalReports";
import CompensationReports from "./compensationReports/CompensationReports";

const enum ReportsType {
  REVENUE = "REVENUE",
  EXPENSES = "EXPENSES",
  REPORTAL = "REPORTAL",
  LABOR_COMPENSATION = "LABOR_COMPENSATION",
}
const Reports = () => {
  const { t } = useTranslation();
  const [openList, setOpenList] = useState<ReportsType>(ReportsType.REVENUE);

  return (
    <Wrapper>
      <MainList>
        <MainListItem
          clicked={openList === ReportsType.REVENUE}
          onClick={() => {
            setOpenList(ReportsType.REVENUE);
          }}
        >
          {t("Report.Revenue")}
        </MainListItem>
        <MainListItem
          clicked={openList === ReportsType.EXPENSES}
          onClick={() => {
            setOpenList(ReportsType.EXPENSES);
          }}
        >
          {t("Report.Expenses")}
        </MainListItem>
        <MainListItem
          clicked={openList === ReportsType.REPORTAL}
          onClick={() => {
            setOpenList(ReportsType.REPORTAL);
          }}
        >
          {t("Report.Reportal")}
        </MainListItem>
        <MainListItem
          clicked={openList === ReportsType.LABOR_COMPENSATION}
          onClick={() => {
            setOpenList(ReportsType.LABOR_COMPENSATION);
          }}
        >
          {t("Report.Compensation")}
        </MainListItem>
      </MainList>

      {openList === ReportsType.REVENUE && <RevenueReports />}
      {openList === ReportsType.EXPENSES && <ExpensesReports />}
      {openList === ReportsType.REPORTAL && <ReportalReports />}
      {openList === ReportsType.LABOR_COMPENSATION && <CompensationReports />}
    </Wrapper>
  );
};

export default Reports;
