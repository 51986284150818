import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  TitleWrapper,
  RightSide,
  Content,
  Title,
  Wrapper,
} from "./WorkOutcome.style";
import ReportalGroup from "../shared/ReportalGroup";
import ReportalItem from "../shared/ReportalItem";

import { ReportalDataType } from "../Reportal";
import Loader from "../../../../components/shared/loader/Loader";

const getPastThreeYears = (year: number) => {
  return [year, year - 1];
};

interface WorkOutcomeProps {
  activeYear: string;
  loading: boolean;
  workOutcomeData: Array<ReportalDataType>;
}
const WorkOutcome = ({
  activeYear,
  loading,
  workOutcomeData,
}: WorkOutcomeProps) => {
  const { t } = useTranslation();
  const reportYears = useMemo(
    () => getPastThreeYears(+activeYear),
    [activeYear]
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <TitleWrapper length={reportYears.length}>
        <Title>{t("WorkOutcome.Title")}</Title>
        <RightSide>
          {reportYears.map((year) => (
            <Content key={year}>{year}</Content>
          ))}
        </RightSide>
      </TitleWrapper>
      <Wrapper>
        {workOutcomeData.map((item, index) => (
          <div key={item.id}>
            {item.isSum ? (
              <ReportalGroup
                length={reportYears.length}
                title={item.name}
                firstAmount={
                  item.data.find(
                    (dataItem: any) => dataItem.year === reportYears[0]
                  )?.amount || 0
                }
                secondAmount={
                  item.data.find(
                    (dataItem: any) => dataItem.year === reportYears[1]
                  )?.amount || 0
                }
              />
            ) : (
              <ReportalItem
                length={reportYears.length}
                title={item.name}
                firstAmount={
                  item.data.find(
                    (dataItem: any) => dataItem.year === reportYears[0]
                  )?.amount || 0
                }
                secondAmount={
                  item.data.find(
                    (dataItem: any) => dataItem.year === reportYears[1]
                  )?.amount || 0
                }
                hasBorder={!workOutcomeData[index + 1]?.isSum}
              />
            )}
          </div>
        ))}
      </Wrapper>
    </div>
  );
};

export default WorkOutcome;
