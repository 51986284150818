import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    background-color: ${theme.colors.white};
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  `
);
export const Title = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 22px;
    line-height: normal;
    margin: 34px 0 24px;
  `
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    padding: 24px;
    border-bottom: 1px solid ${theme.colors.grey003};
  `
);
export const Container = styled.div`
  max-width: 960px;
  margin: auto;
  margin-bottom: 70px;
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
`;
