import React from "react";
import ExcelImage from "../../../../assets/images/excel.png";

import {
  ButtonItem,
  CategoryItem,
  FileImage,
  FileName,
  Loading,
  StatusItem,
  StatusItemWrapper,
  TableRow,
  TableRowItem,
  FileWrapper,
  TimeItem,
  UserEmail,
  UserName,
  YearWrapper,
} from "./ImportDataTableBody.style";

import TextButton from "../../../shared/textButton/TextButton";
import { ReportItem } from "../../../../store/report/report.types";
import {
  fileNameFormat,
  formatStatus,
  formatType,
} from "../../../../helpers/importDataHelper";

import TableItemLoader from "../../../shared/tableItemLoader/TableItemLoader";
import { ExcelFileStatus } from "../../../../enums/enum";
import Tooltip from "../../../shared/tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../helpers/helper";

interface RecordItemsProps {
  data: Array<ReportItem>;
  loadingIds: Array<number>;
  handleDownload: (id: number) => void;
}

const TableBody = ({ data, loadingIds, handleDownload }: RecordItemsProps) => {
  const { t } = useTranslation();
  return (
    <tbody>
      {data.map((item) => (
        <TableRow key={item.id}>
          <TableRowItem>
            <UserName>
              {item.client.firstName} {item.client.lastName}
            </UserName>
            <UserEmail>{item.client.email}</UserEmail>
          </TableRowItem>
          <TimeItem>{formatDate(item.createdAt)}</TimeItem>
          <TableRowItem>
            <StatusItemWrapper>
              <StatusItem status={formatStatus(item.status)}>
                <p>{formatStatus(item.status)}</p>
              </StatusItem>
              {item.status === ExcelFileStatus.Error ||
                (item.status === ExcelFileStatus.PartiallyUploaded && (
                  <Tooltip text={item.errorText} />
                ))}
            </StatusItemWrapper>
          </TableRowItem>

          <TableRowItem>
            <FileWrapper>
              <FileImage
                src={ExcelImage}
                alt={t("ImportDataTableBody.ExcelImageAlt")}
              />
              <FileName>{fileNameFormat(item.path)}</FileName>
            </FileWrapper>
          </TableRowItem>

          <YearWrapper>{Boolean(item.year) && item.year}</YearWrapper>

          <CategoryItem>{formatType(item.type)}</CategoryItem>
          <ButtonItem>
            {loadingIds.includes(item.id) ? (
              <Loading>
                <TableItemLoader />
              </Loading>
            ) : (
              <TextButton
                title={t("ImportDataTableBody.TextButtonTitle")}
                isDisable={false}
                handleClick={() => {
                  handleDownload(item.id);
                }}
                iconName={"download"}
              />
            )}
          </ButtonItem>
        </TableRow>
      ))}
    </tbody>
  );
};

export default TableBody;
