import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getRevenueInfo } from "../../../../store/dashboard/dashboardAction";
import {
  Wrapper,
  ChartTitle,
  GridContainer,
  GroupedChartWrapper,
  BarChartGrouper,
  BarChartGrouperTitle,
  TabsWrapper,
  Header,
  LastUpdated,
} from "./Revenue.style";

import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import DonutChart from "../../../../components/desktop/charts/donutChart/DonutChart";
import {
  dashboardDateFormat,
  generatePeriodTypes,
} from "../../../../helpers/helper";
import { BottomSheetType, ChartGroupTypes } from "../../../../enums/enum";
import BarChartContainer from "../../../../components/desktop/charts/barChart/BarChart";
import ChartFilter from "../../../../components/desktop/chartFilter/ChartFilter";

import ChartHolder from "../../../../components/shared/chartEmptyHolder/ChartEmptyHolder";
import Loader from "../../../../components/shared/loader/Loader";
import ChartFilterButtonMobile from "../../../../components/mobile/filerButton/FilerButtonMobile";
import { setBottomSheet } from "../../../../store/user/userSlice";
import {
  Filter,
  FrontSideFilter,
} from "../../../../store/dashboard/dashboard.types";
import ChartLabels from "../../../../components/desktop/charts/chartLabels/ChartLabels";
import {
  generateRevenueChartData,
  resetGroupeName,
  updateFilter,
  updateFrontSideFilter,
} from "../../../../store/dashboard/dashboardSlice";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import Tab from "../../../../components/shared/tabs/tab/Tab";

enum RevenueTabs {
  GROUPED = "GROUPED",
  BUSINESS_LINE = "BUSINESS_LINE",
  BRANCHES = "BRANCHES",
}

const Revenue = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loadFilterData, setLoadFilterData] = useState(false);
  const [selectedTab, setSelectedTab] = useState(RevenueTabs.GROUPED);

  const frontSideFilter = useAppSelector(
    (state) => state.dashboard.frontSideFilter
  );

  const filter = useAppSelector((state) => state.dashboard.filter);

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const loadingDashboard = useAppSelector((state) => state.dashboard.loading);

  const groupedChartData = useAppSelector(
    (state) => state.dashboard.revenue.groupedChartData
  );

  const donutChartData = useAppSelector(
    (state) => state.dashboard.revenue.donutChartData
  );

  const barsData = useAppSelector((state) => state.dashboard.revenue.barsData);

  const groupedChartDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedRevenue.groupedChartData
  );

  const donutChartDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedRevenue.donutChartData
  );

  const barsDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedRevenue.barsData
  );

  const groupedChartDataForBranch = useAppSelector(
    (state) => state.dashboard.branchRevenue.groupedChartData
  );

  const donutChartDataForBranch = useAppSelector(
    (state) => state.dashboard.branchRevenue.donutChartData
  );

  const barsDataForBranch = useAppSelector(
    (state) => state.dashboard.branchRevenue.barsData
  );

  const groupedChartDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineRevenue.groupedChartData
  );

  const donutChartDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineRevenue.donutChartData
  );

  const barsDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineRevenue.barsData
  );

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const revenueData = useAppSelector((state) => state.dashboard.revenueData);

  const revenueGroupFilterOptions = useAppSelector(
    (state) => state.dashboard.revenueGroupFilterOptions
  );
  const lastUpdatedTime = revenueData?.lastUpdateTime;

  useEffect(() => {
    if (revenueData) {
      dispatch(generateRevenueChartData());
    }
  }, [dispatch, revenueData, frontSideFilter]);

  useEffect(() => {
    setLoadFilterData(true);
    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];
    Promise.all(promises).finally(() => {
      setLoadFilterData(false);
    });
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    return () => {
      dispatch(resetGroupeName());
    };
  }, [dispatch]);

  useEffect(() => {
    if (filter.fromDate && filter.toDate && filter.periodType && !isMobile) {
      dispatch(getRevenueInfo(filter));
    }
  }, [filter, selectedCompany, dispatch]);

  useEffect(() => {
    dispatch(
      updateFrontSideFilter({
        group: ChartGroupTypes.Grouped,
        lines: [],
        branches: [],
        groupNames: [],
      })
    );
    setSelectedTab(RevenueTabs.GROUPED);
  }, [selectedCompany, dispatch]);

  const memoizedGroupChartData = useMemo(() => {
    if (selectedTab === RevenueTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? groupedChartDataForGroup
        : groupedChartData;
    }

    if (selectedTab === RevenueTabs.BRANCHES) {
      return groupedChartDataForBranch;
    }

    if (selectedTab === RevenueTabs.BUSINESS_LINE) {
      return groupedChartDataForBusinessLine;
    }

    return groupedChartData;
  }, [
    selectedTab,
    frontSideFilter.group,
    groupedChartDataForGroup,
    groupedChartDataForBranch,
    groupedChartDataForBusinessLine,
    groupedChartData,
  ]);

  const memoizedDonutChartData = useMemo(() => {
    if (selectedTab === RevenueTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? donutChartDataForGroup
        : donutChartData;
    }

    if (selectedTab === RevenueTabs.BRANCHES) {
      return donutChartDataForBranch;
    }

    if (selectedTab === RevenueTabs.BUSINESS_LINE) {
      return donutChartDataForBusinessLine;
    }

    return donutChartData;
  }, [
    selectedTab,
    frontSideFilter.group,
    donutChartDataForGroup,
    donutChartDataForBranch,
    donutChartDataForBusinessLine,
    donutChartData,
  ]);

  const memoizedLabels = useMemo(() => {
    if (selectedTab === RevenueTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? groupedChartDataForGroup.labels
        : groupedChartData.labels;
    }

    if (selectedTab === RevenueTabs.BRANCHES) {
      return groupedChartDataForBranch.labels;
    }

    if (selectedTab === RevenueTabs.BUSINESS_LINE) {
      return groupedChartDataForBusinessLine.labels;
    }

    return groupedChartData.labels;
  }, [
    selectedTab,
    frontSideFilter.group,
    groupedChartDataForGroup.labels,
    groupedChartDataForBranch.labels,
    groupedChartDataForBusinessLine.labels,
    groupedChartData,
  ]);

  const handleClick = (tab: RevenueTabs) => {
    setSelectedTab(tab);
  };

  const handleFilter = (values: Filter & FrontSideFilter) => {
    if (
      frontSideFilter.group !== values.group ||
      !_.isEqual(frontSideFilter.lines, values.lines) ||
      !_.isEqual(frontSideFilter.branches, values.branches) ||
      !_.isEqual(frontSideFilter.groupNames, values.groupNames)
    ) {
      setLoadFilterData(true);
      dispatch(
        updateFrontSideFilter({
          group: values.group,
          lines: values.lines,
          branches: values.branches,
          groupNames: values.groupNames,
        })
      );
      setTimeout(() => {
        setLoadFilterData(false);
      }, 500);
    }
    if (
      filter.periodType !== values.periodType ||
      filter.fromDate !== values.fromDate ||
      filter.toDate !== values.toDate
    ) {
      dispatch(
        updateFilter({
          periodType: values.periodType,
          fromDate: values.fromDate,
          toDate: values.toDate,
        })
      );
    }
  };

  return (
    <>
      {loadFilterData || loadingDashboard === "pending" ? (
        <Wrapper>
          <Loader />
        </Wrapper>
      ) : (
        <Wrapper>
          {Boolean(companyBranches.length || companyBusinessLine.length) && (
            <Header>
              <TabsWrapper>
                <Tab
                  title={t("Revenue.ByGroup")}
                  isClicked={selectedTab === RevenueTabs.GROUPED}
                  handleClick={() => {
                    handleClick(RevenueTabs.GROUPED);
                  }}
                />
                {Boolean(companyBranches.length) && (
                  <Tab
                    title={t("Revenue.ByBranches")}
                    isClicked={selectedTab === RevenueTabs.BRANCHES}
                    handleClick={() => {
                      handleClick(RevenueTabs.BRANCHES);
                    }}
                  />
                )}
                {Boolean(companyBusinessLine.length) && (
                  <Tab
                    title={t("Revenue.ByBusinessLine")}
                    isClicked={selectedTab === RevenueTabs.BUSINESS_LINE}
                    handleClick={() => {
                      handleClick(RevenueTabs.BUSINESS_LINE);
                    }}
                  />
                )}
              </TabsWrapper>
              {lastUpdatedTime && (
                <LastUpdated>
                  last updated: {dashboardDateFormat(lastUpdatedTime)}
                </LastUpdated>
              )}
            </Header>
          )}
          {isMobile ? (
            <ChartFilterButtonMobile
              handleClick={() => {
                dispatch(setBottomSheet(BottomSheetType.FILTER));
              }}
            />
          ) : (
            <ChartFilter
              filterData={filter}
              frontSideFilter={frontSideFilter}
              hasGroupBy={selectedTab === RevenueTabs.GROUPED}
              options={generatePeriodTypes()}
              branchOptions={companyBranches}
              lineOptions={companyBusinessLine}
              groupOption={revenueGroupFilterOptions}
              handleSubmit={(values) => {
                handleFilter(values);
              }}
            />
          )}

          {Boolean(memoizedGroupChartData.data.length) ? (
            <>
              <GroupedChartWrapper
                isMobile={isMobile}
                dataLacked={!memoizedGroupChartData.data.length}
              >
                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("Revenue.GroupBarChartTitle")}
                  </ChartTitle>
                  <GroupBarChart
                    stackId="a"
                    chartData={memoizedGroupChartData}
                  />
                </div>
                {isMobile && <ChartLabels labels={memoizedLabels} />}
                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("Revenue.DoughnutChartTitle")}
                  </ChartTitle>
                  <DonutChart chartData={memoizedDonutChartData} />
                </div>
              </GroupedChartWrapper>
              {!isMobile && <ChartLabels labels={memoizedLabels} />}
              <GridContainer
                hasGrid={frontSideFilter.group !== ChartGroupTypes.Grouped}
                isMobile={isMobile}
              >
                {selectedTab === RevenueTabs.GROUPED && (
                  <>
                    {frontSideFilter.group === ChartGroupTypes.Grouped
                      ? barsDataForGroup?.map((item, index: number) => (
                          <div key={index}>
                            {item.data.length ? (
                              <>
                                <ChartTitle isMobile={isMobile}>
                                  {item.labels[0]}
                                </ChartTitle>
                                <BarChartContainer chartData={item} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))
                      : barsData?.map((item, index: number) => (
                          <div key={index}>
                            <BarChartGrouperTitle>
                              {item.label}
                            </BarChartGrouperTitle>
                            <BarChartGrouper key={index}>
                              {item.data.map((itemData, itemIndex) => (
                                <div key={itemIndex}>
                                  {itemData.data.length ? (
                                    <>
                                      <ChartTitle isMobile={isMobile}>
                                        {itemData.labels[0]}
                                      </ChartTitle>
                                      <BarChartContainer chartData={itemData} />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ))}
                            </BarChartGrouper>
                          </div>
                        ))}
                  </>
                )}
                {selectedTab === RevenueTabs.BRANCHES &&
                  barsDataForBranch?.map((item, index: number) => (
                    <div key={index}>
                      {item.data.length ? (
                        <>
                          <ChartTitle isMobile={isMobile}>
                            {item.labels[0]}
                          </ChartTitle>
                          <BarChartContainer chartData={item} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}

                {selectedTab === RevenueTabs.BUSINESS_LINE &&
                  barsDataForBusinessLine?.map((item, index: number) => (
                    <div key={index}>
                      {item.data.length ? (
                        <>
                          <ChartTitle isMobile={isMobile}>
                            {item.labels[0]}
                          </ChartTitle>
                          <BarChartContainer chartData={item} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
              </GridContainer>
            </>
          ) : (
            <ChartHolder title="" />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Revenue;
