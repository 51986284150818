import Button from "../../../shared/button/Button";

import Input from "../../../shared/input/Input";
import PasswordInput from "../../../shared/passwordInput/PasswordInput";
import {
  AlertWrapper,
  InputWrapper,
  InteractionArea,
  Title,
} from "./LoginForm.style";
import TextButton from "../../../shared/textButton/TextButton";

import { Formik, Form, Field } from "formik";

import { useTranslation } from "react-i18next";
import Alert from "../../../shared/alert/Alert";
import { initialValues, LoginSchema } from "./schema";

interface ILoginFormProps {
  isLoading: boolean;
  errorMessage: string;
  handleNavigate: () => void;
  handleSubmit: (values: any) => void;
}

const LoginForm = ({
  isLoading,
  errorMessage,
  handleNavigate,
  handleSubmit,
}: ILoginFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t("Login.Title")}</Title>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form autoComplete="off">
            <InputWrapper>
              <Field
                name="email"
                autoComplete="email"
                label={t("Login.EmailPlaceholder")}
                component={Input}
              />
              <Field
                name="password"
                autoComplete="new-password"
                label={t("Login.PasswordPlaceholder")}
                component={PasswordInput}
              />
            </InputWrapper>
            <InteractionArea>
              <div></div>
              <TextButton
                title={t("Login.TextButtonContent")}
                handleClick={handleNavigate}
              />
            </InteractionArea>

            <Button
              title={t("Login.ButtonText")}
              type="submit"
              isDisabled={false}
              isLoading={isLoading}
            />
            {errorMessage && (
              <AlertWrapper>
                <Alert status="Error" message={errorMessage} />
              </AlertWrapper>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
