import {
  CustomTable,
  LoaderWrapper,
  Results,
  TableRow,
  TableRowItem,
  Title,
  TitleWrapper,
  Wrapper,
} from "./RevenueServices.style";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { useEffect, useState } from "react";
import { getRevenueServices } from "../../../../store/dashboard/dashboardAction";

import TableHeader from "../../../../components/shared/tableHeader/TableHeader";
import Pagination from "../../../../components/shared/pagination/Pagination";
import RevenueServicesMobile from "../../../../components/mobile/revenueService/RevenueServiceMobile";
import Loader from "../../../../components/shared/loader/Loader";

const RevenueServices = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const pageSize = 20;

  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const revenueData = useAppSelector(
    (state) => state.dashboard.revenueServices
  );

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  useEffect(() => {
    setLoader(true);
    dispatch(
      getRevenueServices({
        page: currentPage,
        pageSize,
      })
    ).then(() => {
      setLoader(false);
    });
  }, [dispatch, pageSize, currentPage, selectedCompany]);

  const headerListArray = [
    "RevenueServices.ID",
    "RevenueServices.FullName",
    "RevenueServices.BirthDay",
    "RevenueServices.Citizenship",
    "RevenueServices.Gender",
    "RevenueServices.Phone",
    "RevenueServices.WorkType",
    "",
  ];

  const itemsPerPage = (
    totalItems: number,
    numberOfItemsPerPage: number,
    page: number
  ) => {
    return `Showing ${
      totalItems > page * numberOfItemsPerPage
        ? page * numberOfItemsPerPage
        : totalItems
    }  from ${totalItems} results`;
  };
  return (
    <>
      <Wrapper isMobile={isMobile}>
        <TitleWrapper>
          <Title>{t("RevenueServices.Title")}</Title>
          <Results>
            {itemsPerPage(
              revenueData.count,
              revenueData.pageSize,
              revenueData.page
            )}
          </Results>
        </TitleWrapper>
        {loader ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            {isMobile ? (
              <RevenueServicesMobile
                dataHeaders={headerListArray}
                data={revenueData}
              />
            ) : (
              <CustomTable>
                {Boolean(revenueData.items.length) && (
                  <TableHeader headerNames={headerListArray} />
                )}
                <tbody>
                  {revenueData.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableRowItem>{item.tin}</TableRowItem>
                      <TableRowItem>{item.fullName}</TableRowItem>
                      <TableRowItem>{item.birthDate}</TableRowItem>
                      <TableRowItem>{item.citizenship}</TableRowItem>
                      <TableRowItem>{item.genderTxt}</TableRowItem>
                      <TableRowItem>{item.phone}</TableRowItem>
                      <TableRowItem>{item.workTypeText}</TableRowItem>
                      {/* <TableRowItem>
                        <Button
                          onClick={() => {}}
                          title={t("RevenueServices.ButtonText")}
                          size="small"
                        />
                      </TableRowItem> */}
                    </TableRow>
                  ))}
                </tbody>
              </CustomTable>
            )}
            {Boolean(revenueData.count) && (
              <Pagination
                selectedPage={revenueData.page}
                limit={revenueData.pageSize}
                siblings={1}
                totalPage={Math.ceil(revenueData.count / revenueData.pageSize)}
                handlePage={(pageIndex) => {
                  setCurrentPage(pageIndex);
                }}
              />
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default RevenueServices;
