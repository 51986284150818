import { useState } from "react";
import { FieldInputProps, FormikErrors, FormikTouched } from "formik";

import {
  ClosedEyeIcon,
  CustomInput,
  ErrorIcon,
  ErrorMassage,
  Label,
  OpenEyeIcon,
  Wrapper,
} from "./PasswordInput.style";
import { useTranslation } from "react-i18next";

interface FormikPart<T> {
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
}

interface CustomInputProps extends FieldInputProps<string> {
  form: FormikPart<string>;
  [key: string]: any;
  label: string;
}

const PasswordInput = ({
  field,
  form: { touched, errors },
  label,
  ...props
}: CustomInputProps) => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState("password");
  const [focused, setFocused] = useState(false);

  const handleClick = () => {
    setInputType((prevVal) => (prevVal === "text" ? "password" : "text"));
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <div>
      <Wrapper
        isFocus={focused}
        invalid={Boolean(touched[field.name] && errors[field.name])}
        hasValue={field.value}
        onBlur={() => {
          handleBlur();
        }}
        onFocus={() => {
          handleFocus();
        }}
      >
        <Label
          invalid={Boolean(touched[field.name] && errors[field.name])}
          htmlFor={field.name}
          isFocus={focused}
          hasValue={field.value}
        >
          {label}
        </Label>
        <CustomInput
          id={field.name}
          hasValue={field.value}
          isFocused={focused}
          invalid={touched[field.name] && errors[field.name]}
          type={inputType}
          {...field}
          {...props}
        />
        {inputType === "text" ? (
          <ClosedEyeIcon onClick={handleClick} />
        ) : (
          <OpenEyeIcon onClick={handleClick} />
        )}
      </Wrapper>
      {touched[field.name] && errors[field.name] && (
        <ErrorMassage>
          <ErrorIcon /> {t(errors[field.name])}
        </ErrorMassage>
      )}
    </div>
  );
};

export default PasswordInput;
