import React from "react";
import { useTranslation } from "react-i18next";

import { User } from "../../../store/user/user.types";
import {
  DataWrapper,
  TableContentRow,
  TableUserContainer,
  UserWrapper,
  TableHeaderItem,
  Wrapper,
  UserEmail,
  DropDownItem,
  TableContent,
} from "./UserManagementMobile.style";
import DropDown from "../../shared/dropdown/Dropdown";

import { formatDate } from "../../../helpers/helper";
import TableItemLoader from "../../shared/tableItemLoader/TableItemLoader";

interface UserManagementMobileProps {
  data: Array<User>;
  userRoles: Array<{ value: string; label: string }>;
  userStatues: Array<{ value: string; label: string }>;
  loadingIds: Array<number>;
  headers: Array<string>;
  updateUserRole: (user: User, roleId: string) => void;
  updateUserStatus: (user: User, statusId: string) => void;
}

const UserManagementMobile = ({
  data,
  userRoles,
  userStatues,
  loadingIds,
  headers,
  updateUserRole,
  updateUserStatus,
}: UserManagementMobileProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {data.map((item, index) => (
        <DataWrapper key={index}>
          <TableContentRow>
            <TableHeaderItem>{t(headers[0])}</TableHeaderItem>
            <TableUserContainer>
              <UserWrapper>
                {item.firstName}
                {item.lastName}
              </UserWrapper>
              <UserEmail>{item.email}</UserEmail>
            </TableUserContainer>
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[1])}</TableHeaderItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={
                    item?.clientCompany?.clientRoleId?.toString() || ""
                  }
                  handleSelect={(value) => {
                    updateUserRole(item, value);
                  }}
                  title={""}
                  options={userRoles}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagement.TableItemLoader")} />
            )}
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[2])}</TableHeaderItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={item?.clientCompany?.status.toString() || ""}
                  handleSelect={(value) => {
                    updateUserStatus(item, value);
                  }}
                  title={""}
                  options={userStatues}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagement.TableItemLoader")} />
            )}
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[3])}</TableHeaderItem>
            <TableContent>{formatDate(item.createdAt)}</TableContent>
          </TableContentRow>
          <TableContentRow>
            <TableHeaderItem>{t(headers[4])}</TableHeaderItem>
            <TableContent>
              {item.updatedAt === "0001-01-01T00:00:00"
                ? t("UserManagement.Never")
                : formatDate(item.updatedAt)}
            </TableContent>
          </TableContentRow>
        </DataWrapper>
      ))}
    </Wrapper>
  );
};

export default UserManagementMobile;
