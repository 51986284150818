import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tab from "../../../components/shared/tabs/tab/Tab";
import { TabsWrapper, Wrapper } from "./CompanyTabs.style";

import CompanyInfo from "./companyInfo/CompanyInfo";
import CompanyBranch from "./companyBranches/CompanyBranches";
import CompanyBusinessLine from "./companyBusinessLine/CompanyBusinessLine";

const CompanyTabs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("companyInfo");
  const handleClick = (state: string) => {
    setSelectedTab(state);
  };

  return (
    <Wrapper>
      <TabsWrapper>
        <Tab
          title={t("CompanyTabs.CompanyInfo")}
          isClicked={selectedTab === "companyInfo"}
          handleClick={() => {
            handleClick("companyInfo");
          }}
        />
        <Tab
          title={t("CompanyTabs.CompanyBranch")}
          isClicked={selectedTab === "companyBranch"}
          handleClick={() => {
            handleClick("companyBranch");
          }}
        />
        <Tab
          title={t("CompanyTabs.CompanyBusinessLine")}
          isClicked={selectedTab === "companyBusinessLine"}
          handleClick={() => {
            handleClick("companyBusinessLine");
          }}
        />
      </TabsWrapper>
      {selectedTab === "companyInfo" && <CompanyInfo />}
      {selectedTab === "companyBranch" && <CompanyBranch />}
      {selectedTab === "companyBusinessLine" && <CompanyBusinessLine />}
    </Wrapper>
  );
};

export default CompanyTabs;
