import React, { useState } from "react";
import {
  List,
  Item,
  Wrapper,
  ItemLabel,
  BottomWrapper,
} from "./SelectGroupName.style";
import { RadioButton } from "../../shared/accountItem/AccountItem.style";
import { BottomSheetType } from "../../../enums/enum";
import Button from "../../shared/button/Button";
import { useTranslation } from "react-i18next";

interface SelectGroupNameProps {
  groupNameList: Array<{ value: string; label: string }>;
  groupNames: Array<{ value: string; label: string }>;
  submitBranch: (groupNames: Array<{ value: string; label: string }>) => void;
  handleClick: (type: BottomSheetType) => void;
}
const SelectGroupName = ({
  groupNameList,
  groupNames,
  submitBranch,
  handleClick,
}: SelectGroupNameProps) => {
  const { t } = useTranslation();
  const [selectedGroupNames, setSelectedGroupNames] = useState(groupNames);
  const [isChanged, setIsChanged] = useState(false);

  return (
    <>
      <Wrapper>
        <List>
          {[{ label: t("ChartFilter.All"), value: "0" }, ...groupNameList].map(
            (item) => (
              <Item
                key={item.value}
                clicked={Boolean(
                  selectedGroupNames.find((name) => name.value === item.value) ||
                    (!selectedGroupNames.length && item.value === "0")
                )}
                onClick={() => {
                  setIsChanged(true);
                  if (item.value === "0") {
                    setSelectedGroupNames([]);
                  } else {
                    setSelectedGroupNames((prev) => {
                      const exists = prev.some(
                        (selectedItem) => selectedItem.value === item.value
                      );
                      if (exists) {
                        return prev.filter(
                          (selectedItem) => selectedItem.value !== item.value
                        );
                      } else {
                        return [...prev, item];
                      }
                    });
                  }
                }}
              >
                <ItemLabel>{t(item.label)}</ItemLabel>
                <RadioButton
                  type="radio"
                  id="selectDateType"
                  checked={Boolean(
                    selectedGroupNames.find(
                      (name) => name.value === item.value
                    ) ||
                      (!selectedGroupNames.length && item.value === "0")
                  )}
                  onChange={() => {}}
                />
              </Item>
            )
          )}
        </List>
      </Wrapper>
      <BottomWrapper>
        <Button
          title={t("ChartFilter.ButtonTitle")}
          isDisabled={!isChanged}
          onClick={() => {
            if (isChanged) {
              handleClick(BottomSheetType.FILTER);
              submitBranch(selectedGroupNames);
            }
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default SelectGroupName;
