import React from "react";
import { isMobile } from "react-device-detect";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { chartColors } from "../../../../constants/color";
import { formatNumber } from "../../../../helpers/dashbordHelper";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, outerRadius, percent } = props;

  let radius = outerRadius * 0.75;
  if (isMobile) {
    radius = outerRadius * 0.72;
  }
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const updateValue = +percent.toFixed(4);

  if (updateValue * 100 < 1) return null;

  return (
    <text x={x} y={y} fill="#fff" textAnchor="middle" dominantBaseline="middle">
      <tspan>{`${(updateValue * 100).toFixed(1)}%`}</tspan>
    </text>
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        {payload.map((entry: any, index: number) => (
          <p key={`tooltip-item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${formatNumber(+entry.value.toFixed(1))}`} GEL
          </p>
        ))}
      </div>
    );
  }

  return null;
};

interface DonutChartProps {
  chartData: {
    data: Array<{
      name: string;
      value: number;
    }>;
    sum: number;
  };
}

const DonutChart = ({ chartData }: DonutChartProps) => {
  if (!chartData?.data) return null;

  return (
    <ResponsiveContainer width="100%" height={isMobile ? 380 : 500}>
      <PieChart>
        <Pie
          data={chartData.data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius="85%"
          innerRadius="40%"
          dataKey="value"
        >
          {chartData.data.map((_, index: number) => (
            <Cell key={`cell-${index}`} fill={chartColors[index]} />
          ))}
        </Pie>
        <Tooltip content={CustomTooltip} />
        {chartData.sum && (
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="central"
            className="number"
            fontSize={24}
            fontWeight="bold"
          >
            {formatNumber(chartData.sum)}
          </text>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
