import { css } from "@emotion/react";
import styled from "@emotion/styled";

type StatusItemProps = {
  status: "Pending" | "Uploaded" | "Declined" | "Partially uploaded";
};

export const TableBodyWrapper = styled.div(
  ({ theme }) => css`
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 1fr;
    }
  `
);

export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 0 30px;
  `
);

//
export const TableContentRow = styled.div(
  ({ theme }) => css`
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyBorderDark};
    &:last-child {
      border-bottom: none;
    }
  `
);
export const TableUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const UserWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 14px;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
  `
);
export const UserEmail = styled.p(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirDemi};
    color: ${theme.colors.grey};
    margin: 0;
  `
);
//
export const TableHeaderItem = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 12px;
    color: ${theme.colors.grey};
    margin: 0;
    &:last-child {
      border-bottom: none;
    }
  `
);

export const TableContent = styled.p(
  ({ theme }) => css`
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
    margin: 0;
  `
);

export const StatusItem = styled.div<StatusItemProps>(
  ({ theme, status }) => css`
    display: block;
    padding: 4px 7px;
    width: fit-content;
    border-radius: 15px;
    font-size: 12px;
    ${theme.fonts.AvenirMedium};
    color: ${status === "Pending" && theme.colors.yellow};
    color: ${status === "Uploaded" && theme.colors.green};
    color: ${status === "Declined" && theme.colors.dangerMain};
    color: ${status === "Partially uploaded" && theme.colors.darkBlue2};

    background-color: ${status === "Pending" && theme.colors.yellowBackground};
    background-color: ${status === "Uploaded" && theme.colors.greenBackground};
    background-color: ${status === "Declined" &&
    theme.colors.dangerBackgroundLight};
    background-color: ${status === "Partially uploaded" &&
    theme.colors.blueBackground};
  `
);
export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const FileImage = styled.img`
  width: 35px;
  height: 35px;
`;

export const ButtonItem = styled.div`
  text-align: center;
  margin: 20px 0;
`;
export const Loading = styled.p`
  margin: 0;
  font-size: 14px;
`;
