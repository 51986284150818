import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 50px;
    width: fit-content;
    padding: 10px 0;
    border-bottom: 1px solid ${theme.colors.greyBorder};
  `
);
export const Title = styled.div`
  width: 200px;
`;
