import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  AmountItem,
  AmountWrapper,
  BodyTitle,
  Content,
  Item,
  ItemsWrapper,
  RightSide,
  Title,
  TitleWrapper,
  Wrapper,
} from "./WorkOutcome.style";
import { isMobile } from "react-device-detect";
import { MarketData } from "../../../../store/dashboard/dashboard.types";
import Loader from "../../../../components/shared/loader/Loader";
import { LoaderType } from "../../../../store/type";

interface WorkOutcomeProps {
  financialData: MarketData | undefined;
  isConsolidate: boolean;
  loading: LoaderType;
}

const WorkOutcome = ({
  financialData,
  isConsolidate,
  loading,
}: WorkOutcomeProps) => {
  const firstYear = useMemo(() => {
    return financialData?.data ? Object.keys(financialData.data)[0] : "";
  }, [financialData]);

  if (loading === "pending") {
    return <Loader />;
  }

  return (
    <>
      {financialData?.data && (
        <div>
          <TitleWrapper isMobile={isMobile}>
            <Title isMobile={isMobile}>{financialData.name}</Title>
            <RightSide>
              {Object.keys(financialData.data).map((year) => (
                <Content key={year}>{year}</Content>
              ))}
            </RightSide>
          </TitleWrapper>

          <Wrapper>
            <ItemsWrapper>
              {financialData.data[+firstYear]?.map((item, index) => (
                <>
                  {Boolean(isConsolidate === item.isConsolidated) && (
                    <Item isSum={item.isSum} isMobile={isMobile} key={index}>
                      <BodyTitle isSum={item.isSum}>{item.name}</BodyTitle>
                      <AmountWrapper>
                        {financialData.data &&
                          Object.entries(financialData.data).map(
                            ([year, yearData]) => {
                              if (
                                isConsolidate === yearData[index].isConsolidated
                              ) {
                                return (
                                  <AmountItem key={year}>
                                    {yearData[index]?.amount.toLocaleString()}
                                  </AmountItem>
                                );
                              }
                              return null;
                            }
                          )}
                      </AmountWrapper>
                    </Item>
                  )}
                </>
              ))}
            </ItemsWrapper>
          </Wrapper>
        </div>
      )}
    </>
  );
};

export default WorkOutcome;
