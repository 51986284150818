import { ReportItem } from "../../../../store/report/report.types";
import Table from "../../../shared/table/Table";
import TableSkeleton from "../../../shared/tableSkeleton/TableSkeleton";
import ImportDataTableBody from "../importDataTableBody/ImportDataTableBody";

interface IImportDataTableProps {
  reportItems: Array<ReportItem>;
  tableHeaders: Array<string>;
  loadingIds: Array<number>;
  loading: boolean;
  handleDownload: (id: number) => void;
}

const ImportDataTable = ({
  reportItems,
  tableHeaders,
  loadingIds,
  loading,
  handleDownload,
}: IImportDataTableProps) => {
  return (
    <Table headerNames={tableHeaders}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <ImportDataTableBody
          handleDownload={handleDownload}
          loadingIds={loadingIds}
          data={reportItems}
        />
      )}
    </Table>
  );
};

export default ImportDataTable;
