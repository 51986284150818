import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface GroupedChartWrapperProps {
  dataLacked: boolean;
  isMobile: boolean;
}
interface IsMobile {
  isMobile: boolean;
}
interface GridProps {
  isMobile: boolean;
  hasGrid: boolean;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    min-height: 100vh;
    padding: 10px 0 80px;
    overflow: hidden;
    width: 100%;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 10px 0 80px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 10px 0 80px;
    }
  `
);

export const TabsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    max-width: 675px;
    width: 100%;
  `
);

export const GroupedChartWrapper = styled.div<GroupedChartWrapperProps>(
  ({ dataLacked, isMobile }) => css`
    display: grid;
    grid-template-columns: ${dataLacked || isMobile ? "1fr" : "60% 40%"};
  `
);

export const DropDownWrapper = styled.div`
  width: 165px;
`;

export const DonutChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const GridContainer = styled.div<GridProps>(
  ({ isMobile, hasGrid }) => css`
    display: grid;
    grid-template-columns: ${isMobile || hasGrid ? "1fr" : "1fr 1fr"};
    gap: ${isMobile ? "30px" : "50px"};
    margin-top: 50px;
  `
);

export const ChartTitle = styled.p<IsMobile>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.AvenirMedium}
    font-size: ${isMobile ? "16px" : "20px"};
    color: ${theme.colors.black};
    margin-bottom: ${isMobile ? "15px" : "30px"};
    text-align: center;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  `
);

export const BarChartGrouper = styled.div`
  display: grid;
  grid-template-columns: ${"1fr 1fr"};
`;

export const BarChartGrouperTitle = styled.div(
  ({ theme }) => css`
    font-size: 23px;
    list-style-type: none; /* Remove default bullets */
    padding-left: 0;
    background: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  `
);

export const LastUpdated = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.textSecondary};
    font-size: 16px;
  `
);
