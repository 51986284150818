import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getProfitLossInfo } from "../../../../store/dashboard/dashboardAction";
import {
  Wrapper,
  ChartTitle,
  GridContainer,
  LastUpdated,
} from "./ProfitLoss.style";

import {
  dashboardDateFormat,
  generatePeriodTypes,
} from "../../../../helpers/helper";
import { BottomSheetType } from "../../../../enums/enum";
import BarChartContainer from "../../../../components/desktop/charts/barChart/BarChart";
import ChartFilter from "../../../../components/desktop/chartFilter/ChartFilter";
import { Filter } from "../../../../store/dashboard/dashboard.types";
import PercentageBarChart from "../../../../components/desktop/charts/percentageBarChart/PercentageBarChart";
import ChartHolder from "../../../../components/shared/chartEmptyHolder/ChartEmptyHolder";
import Loader from "../../../../components/shared/loader/Loader";
import { useTranslation } from "react-i18next";

import ChartFilterButtonMobile from "../../../../components/mobile/filerButton/FilerButtonMobile";
import { setBottomSheet } from "../../../../store/user/userSlice";

import GroupChartProfitLoss from "../../../../components/desktop/charts/groupChartProfitLoss/GroupChartProfitLoss";
import { updateFilter } from "../../../../store/dashboard/dashboardSlice";

const ProfitLoss = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const groupedChartData = useAppSelector(
    (state) => state.dashboard.profitLoss.groupedChartData
  );

  const filter = useAppSelector((state) => state.dashboard.filter);

  const frontSideFilter = useAppSelector(
    (state) => state.dashboard.frontSideFilter
  );

  const netIncome = useAppSelector(
    (state) => state.dashboard.profitLoss.netIncome
  );

  const loadingDashboard = useAppSelector((state) => state.dashboard.loading);

  const netIncomePercentage = useAppSelector(
    (state) => state.dashboard.profitLoss.netIncomePercentage
  );

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const lastUpdatedTime = useAppSelector(
    (state) => state.dashboard.profitLossLastDateUpdate
  );

  useEffect(() => {
    if (filter.fromDate && filter.toDate && filter.periodType && !isMobile) {
      setLoading(true);
      dispatch(getProfitLossInfo(filter)).then(() => {
        setLoading(false);
      });
    }
  }, [filter, selectedCompany, dispatch]);

  const handleFilter = (values: Filter) => {
    if (
      filter.periodType === values.periodType &&
      filter.fromDate === values.fromDate &&
      filter.toDate === values.toDate
    ) {
    } else {
      dispatch(
        updateFilter({
          periodType: values.periodType,
          fromDate: values.fromDate,
          toDate: values.toDate,
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        {isMobile ? (
          <ChartFilterButtonMobile
            handleClick={() => {
              dispatch(setBottomSheet(BottomSheetType.FILTER));
            }}
          />
        ) : (
          <>
            {lastUpdatedTime && (
              <LastUpdated>
                last updated: {dashboardDateFormat(lastUpdatedTime)}
              </LastUpdated>
            )}

            <ChartFilter
              hasGroupBy={false}
              filterData={filter}
              frontSideFilter={frontSideFilter}
              options={generatePeriodTypes()}
              handleSubmit={(values) => {
                handleFilter(values);
              }}
            />
          </>
        )}

        {loading || loadingDashboard === "pending" ? (
          <Loader />
        ) : (
          <>
            <div>
              {Boolean(groupedChartData?.data.length) ? (
                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("ProfitLoss.GroupBarChartTitle")}
                  </ChartTitle>
                  <GroupChartProfitLoss
                    stackId={undefined}
                    chartData={groupedChartData}
                  />
                </div>
              ) : (
                <ChartHolder title="" />
              )}
            </div>
            <GridContainer isMobile={isMobile}>
              <div>
                {netIncome.data.length ? (
                  <>
                    <ChartTitle isMobile={isMobile}>
                      {t("ProfitLoss.BarChartTitle")}
                    </ChartTitle>
                    <BarChartContainer chartData={netIncome} />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {netIncomePercentage.data.length ? (
                  <>
                    <ChartTitle isMobile={isMobile}>
                      {t("ProfitLoss.PercentageBarChart")}
                    </ChartTitle>
                    <PercentageBarChart chartData={netIncomePercentage} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </GridContainer>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default ProfitLoss;
