import React, { useState } from "react";

import CreatePasswordForm from "../../../components/desktop/forms/createPasswordForm/CreatePasswordForm";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createPassword } from "../../../store/user/userAction";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthURL } from "../../../constants/router";

const CreatePassword = () => {
  const isLoading = useAppSelector((state) => state.user.loading === "pending");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = ({ password }: { password: string }) => {
    setErrorMessage("");
    dispatch(createPassword({ password, token })).then((action: any) => {
      if (action?.error?.message) {
        setErrorMessage(action.error.message);
      }
    });
  };

  const handleNavigate = () => {
    navigate(AuthURL.Login.path);
  };

  return (
    <>
      <CreatePasswordForm
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleNavigate={handleNavigate}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default CreatePassword;
