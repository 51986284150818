import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../../../store";

import {
  getMarketAllCompanyInfo,
  getMarketCompanyInfo,
} from "../../../store/dashboard/dashboardAction";
import { useTranslation } from "react-i18next";
import { generatePreviousYearsData } from "../../../helpers/helper";
import {
  DropDownWrapper,
  HeaderWrapper,
  InputWrapper,
  NoDataWrapper,
  SubTitle,
  TabsRow,
  TabsWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "./Market.style";

import DropDown from "../../../components/shared/dropdown/Dropdown";
import Tab from "../../../components/shared/tabs/tab/Tab";

import WorkOutcome from "./workOutcome/WorkOutcome";
import SearchableSelect from "../../../components/shared/searchableSelect/SearchableSelect";

export enum MarketTab {
  FinanceStanding = 1,
  WorkOutcome = 2,
}

export enum MarketFilter {
  Individual = "1",
  Consolidated = "2",
}

const consolidatedTypes = [
  { label: "MarketTabs.Consolidated", value: MarketFilter.Consolidated },
  { label: "MarketTabs.Individual", value: MarketFilter.Individual },
];

const Market = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(MarketTab.WorkOutcome);
  const [marketsIsLoading, setMarketsIsLoading] = useState(false);

  const [selectedYear, setSelectedYear] = useState(
    generatePreviousYearsData(2)[0].value
  );
  const [consolidatedFilter, setConsolidatedFilter] = useState(
    MarketFilter.Individual
  );

  const [companyCode, setCompanyCode] = useState(0);

  const [inputText, setInputText] = useState("");

  const loading = useAppSelector((state) => state.dashboard.loading);

  const marketFinancialData = useAppSelector((state) => state.dashboard.market);

  const marketCompanyData = useAppSelector(
    (state) => state.dashboard.marketWorkOutcomeCompanyInfo
  ).map((item) => ({
    value: item.idCode,
    label: `${item.orgNameInReport} (${item.idCode})`,
  }));

  const handleSelectTab = (id: MarketTab) => {
    setSelectedTab(id);
  };

  useEffect(() => {
    if (inputText.length > 2) {
      const typingDelay = 500;
      setMarketsIsLoading(true);
      setCompanyCode(0);

      const timeoutId = setTimeout(() => {
        dispatch(getMarketAllCompanyInfo(inputText)).then(() => {
          setMarketsIsLoading(false);
        });
      }, typingDelay);

      return () => clearTimeout(timeoutId);
    }
  }, [dispatch, inputText]);

  useEffect(() => {
    dispatch(
      getMarketCompanyInfo({
        identificationCode: companyCode,
        year: +selectedYear,
        rePortalMappingType: selectedTab,
      })
    );
  }, [selectedYear, dispatch, selectedTab]);

  const handleSelect = (codeId: number) => {
    dispatch(
      getMarketCompanyInfo({
        identificationCode: codeId,
        year: +selectedYear,
        rePortalMappingType: selectedTab,
      })
    );
  };

  return (
    <Wrapper isMobile={isMobile}>
      <HeaderWrapper isMobile={isMobile}>
        <TitleWrapper>
          <Title>{t("MarketTabs.Title")}</Title>
          <SubTitle>{t("MarketTabs.SubTitle")}</SubTitle>
        </TitleWrapper>
        <InputWrapper>
          <Formik onSubmit={() => {}} initialValues={{ marketCompany: "" }}>
            {({ values }) => (
              <Form>
                <InputWrapper>
                  <Field
                    value={values.marketCompany}
                    name="marketCompany"
                    label={t("MarketTabs.SelectCompany")}
                    options={marketCompanyData}
                    isLoading={marketsIsLoading}
                    component={SearchableSelect}
                    hasLazyLoading
                    hasValue={values.marketCompany}
                    onInputChange={(value: string) => {
                      if (value) {
                        setInputText(value);
                      }
                    }}
                    onCustomChange={(codeId: number) => {
                      setCompanyCode(codeId);
                      if (codeId) {
                        handleSelect(codeId);
                      }
                    }}
                  />
                </InputWrapper>
              </Form>
            )}
          </Formik>
        </InputWrapper>
      </HeaderWrapper>
      {Boolean(companyCode) && (
        <TabsRow isMobile={isMobile}>
          <TabsWrapper>
            <Tab
              isClicked={selectedTab === MarketTab.WorkOutcome}
              handleClick={() => {
                handleSelectTab(MarketTab.WorkOutcome);
              }}
              title={t("MarketTabs.WorkOutcome")}
            />
          </TabsWrapper>
          <DropDownWrapper isMobile={isMobile}>
            <DropDown
              title={t("MarketTabs.years")}
              options={generatePreviousYearsData(2)}
              selectedValue={selectedYear}
              handleSelect={setSelectedYear}
            />
            <DropDown
              title={t("MarketTabs.Type")}
              options={consolidatedTypes}
              selectedValue={consolidatedFilter}
              handleSelect={(value) =>
                setConsolidatedFilter(value as MarketFilter)
              }
            />
          </DropDownWrapper>
        </TabsRow>
      )}

      {(!marketFinancialData || !companyCode) && (
        <NoDataWrapper>{t("MarketFinancial.NoDataTitle")}</NoDataWrapper>
      )}

      {Boolean(selectedTab === MarketTab.WorkOutcome && companyCode) && (
        <WorkOutcome
          isConsolidate={consolidatedFilter === MarketFilter.Consolidated}
          financialData={marketFinancialData}
          loading={loading}
        />
      )}
    </Wrapper>
  );
};

export default Market;
