import React from "react";
import { Wrapper, Description, Title } from "./Slider.style";

import coverIMG from "../../../assets/images/cover.png";

interface sliderProps {
  title: string;
  description: string;
}
const Slider = ({ title, description }: sliderProps) => {
  return (
    <Wrapper>
      <img src={coverIMG} alt="amadeo cover" />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

export default Slider;
