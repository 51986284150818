import axios, { AxiosError, AxiosResponse } from "axios";
import config from "../utils/config";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

import {
  getDataToStorage,
  setDataToStorage,
  STORAGE_USER_DETAIL,
} from "./storage";

import storeRegistry from "../store/storeRegistry";
import { logout, showErrorMessage } from "../store/user/userSlice";
import { User } from "../store/user/user.types";

const parseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error("Parsing error on json", error);
    return null; // or a default value if you prefer
  }
};

declare module "axios" {
  export interface AxiosRequestConfig {
    removeLoader?: boolean;
  }
}
const backendUrl = process.env.REACT_APP_URL || "https://dev-api.amadeo.ge";

const axiosInstance = axios.create({
  baseURL: `${backendUrl}`,
});

axiosInstance.interceptors.request.use(
  async (request) => {
    const user = parseJSON(getDataToStorage(STORAGE_USER_DETAIL) || "{}");

    request.headers = request.headers || {};
    let decodeUser, isExpired;

    if (user?.token) {
      request.headers["Authorization"] = `Bearer ${user?.token}`;
      decodeUser = jwtDecode(user?.token);
    }

    if (decodeUser) {
      isExpired = dayjs.unix(decodeUser?.exp || 0).diff(dayjs()) < 1;
    }

    if (!isExpired) return request;

    // todo to match refresh sending
    const response = await axios.post(
      `${backendUrl}${config.api.endpoints.auth.refresh}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user?.refreshToken}`,
        },
      }
    );
    const { data } = response as AxiosResponse<User>;
    const updatedUser = {
      ...user,
      token: data.token,
      refreshToken: data.refreshToken,
    };

    setDataToStorage(STORAGE_USER_DETAIL, JSON.stringify(updatedUser));
    request.headers["Authorization"] = `Bearer ${data?.token}`;
    return request;
  },

  (error) => {
    return Promise.reject({ ...error });
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return onResponseFulfilled(response);
  },
  (error: AxiosError) => {
    return onResponseRejected(error);
  }
);

const onResponseFulfilled = (
  response: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => {
  return response;
};

const onResponseRejected = (error: any) => {
  if (error?.response?.status === 401) {
    storeRegistry?.getStore().dispatch(logout());
  } else {
    storeRegistry
      ?.getStore()
      .dispatch(showErrorMessage(error?.response?.data?.Message || ""));
  }
  return Promise.reject({ message: error?.response?.data?.Message || "" });
};

export default axiosInstance;
