import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type ButtonType = "filled" | "outlined";
export type ButtonSize = "small" | "large";

interface ButtonProps {
  disabled: boolean;
  isLoading: boolean;
  size: ButtonSize;
  buttonType: ButtonType;
  isMobile: boolean;
}

export const CustomButton = styled.button<ButtonProps>(
  ({ theme, disabled, size, buttonType, isLoading, isMobile }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${size === "small" && "12px"};
    gap: 10px;
    padding: ${size === "small" ? "4px 15px" : "12px 0"};
    border-radius: 15px;
    color: ${disabled || isLoading
      ? theme.colors.grey
      : buttonType === "outlined"
      ? theme.colors.primary
      : theme.colors.white};
    background-color: ${disabled
      ? theme.colors.lightGrey
      : buttonType === "outlined"
      ? theme.colors.white
      : theme.colors.primary};
    border: ${buttonType === "outlined" && !disabled
      ? `1px solid ${theme.colors.primary}`
      : "none"};
    cursor: ${disabled || isLoading ? "default" : "pointer"};
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${!disabled &&
        (buttonType === "outlined"
          ? theme.colors.lightGrey
          : theme.colors.primaryHover)};
      }
    `}

    .loading-svg {
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    ${!isMobile &&
    isLoading &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      &:hover {
        background-color: ${theme.colors.primaryHover};
      }
    `}
  `
);
