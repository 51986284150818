import { useMemo } from "react";
import { EmotionCache } from "@emotion/react";

import createCache from "@emotion/cache";

export default function useEmotionCache(prefix: string): EmotionCache {
  return useMemo(() => {
    return createCache({
      key: prefix,
    });
  }, [prefix]);
}
