import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  status: "Success" | "Error";
};
export const Wrapper = styled.div<Props>(
  ({ theme, status }) => css`
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 40px;
    left: 40px;
    background: ${theme.colors.white};
    ${theme.fonts.HelveticaLight}
  `
);
export const AlertContent = styled.p<Props>(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    line-height: 20px;
  `
);
export const IconWrapper = styled.div<Props>(
  ({ status, theme }) => css`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${status === "Success"
      ? theme.colors.success600
      : theme.colors.dangerMain};
  `
);

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
