import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FormikProps } from "formik";
// import { ReactComponent as WarningSvg } from "../../../assets/svgs/warning.svg";
import {
  EmailButtonContainer,
  EmailItem,
  EmailWrapper,
  ErrorMassage,
  ErrorMassageText,
  SvgWrapper,
  Wrapper,
} from "./InviteUser.style";
import Modal from "../../../shared/modal/Modal";
import Input from "../../../shared/input/Input";
import DropDown from "../../../shared/dropdown/Dropdown";
import Button from "../../../shared/button/Button";
import { initialValues, InviteUserSchema } from "./schema";
import { User } from "../../../../store/user/user.types";
import { useTranslation } from "react-i18next";

interface UploadModalProps {
  loadingInvite: boolean;
  loadingCheck: boolean;
  inviteErrorMessage: string;
  userData: User | null | undefined;
  userRoles: Array<{ value: string; label: string }>;
  disable: boolean;
  handleClose: () => void;
  handleCheck: (email: string) => void;
  handleSubmit: (values: {
    firstName: string;
    lastName: string;
    email: string;
    clientRoleId: string;
  }) => void;
}

const InviteUser = ({
  userRoles,
  userData,
  loadingInvite,
  loadingCheck,
  inviteErrorMessage,
  disable,
  handleSubmit,
  handleClose,
  handleCheck,
}: UploadModalProps) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);

  useEffect(() => {
    if (userData === null && formikRef.current) {
      setShowError(true);
      const { email } = formikRef.current.values;
      formikRef.current?.resetForm({ values: { ...initialValues, email } });
    }

    if (userData && formikRef.current) {
      setShowError(false);
      formikRef.current.setFieldValue("firstName", userData.firstName);
      formikRef.current.setFieldValue("lastName", userData.lastName);
    }
  }, [userData, formikRef]);

  const formSubmit = (values: {
    firstName: string;
    lastName: string;
    email: string;
    clientRoleId: string;
  }) => {
    setShowError(false);
    handleSubmit(values);
  };

  const handleKeyPress = (event: any, email: string) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleCheck(email);
    }
  };

  return (
    <Modal
      type="medium"
      title={t("InviteUser.ModalTitle")}
      close={handleClose}
      showCloseIcon={true}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={InviteUserSchema}
        onSubmit={formSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form autoComplete="off">
            <Wrapper>
              <EmailWrapper>
                <EmailItem>
                  <Field
                    name="email"
                    autoComplete="email"
                    label={t("InviteUser.EmailPlaceholder")}
                    component={Input}
                    onKeyDown={(event: any) =>
                      handleKeyPress(event, values.email)
                    }
                  />
                </EmailItem>
                <EmailButtonContainer>
                  <Button
                    isLoading={loadingCheck}
                    title={t("InviteUser.CheckButton")}
                    onClick={() => {
                      handleCheck(values.email);
                    }}
                  />
                </EmailButtonContainer>
              </EmailWrapper>
              {showError || inviteErrorMessage ? (
                <ErrorMassage>
                  <SvgWrapper />
                  <ErrorMassageText>
                    {inviteErrorMessage
                      ? inviteErrorMessage
                      : t("InviteUser.InviteErrorMessage")}
                  </ErrorMassageText>
                </ErrorMassage>
              ) : null}

              <Field
                autoComplete="firstName"
                name="firstName"
                label={t("InviteUser.FirstNamePlaceholder")}
                disabled={disable}
                component={Input}
              />
              <Field
                autoComplete="lastName"
                name="lastName"
                label={t("InviteUser.LastNamePlaceholder")}
                disabled={disable}
                component={Input}
              />

              <DropDown
                title={t("InviteUser.Status")}
                options={userRoles}
                hasError={Boolean(errors.clientRoleId && touched.clientRoleId)}
                selectedValue={values.clientRoleId}
                handleSelect={(value) => {
                  setFieldValue("clientRoleId", value);
                }}
              />
            </Wrapper>

            <Button
              title={t("InviteUser.SubmitButton")}
              type="submit"
              isLoading={loadingInvite}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default InviteUser;
