import Slider from "../../components/desktop/slider/Slider";
import { AuthWrapper, FormContainer, FormWrapper } from "./Auth.layout.style";

import { Navigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";

import ChooseAccount from "../../pages/auth/chooseAccount/ChooseAccount";
import { PageURL } from "../../constants/router";

const AuthLayout = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  if (user && selectedCompany) {
    return <Navigate to={PageURL.Root.path} replace />;
  }

  return (
    <AuthWrapper>
      <FormWrapper>
        <FormContainer>
          {user && !selectedCompany ? <ChooseAccount /> : <Outlet />}
        </FormContainer>
      </FormWrapper>
      <Slider title={t("Slider.Title")} description={t("Slider.Description")} />
    </AuthWrapper>
  );
};

export default AuthLayout;
