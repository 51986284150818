import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 300px;
    border: 1px solid ${theme.colors.greyBorderDark};
    border-radius: 15px;
    padding: 30px 10px;
    @media (max-width: ${theme.breakpoints.xl}) {
      width: 100%;
      padding: 20px 5px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 30px 10px;
    }
  `
);

export const SvgWrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.primaryLight};
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin-bottom: 15px;
  `
);
export const Title = styled.h2(
  ({ theme }) => css`
    font-size: 18px;
    color: ${theme.colors.black};
    ${theme.fonts.AvenirDemi};
    margin-bottom: 30px;
    @media (max-width: ${theme.breakpoints.xl}) {
      margin-bottom: 20px;
      font-size: 16px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      margin-bottom: 30px;
    }
  `
);

export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 0 35px;
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 0 20px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 0 35px;
    }
  `
);
