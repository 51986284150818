import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 16px;
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    min-width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.negative600};
    border-radius: 50%;
  `
);
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Title = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 16px;
    line-height: normal;
  `
);
export const SubTitle = styled.h5(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.grey700};
    font-size: 12px;
    line-height: 18px;
  `
);

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const NameTitle = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.negative600};
    font-size: 16px;
  `
);
