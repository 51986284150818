import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface IsMobile {
  isMobile: boolean;
}

export const TitleWrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: grid;
    grid-template-columns: ${isMobile ? "1fr" : "4fr 3fr"};

    padding: 10px 20px;
  `
);

export const Title = styled.h2<IsMobile>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
    ${isMobile && "text-align: center;"}
  `
);

export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

export const Content = styled.h3(
  ({ theme }) => css`
    width: 120px;
    text-align: center;
    ${theme.fonts.AvenirMedium}
    font-size: 18px;
    text-align: right;
  `
);

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Item = styled.div<{ isMobile: boolean; isSum: boolean }>(
  ({ isMobile, isSum, theme }) => css`
    background-color: ${isSum && theme.colors.primaryShade100};
    display: grid;
    grid-template-columns: ${isMobile ? "1fr" : "4fr 3fr"};
    padding: 10px 20px;
    gap: 10px;
    border-radius: 10px;
    align-items: center;
  `
);
export const BodyTitle = styled.div<{ isSum: boolean }>(
  ({ isSum, theme }) => css`
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${isSum ? theme.colors.primary600 : theme.colors.black005};
  `
);

export const AmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row-reverse;
`;

export const AmountItem = styled.h2(
  ({ theme }) => css`
    padding: 8px 5px;
    border-radius: 15px;
    border: 1px solid ${theme.colors.greyBorder};
    background-color: aliceblue;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.grey};
    font-size: 14px;
    max-width: 120px;
    width: 100%;
    user-select: text;
    text-align: end;
  `
);
