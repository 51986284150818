import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 25px 30px;
    margin-bottom: 60px;
    gap: 10px;
    flex-wrap: wrap;
  `
);

export const Title = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
    color: ${theme.colors.black};
  `
);

export const Filter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
`;
export const ButtonWrapper = styled.div`
  width: 120px;
`;

export const DropDownWrapper = styled.div`
  width: 265px;
`;

export const Calendar = styled.div`
  .rs-input-group {
    border-radius: 15px;
    input {
      border-radius: 15px;
    }
  }

  .rs-input-group.rs-input-group-lg > .rs-input-group-addon,
  .rs-input-group.rs-input-group-lg > .rs-input {
    height: 47px;
  }
`;
