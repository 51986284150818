import React, { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

import { useTranslation } from "react-i18next";
import {
  Wrapper,
  DropDownField,
  HeaderWrapper,
  OptionItem,
  OptionsWrapper,
  Title,
  TitleWrapper,
  Arrow,
} from "./Dropdown.style";
import Tooltip from "../tooltip/Tooltip";
import { ErrorIcon, ErrorMassage } from "../input/Input.style";

interface Data {
  value: string;
  label: string;
}
interface DropDownProps {
  title: string;
  options: Array<Data>;
  selectedValue: string;
  toolTipText?: string;
  haveToolTip?: boolean;
  hasError?: boolean;
  handleSelect: (value: string) => void;
}

const DropDown = ({
  title,
  options,
  selectedValue,
  haveToolTip = false,
  toolTipText,
  hasError,
  handleSelect,
}: DropDownProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropDownRef?.current &&
        !dropDownRef.current.contains(event.target as Node)
      ) {
        setOpened(false);
      }
    };

    if (dropDownRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  const handleClick = (e: any) => {
    e.preventDefault();
    setOpened((preVal) => !preVal);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const selectedLabel = useMemo(() => {
    const item = options.find(
      (option) => option.value === selectedValue?.toString() && selectedValue
    );

    return item?.label || "";
  }, [options, selectedValue]);

  return (
    <Wrapper>
      <DropDownField ref={dropDownRef} onClick={handleClick}>
        <HeaderWrapper opened={opened}>
          <TitleWrapper>
            <Title>{t(selectedLabel) || t(title)}</Title>
            {haveToolTip && (
              <div>
                <Tooltip click={handleClose} text={toolTipText || ""} />
              </div>
            )}
          </TitleWrapper>
          <Arrow className={opened ? "rotate" : ""} />
        </HeaderWrapper>
        <OptionsWrapper opened={opened}>
          {options.map((option) => (
            <OptionItem
              isMobile={isMobile}
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              {option.label.includes(".") ? t(option.label) : option.label}
            </OptionItem>
          ))}
        </OptionsWrapper>
      </DropDownField>
      {hasError ? (
        <ErrorMassage>
          <ErrorIcon /> {t("DropDown.ErrorMassage")}
        </ErrorMassage>
      ) : null}
    </Wrapper>
  );
};

export default DropDown;
