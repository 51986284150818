import React from "react";
import { ThemeProvider as Emotion, CacheProvider } from "@emotion/react";

import { createTheme } from "./createTheme";
import { GlobalStyles } from "./GlobalStyle";
import { isMobileDevice } from "../helpers/helper";
import useEmotionCache from "../hooks/useEmotionCache";

interface IProps {
  children: React.ReactNode;
  isMobileOverride?: boolean;
  prefix?: string;
}

const ThemeProvider: React.FC<IProps> = ({
  children,
  isMobileOverride = false,
  prefix = "ui",
}: IProps) => {
  const theme = createTheme(isMobileDevice() || isMobileOverride);

  const cache = useEmotionCache(prefix);

  return (
    <CacheProvider value={cache}>
      <Emotion theme={theme}>
        <GlobalStyles />
        {children}
      </Emotion>
    </CacheProvider>
  );
};
export { ThemeProvider };
