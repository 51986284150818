import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as ToolTipSvg } from "../../../assets/svgs/info.svg";

export const ToolTipSvgWrapper = styled(ToolTipSvg)(
  ({ theme }) => css`
    cursor: pointer;
    fill: ${theme.colors.black};
  `
);

export const Wrapper = styled.div`
  position: relative;
`;
interface ContentProps {
  width: number;
}

export const Content = styled.div<ContentProps>(
  ({ theme, width }) => css`
    background-color: ${theme.colors.darkBlue1};
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.white};
    text-align: center;
    margin-top: 16px;
    width: ${width}px;
    z-index: 1;
    font-size: 12px;
    padding: 12px;
    border-radius: 8px;
    position: absolute;
    right: 50%;
    transform: translate(50%);
    line-break: anywhere;

    &::before {
      content: "";
      position: absolute;
      right: 50%;
      transform: translate(50%);
      bottom: 100%;
      border: 8px solid transparent;
      border-bottom-color: ${theme.colors.darkBlue1};
    }
  `
);
