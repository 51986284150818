/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-unused-modules */
import React from "react";
import { Global, css } from "@emotion/react";

import { fontWeights } from "../constants/fonts";
import "rsuite/dist/rsuite.css";

import AvenirNextGeorgianBoldWoff from "../assets/fonts/AvenirNextGeorgianBold.woff";
import AvenirNextGeorgianDemiWoff from "../assets/fonts/AvenirNextGeorgianDemi.woff";
import AvenirNextGeorgianMediumWoff from "../assets/fonts/AvenirNextGeorgianMedium.woff";
import AvenirNextGeorgianLightWoff from "../assets/fonts/AvenirNextGeorgianRegular.woff";
import AvenirNextGeorgianBoldWoff2 from "../assets/fonts/AvenirNextGeorgianBold.woff2";
import AvenirNextGeorgianDemiWoff2 from "../assets/fonts/AvenirNextGeorgianDemi.woff2";
import AvenirNextGeorgianMediumWoff2 from "../assets/fonts/AvenirNextGeorgianMedium.woff2";
import AvenirNextGeorgianLightWoff2 from "../assets/fonts/AvenirNextGeorgianRegular.woff2";
import HelveticaNeueWoff from "../assets/fonts/HelveticaNeue.woff";
import HelveticaNeueWoff2 from "../assets/fonts/HelveticaNeue.woff2";

export const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "Avenir-Bold";
          src: url("${AvenirNextGeorgianBoldWoff2}") format("woff2"),
            url("${AvenirNextGeorgianBoldWoff}") format("woff");
          font-weight: ${fontWeights.bold};
          font-style: normal;
        }
        @font-face {
          font-family: "Avenir-Demi";
          src: url("${AvenirNextGeorgianDemiWoff2}") format("woff2"),
            url("${AvenirNextGeorgianDemiWoff}") format("woff");
          font-weight: ${fontWeights.medium};
          font-style: normal;
        }
        @font-face {
          font-family: "Avenir-Medium";
          src: url("${AvenirNextGeorgianMediumWoff2}") format("woff2"),
            url("${AvenirNextGeorgianMediumWoff}") format("woff");
          font-weight: ${fontWeights.medium};
          font-style: normal;
        }
        @font-face {
          font-family: "Avenir-Light";
          src: url("${AvenirNextGeorgianLightWoff2}") format("woff2"),
            url("${AvenirNextGeorgianLightWoff}") format("woff");
          font-weight: ${fontWeights.light};
          font-style: normal;
        }

        @font-face {
          font-family: "Helvetica-Neue";
          src: url("${HelveticaNeueWoff2}") format("woff2"),
            url("${HelveticaNeueWoff}") format("woff");
          font-weight: ${fontWeights.medium};
          font-style: normal;
        }

        html.fixed-height,
        html.fixed-height body,
        html.fixed-height body #root {
          height: 100vh;
          overflow: hidden;
        }

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }
        body {
          line-height: 1;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: "";
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        .no-scroll {
          overflow: hidden;
          height: 100%;
        }
        .rs-picker-popup {
          z-index: 99999 !important;
        }

        body {
          font-family: Avenir-Medium;
          font-size: 16px;
          line-height: 1.5;
          margin: 0;
          color: #000;
          background-color: #fff;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          -webkit-text-size-adjust: 100%;
          -moz-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        input,
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }

        input:-internal-autofill-selected {
          appearance: menulist-button;
          background-image: none !important;
          background-color: light-dark(
            rgb(232, 240, 254),
            rgba(70, 90, 126, 0.4)
          ) !important;
          color: fieldtext !important;
        }

        .rs-input-group.rs-input-group-lg > .rs-input-group-addon,
        .rs-input-group.rs-input-group-lg > .rs-input {
          height: 49px;
        }
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px #f7f9fc inset !important;
          -webkit-text-fill-color: #000 !important;
        }

        input:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 30px #f7f9fc inset !important;
          -webkit-text-fill-color: #000 !important;
        }

        input:-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #f7f9fc inset !important;
          -webkit-text-fill-color: #000 !important;
        }

        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #f7f9fc inset !important;
          -webkit-text-fill-color: #000 !important;
        }

        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
      `}
    />
  );
};
