export const STORAGE_USER_DETAIL = "USER_DETAIL";
export const SELECTED_COMPANY = "SELECTED_COMPANY";
export const LANGUAGE = "LANGUAGE";


export const setDataToStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getDataToStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const deleteDataToStorage = (key: string) => {
  localStorage.removeItem(key);
};
