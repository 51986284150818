import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IsMobile {
  isMobile: boolean;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    min-height: 100vh;
    padding: 10px 0 80px;
    overflow: hidden;
    width: 100%;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 30px 0 80px;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 20px 0 80px;
    }
  `
);

export const TabsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    max-width: 675px;
    width: 100%;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0 auto 50px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 0 auto 30px;
    }
  `
);

export const GroupedChartWrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: grid;
    grid-template-columns: ${isMobile ? "1ft" : "60% 40%;"};
  `
);

export const DropDownWrapper = styled.div`
  width: 165px;
`;

export const DonutChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

interface GridProps {
  isMobile: boolean;
  hasGrid: boolean;
}

export const GridContainer = styled.div<GridProps>(
  ({ isMobile, hasGrid }) => css`
    display: grid;
    grid-template-columns: ${isMobile || hasGrid ? "1fr" : "1fr 1fr"};
    gap: 50px;
    margin-top: 50px;
  `
);

export const ChartTitle = styled.p<IsMobile>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    font-size: ${isMobile ? "16px" : "20px"};
    text-align: center;
    margin-bottom: ${isMobile ? "15px" : "30px"};
  `
);
export const BarChartGrouper = styled.div`
  display: grid;
  grid-template-columns: ${"1fr 1fr"};
`;

export const BarChartGrouperTitle = styled.div(
  ({ theme }) => css`
    font-size: 23px;
    list-style-type: none; /* Remove default bullets */
    padding-left: 0;
    background: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  `
);
export const LastUpdated = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.textSecondary};
    font-size: 16px;
  `
);
