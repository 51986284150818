import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface WrapperProps {
  length: number;
  hasBorder: boolean;
  isMobile: boolean;
}
export const Wrapper = styled.div<WrapperProps>(
  ({ theme, length, hasBorder, isMobile }) => css`
    display: grid;
    grid-template-columns: ${isMobile ? "1fr" : `4fr ${length}fr`};
    align-items: center;
    padding: 7px 0 13px;

    gap: 10px;
    ${hasBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.greyBorder};
    `}
  `
);

export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium}
    font-size: 16px;
  `
);
export const Content = styled.h2(
  ({ theme }) => css`
    padding: 8px 5px;
    border-radius: 15px;
    border: 1px solid ${theme.colors.greyBorder};
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.grey};
    font-size: 14px;
    max-width: 120px;
    width: 100%;
    user-select: text;
    text-align: end;
  `
);
