import React, { useState } from "react";
import {
  List,
  Item,
  Wrapper,
  ItemLabel,
  BottomWrapper,
} from "./SelectBusinessLineMobile.style";
import { RadioButton } from "../../shared/accountItem/AccountItem.style";
import { BottomSheetType } from "../../../enums/enum";
import Button from "../../shared/button/Button";
import { useTranslation } from "react-i18next";

interface SelectBusinessLineMobileProps {
  businessLineList: Array<{ value: string; label: string }>;
  businessLines: Array<{ value: string; label: string }>;
  submitBusinessLines: (lines: Array<{ value: string; label: string }>) => void;
  handleClick: (type: BottomSheetType) => void;
}
const SelectBusinessLineMobile = ({
  businessLineList,
  businessLines,
  submitBusinessLines,
  handleClick,
}: SelectBusinessLineMobileProps) => {
  const { t } = useTranslation();

  const [selectedBusinessLines, setSelectedBusinessLines] =
    useState(businessLines);

  const [isChanged, setIsChanged] = useState(false);
  return (
    <>
      <Wrapper>
        <List>
          {[
            { label: t("ChartFilter.All"), value: "0" },
            ...businessLineList,
          ].map((item) => (
            <Item
              key={item.value}
              clicked={Boolean(
                selectedBusinessLines.find(
                  (name) => name.value === item.value
                ) ||
                  (!selectedBusinessLines.length && item.value === "0")
              )}
              onClick={() => {
                setIsChanged(true);
                if (item.value === "0") {
                  setSelectedBusinessLines([]);
                } else {
                  setSelectedBusinessLines((prev) => {
                    const exists = prev.some(
                      (selectedItem) => selectedItem.value === item.value
                    );
                    if (exists) {
                      return prev.filter(
                        (selectedItem) => selectedItem.value !== item.value
                      );
                    } else {
                      return [...prev, item];
                    }
                  });
                }
              }}
            >
              <ItemLabel>{t(item.label)}</ItemLabel>
              <RadioButton
                type="radio"
                id="selectDateType"
                checked={Boolean(
                  selectedBusinessLines.find(
                    (name) => name.value === item.value
                  ) ||
                    (!selectedBusinessLines.length && item.value === "0")
                )}
                onChange={() => {}}
              />
            </Item>
          ))}
        </List>
      </Wrapper>
      <BottomWrapper>
        <Button
          title={t("ChartFilter.ButtonTitle")}
          isDisabled={!isChanged}
          onClick={() => {
            if (isChanged) {
              handleClick(BottomSheetType.FILTER);
              submitBusinessLines(selectedBusinessLines);
            }
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default SelectBusinessLineMobile;
