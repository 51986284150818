import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IsMobileProps {
  isMobile: boolean;
}
export const Wrapper = styled.div<IsMobileProps>(
  ({ isMobile }) => css`
    padding: 20px 0;
    width: 80%;
    ${isMobile && "width: 100%;"}
  `
);

export const Title = styled.h3(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
    margin-bottom: 60px;
    @media (max-width: ${theme.breakpoints.xl}) {
      text-align: center;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 40px;
    }
  `
);
export const InputWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 300px 300px;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  `
);

export const ButtonWrapper = styled.div`
  grid-column: 1/-1;
`;
