import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import axiosInstance from "../../serveces/interceptor";
import config from "../../utils/config";
import { requestUpdateCompany } from "./company.types";

export const GET_COMPANY_INFO = "GET_COMPANY_INFO";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_COMPANY_BRANCH = "GET_COMPANY_BRANCH";
export const CREATE_COMPANY_BRANCH = "CREATE_COMPANY_BRANCH";
export const EDIT_COMPANY_BRANCH = "EDIT_COMPANY_BRANCH";
export const DELETE_COMPANY_BRANCH = "DELETE_COMPANY_BRANCH";
export const UPDATE_BRANCH_ORDERING = "UPDATE_BRANCH_ORDERING";
export const GET_COMPANY_BUSINESS_LINE = "GET_COMPANY_BUSINESS_LINE";
export const CREATE_COMPANY_BUSINESS_LINE = "CREATE_COMPANY_BUSINESS_LINE";
export const EDIT_COMPANY_BUSINESS_LINE = "EDIT_COMPANY_BUSINESS_LINE";
export const DELETE_COMPANY_BUSINESS_LINE = "DELETE_COMPANY_BUSINESS_LINE";
export const UPDATE_BUSINESS_LINE_ORDERING = "UPDATE_BUSINESS_LINE_ORDERING";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const getCompanyInfo = createAsyncThunk(GET_COMPANY_INFO, async () => {
  const response: AxiosResponse = await axiosInstance.get(
    config.api.endpoints.company.getCompany
  );

  return response.data;
});

export const updateCompany = createAsyncThunk(
  UPDATE_COMPANY,
  async (data: requestUpdateCompany) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.company.updateCompany,
      {
        ...data,
      }
    );

    return response.data;
  }
);

export const getCompanyBranch = createAsyncThunk(
  GET_COMPANY_BRANCH,
  async () => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.company.getCompanyBranch
    );
    return response.data;
  }
);

export const createCompanyBranch = createAsyncThunk(
  CREATE_COMPANY_BRANCH,
  async ({ name }: { name: string }) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.company.createCompanyBranch,
      {
        name,
      }
    );

    return response.data;
  }
);

export const editCompanyBranch = createAsyncThunk(
  EDIT_COMPANY_BRANCH,
  async (data: { id: number; name: string }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.company.editCompanyBranch,
      {
        ...data,
      }
    );
    return response.data;
  }
);
export const deleteCompanyBranch = createAsyncThunk(
  DELETE_COMPANY_BRANCH,
  async ({ id }: { id: number }) => {
    const response: AxiosResponse = await axiosInstance.delete(
      `${config.api.endpoints.company.deleteCompanyBranch}/${id}`
    );
    return response.data;
  }
);
export const updateBranchOrdering = createAsyncThunk(
  UPDATE_BRANCH_ORDERING,
  async (ids: number[]) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.company.updateBranchOrdering,
      { ids }
    );
    return response.data;
  }
);

// COMPANY_BUSINESS_LINE
export const getCompanyBusinessLine = createAsyncThunk(
  GET_COMPANY_BUSINESS_LINE,
  async () => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.company.getCompanyBusinessLine
    );
    return response.data;
  }
);

export const createCompanyBusinessLine = createAsyncThunk(
  CREATE_COMPANY_BUSINESS_LINE,
  async ({ name }: { name: string }) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.company.createCompanyBusinessLine,
      {
        name,
      }
    );

    return response.data;
  }
);

export const editCompanyBusinessLine = createAsyncThunk(
  EDIT_COMPANY_BUSINESS_LINE,
  async (data: { id: number; name: string }) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.company.editCompanyBusinessLine,
      {
        ...data,
      }
    );
    return response.data;
  }
);
export const deleteCompanyBusinessLine = createAsyncThunk(
  DELETE_COMPANY_BUSINESS_LINE,
  async ({ id }: { id: number }) => {
    const response: AxiosResponse = await axiosInstance.delete(
      `${config.api.endpoints.company.deleteCompanyBusinessLine}/${id}`
    );
    return response.data;
  }
);

export const updateBusinessLineOrdering = createAsyncThunk(
  UPDATE_BUSINESS_LINE_ORDERING,
  async (ids: number[]) => {
    const response: AxiosResponse = await axiosInstance.put(
      config.api.endpoints.company.updateBusinessLineOrdering,
      { ids }
    );
    return response.data;
  }
);

export const createCompany = createAsyncThunk(
  CREATE_COMPANY,
  async (data: {
    identificationCode: string;
    name: string;
    email: string;
    country: string;
    city: string;
    address: string;
    mobileCountryCode: string;
    mobile: string;
    companyCategoryId: number;
    companyFormId: number;
    postalCode: string;
    integrationType: number;
  }) => {
    const response: AxiosResponse = await axiosInstance.post(
      config.api.endpoints.company.createCompany,
      data
    );

    return response.data;
  }
);
