import { useTranslation } from "react-i18next";
import { CustomTableHeader, TableTitle } from "./TableHeader.style";

interface ITableHeader {
  headerNames: Array<string>;
}

const TableHeader = ({ headerNames }: ITableHeader) => {
  const { t } = useTranslation();
  return (
    <CustomTableHeader>
      <tr>
        {headerNames.map((itemName, index) => (
          <TableTitle key={index}>{t(itemName)}</TableTitle>
        ))}
      </tr>
    </CustomTableHeader>
  );
};

export default TableHeader;
