import * as Yup from "yup";

export const CompanyInfoSchema = Yup.object().shape({
  email: Yup.string()
    .email("CompanyInfoSchema.EmailError")
    .required("CompanyInfoSchema.Required"),
  country: Yup.string().required("CompanyInfoSchema.Required"),
  city: Yup.string().required("CompanyInfoSchema.Required"),
  address: Yup.string().required("CompanyInfoSchema.Required"),
  postalCode:
    Yup.string()
    .required("Required"),
});
