import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface IsMobile {
  isMobile: boolean;
}

export const TitleWrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: grid;
    grid-template-columns: ${isMobile ? "1fr" : "4fr 3fr"};
  `
);

export const Title = styled.h2<IsMobile>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
    ${isMobile && "text-align: center;"}
  `
);

export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

export const Content = styled.h3(
  ({ theme }) => css`
    width: 120px;
    text-align: center;
    ${theme.fonts.AvenirMedium}
    font-size: 18px;
    text-align: right;
  `
);
