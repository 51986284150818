import React, { useState } from "react";
import { Container, Item, Wrapper } from "./ReportalReports.style";

import { useTranslation } from "react-i18next";
import WorkingOutCome from "./workingOutcome/WorkingOutcome";
import FinanceDetailed from "./financialDetailed/FinancialDetailed";

const enum ReportalReportsType {
  WORKING_OUTCOME = "WORKING_OUTCOME",
  FINANCE_DETAILED_REPORT = "FINANCE_DETAILED_REPORT",
}

const ReportalReports = () => {
  const [openTab, setOpenTab] = useState<ReportalReportsType>(
    ReportalReportsType.WORKING_OUTCOME
  );

  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Item
          onClick={() => setOpenTab(ReportalReportsType.WORKING_OUTCOME)}
          clicked={openTab === ReportalReportsType.WORKING_OUTCOME}
        >
          {t("ReportalReports.WorkingOutcome")}
        </Item>
        <Item
          onClick={() =>
            setOpenTab(ReportalReportsType.FINANCE_DETAILED_REPORT)
          }
          clicked={openTab === ReportalReportsType.FINANCE_DETAILED_REPORT}
        >
          {t("ReportalReports.FinancialDetailed")}
        </Item>
      </Wrapper>
      <Container>
        {openTab === ReportalReportsType.WORKING_OUTCOME && <WorkingOutCome />}
        {openTab === ReportalReportsType.FINANCE_DETAILED_REPORT && (
          <FinanceDetailed />
        )}
      </Container>
    </>
  );
};

export default ReportalReports;
