import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    padding: 40px 30px 0;
    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 20px 15px 0;
    }
  `
);

export const Title = styled.h3(
  ({ theme }) => css`
    margin-bottom: 20px;
    font-size: 16px;
    ${theme.fonts.AvenirDemi}
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: 15px;
    }
  `
);
export const AccountWrapper = styled.div(
  ({ theme }) => css`
    max-height: 230px;
    min-height: 160px;
    overflow: auto;
    margin-top: 35px;

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 15px;
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 10px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 15px;
    }
  `
);
