import styled from "@emotion/styled";

export const LoaderIconWrapper = styled.tbody`
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  .loading-svg {
    margin-right: 3px;
    animation: spin 1s linear infinite;
  }
`;
