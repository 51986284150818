import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ size: "medium" | "small" }>(
  ({ size, theme }) => css`
    ${theme.fonts.HelveticaNeue}
    z-index: 100;
    position: fixed;
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    border-radius: 12px;
    background: ${theme.colors.white};
    ${size === "medium"
      ? css`
          width: 768px;
        `
      : size === "small" &&
        css`
          width: 528px;
        `}
  `
);

export const Overlay = styled.div(
  ({ theme }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${theme.colors.black900};
    opacity: 0.5;
    z-index: 99;
  `
);
// TODO Border color
export const Header = styled.div<{ size: "medium" | "small" }>(
  ({ size }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(101, 101, 101, 0.08);
    ${size === "medium"
      ? css`
          padding: 20px 24px;
        `
      : size === "small" &&
        css`
          padding: 18px 24px;
        `}
  `
);

export const Title = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    line-height: normal;
  `
);

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid rgba(101, 101, 101, 0.08);
  padding: 12px 24px;
`;
