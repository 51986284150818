import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `
);

export const ContentWrapper = styled.div`
  text-align: center;
  width: 315px;
  margin: auto;
`;
export const SuccessPageContent = styled.div`
  text-align: center;
  width: 400px;
  margin: auto;
`;

export const Title = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    margin-bottom: 6px;
  `
);

export const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textSecondary};
    font-size: 12px;
    line-height: 18px; /* 150% */
  `
);

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CreatedContentWrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 64px;
    gap: 16px;
  `
);

export const IconWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    background-color: ${theme.colors.success600};
  `
);
