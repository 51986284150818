import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/shared/button/Button";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  createCompanyBusinessLine,
  deleteCompanyBusinessLine,
  editCompanyBusinessLine,
  getCompanyBusinessLine,
  updateBusinessLineOrdering,
} from "../../../../store/company/companyAction";

import { CompanyBusinessLineType } from "../../../../store/company/company.types";
import ConfirmationModal from "../../../../components/shared/confirmationModal/ConfirmationModal";
import Loader from "../../../../components/shared/loader/Loader";
import { showSuccessMessage } from "../../../../store/user/userSlice";
import { DropResult } from "react-beautiful-dnd";
import CompanyBranchAndLineManage from "../../../../components/desktop/companyInfo/companyBranchAndLineManage/CompanyBranchAndLineManage";
import { ButtonWrapper, TableWrapper } from "./CompanyBusinessLine.style";
import CompanyBusinessLIneTable from "../../../../components/desktop/companyInfo/companyBusinessLine/companyBusinessLineTable/CompanyBusinessLineTable";

const CompanyBusinessLine = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [editModalData, setEditModalData] =
    useState<CompanyBusinessLineType | null>(null);

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  );
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const fetchBusinessLine = useCallback(() => {
    setIsLoading(true);
    return dispatch(getCompanyBusinessLine()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    fetchBusinessLine();
  }, [fetchBusinessLine, selectedCompany]);

  const handleCreateBusinessLine = (name: string) => {
    setIsLoading(true);
    dispatch(createCompanyBusinessLine({ name })).then(() => {
      fetchBusinessLine();
    });
  };

  const handleEditCompanyBusinessLine = (id: number, name: string) => {
    setIsLoading(true);
    dispatch(editCompanyBusinessLine({ id, name })).then(() => {
      fetchBusinessLine();
    });
  };
  const handleDeleteBusinessLine = () => {
    if (deleteModalId) {
      dispatch(deleteCompanyBusinessLine({ id: deleteModalId })).then(() => {
        fetchBusinessLine();
      });
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const companyBranchData = companyBusinessLine.map((item) => item.id);
    const items = Array.from(companyBranchData);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    setIsLoading(true);
    dispatch(updateBusinessLineOrdering(items)).then(() => {
      fetchBusinessLine().then(() => {
        dispatch(showSuccessMessage(true));
      });
    });
  };
  return (
    <>
      <ButtonWrapper>
        <Button
          title={t("CompanyBusinessLine.ButtonTitle")}
          onClick={() => setIsCreateModalOpen(true)}
        />
      </ButtonWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <TableWrapper>
          <CompanyBusinessLIneTable
            companyBusinessLines={companyBusinessLine}
            handleOnDragEnd={handleOnDragEnd}
            handleOpenEditModal={setEditModalData}
            handleDeleteModal={setDeleteModalId}
          />
        </TableWrapper>
      )}

      {isCreateModalOpen && (
        <CompanyBranchAndLineManage
          initialValue={{ name: "" }}
          handleClose={() => setIsCreateModalOpen(false)}
          handleSubmitBranch={handleCreateBusinessLine}
        />
      )}
      {editModalData && (
        <CompanyBranchAndLineManage
          initialValue={editModalData}
          handleClose={() => setEditModalData(null)}
          handleSubmitBranch={(name) => {
            handleEditCompanyBusinessLine(editModalData.id, name);
          }}
        />
      )}
      {deleteModalId && (
        <ConfirmationModal
          description={t("CompanyBusinessLine.ConfirmationModal")}
          handleClose={() => setDeleteModalId(null)}
          handleConfirm={() => {
            handleDeleteBusinessLine();
            setDeleteModalId(null);
          }}
        />
      )}
    </>
  );
};

export default CompanyBusinessLine;
