import * as Yup from "yup";

export const PersonalInfoSchema = Yup.object().shape({
  firstName: Yup.string().required("LoginFormError.Required"),
  lastName: Yup.string().required("LoginFormError.Required"),
  email: Yup.string()
    .email("LoginFormError.EmailError")
    .required("LoginFormError.Required"),
  mobile: Yup.string().required("LoginFormError.Required"),
});
