import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0 30px;
    }
  `
);

export const TableRowItem = styled.td`
  vertical-align: middle;
`;

export const GroupCardWrapper = styled.td`
  display: flex;
  align-items: center;
  gap: 10px;
  vertical-align: middle;
`;

export const DefaultContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    width: 140px;
    height: 37px;
    border-radius: 15px;
  `
);

export const GroupImage = styled.div(
  ({ theme }) => css`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: ${theme.colors.lightGrey};
  `
);
export const GroupCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const GroupLine = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    width: 122px;
    height: 17px;
  `
);
export const SmallContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    width: 41px;
    height: 17px;
  `
);

export const MediumContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGrey};
    width: 83px;
    height: 17px;
    border-radius: 15px;
  `
);
