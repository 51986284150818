import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    "UpdatePasswordErrors.CurrentPasswordRequired"
  ),
  newPassword: Yup.string()
    .required("UpdatePasswordErrors.NewPasswordRequired")
    .min(6, "UpdatePasswordErrors.NewPasswordMinLength"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "UpdatePasswordErrors.ConfirmNewPassword")
    .required("UpdatePasswordErrors.ConfirmNewPasswordRequired"),
});

export const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
