import { ReactNode } from "react";

import TableHeader from "../tableHeader/TableHeader";
import { CustomTable } from "./Table.style";

interface ITableProps {
  headerNames: Array<string>;
  children: ReactNode;
}

const Table = ({ headerNames, children }: ITableProps) => {
  return (
    <CustomTable>
      <TableHeader headerNames={headerNames} />
      {children}
    </CustomTable>
  );
};

export default Table;
