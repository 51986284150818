import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { ReactComponent as CloseIconSvg } from "../../../assets/svgs/close.svg";

type StatusItemProps = {
  type: "medium" | "small";
};

export const Overlay = styled.div(
  ({ theme }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  `
);

export const CustomPopUp = styled.div<StatusItemProps>(
  ({ type, theme }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${type === "medium" && "555px"};
    width: ${type === "small" && "412px"};
    background-color: ${theme.colors.white};
    padding: ${type === "medium" && "30px"};
    padding: ${type === "small" && "33px 55px"};
    border-radius: 15px;
    z-index: 99;
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 90%;
      padding: 20px;
    }
  `
);

export const Title = styled.h3(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
  `
);

export const CloseIcon = styled(CloseIconSvg)`
  box-sizing: content-box;
  padding: 5px;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
