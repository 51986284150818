import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Overlay = styled.div(
  ({ theme }) => css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  `
);

export const CustomPopUp = styled.div(
  ({ theme }) => css`
    text-align: center;
    width: 412px;
    padding: 40px 30px 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    background-color: ${theme.colors.white};
    z-index: 99;
  `
);
export const PopUpContent = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 14px;
  `
);
export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 75px;
  gap: 18px;
`;
