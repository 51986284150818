import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div``;

export const CustomTable = styled.table`
  width: 100%;
  position: relative;
  margin-top: 50px;
`;
export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0;
      min-width: 100px;
    }
  `
);
export const TableRowItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);

export const ExportExcelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  margin-top: 20px;
`;
