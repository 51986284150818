import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
`;

interface LabelProps {
  hasValue: boolean;
  isFocused: boolean;
}

export const LabelWrapper = styled.div<LabelProps>(
  ({ hasValue, isFocused, theme }) => css`
    position: absolute;
    z-index: 1;
    top: ${isFocused ? "-10px" : hasValue ? "-10px" : "11px"};
    left: 15px;
    color: ${theme.colors.grey};
    color: ${isFocused && theme.colors.primary};
    font-size: ${isFocused ? "13px" : hasValue ? "13px" : "16px"};
    transition: all 0.3s ease-in-out;

    &::before {
      content: "";
      box-sizing: border-box;
      display: block;
      position: absolute;
      pointer-events: none;
      top: 11px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: inherit;
      margin: calc(1 * -3px);
      background-color: ${theme.colors.white};
    }
  `
);
