import { css } from "@emotion/react";
import styled from "@emotion/styled";

// TODO color and gap. დეტალურად ნახვა has invisible paddings
export const UnMappedWrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    margin: 24px 0 16px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${theme.colors.negative100};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `
);

export const InfoWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
export const UnMappedTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 14px;
    line-height: normal;
  `
);

// TODO font weight does not work
export const UnMappedSubTitle = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: #717171;
    font-size: 12px;
    line-height: 17px;
  `
);
// TODO design says it have 621px but only 421 breaks line
export const InfoContentWrapper = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
  `
);

export const UnMappedIconWrapper = styled.div`
  width: 21px;
  height: 21px;
`;
