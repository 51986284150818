import React, { useMemo, useState } from "react";

import AccountItem from "../../../shared/accountItem/AccountItem";
import Button from "../../../shared/button/Button";
import { AccountWrapper, ButtonWrapper, Title } from "./ChoseAccountForm.style";
import { useTranslation } from "react-i18next";
import { userCompany } from "../../../../store/user/user.types";
import SearchInput from "../../../shared/searchInput/SearchInput";

interface ChooseAccountFormProps {
  companies: Array<userCompany>;
  loading: boolean;
  onSubmit: (
    defaultCompanyId: number | null,
    selectedCompanyId: number | null
  ) => void;
}

const ChooseAccountForm = ({
  companies,
  loading,
  onSubmit,
}: ChooseAccountFormProps) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [defaultOption, setDefaultOption] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const handleSelectOption = (id: number): void => {
    setSelectedOption((prevId) => {
      if (prevId === id) {
        return null;
      }
      return id;
    });
  };

  const filteredCompanies = useMemo(() => {
    if (searchValue) {
      return companies.filter((company) =>
        company.name.toUpperCase().includes(searchValue.toUpperCase())
      );
    }
    return companies;
  }, [searchValue, companies]);

  const handleSelectDefault = (id: number): void => {
    setDefaultOption((prevId) => (prevId === id ? null : id));
  };
  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <Title>{t("ChooseAccount.Title")}</Title>
      <SearchInput
        placeholder={t("ChooseAccount.InputText")}
        value={searchValue}
        handleChange={handleChange}
      />
      <AccountWrapper>
        {filteredCompanies.map((company) => (
          <AccountItem
            key={company.id}
            title={company.name}
            id={company.id}
            defaultOption={defaultOption}
            selectedOption={selectedOption}
            handleSelectDefault={handleSelectDefault}
            handleSelectOption={handleSelectOption}
          />
        ))}
      </AccountWrapper>
      <ButtonWrapper>
        <Button
          isLoading={loading}
          title={t("ChooseAccount.ButtonText")}
          isDisabled={selectedOption === null}
          onClick={() => {
            onSubmit(defaultOption, selectedOption);
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default ChooseAccountForm;
