import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px;
`;

export const SideBarFooter = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${theme.colors.grey100};
    padding: 24px;
  `
);
export const FAQWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const FAQTitle = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 14px;
    padding-left: 12px;
  `
);
