import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  createCompanyBranch,
  editCompanyBranch,
  deleteCompanyBranch,
  getCompanyBranch,
  updateBranchOrdering,
} from "../../../../store/company/companyAction";

import Button from "../../../../components/shared/button/Button";
import { ButtonWrapper, Wrapper } from "./CompanyBranches.style";

import ConfirmationModal from "../../../../components/shared/confirmationModal/ConfirmationModal";
import Loader from "../../../../components/shared/loader/Loader";

import { showSuccessMessage } from "../../../../store/user/userSlice";
import { DropResult } from "react-beautiful-dnd";
import { CompanyBranchType } from "../../../../store/company/company.types";
import CompanyBranchTable from "../../../../components/desktop/companyInfo/companyBranch/companyBranchTable/CompanyBranchTable";
import CompanyBranchAndLineManage from "../../../../components/desktop/companyInfo/companyBranchAndLineManage/CompanyBranchAndLineManage";

const CompanyBranch = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editModalData, setEditModalData] = useState<CompanyBranchType | null>(
    null
  );

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  );

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const fetchCompanyBranches = useCallback(() => {
    setIsLoading(true);
    return dispatch(getCompanyBranch()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    fetchCompanyBranches();
  }, [fetchCompanyBranches, selectedCompany]);

  const handleCreateBranch = (name: string) => {
    setIsLoading(true);
    dispatch(createCompanyBranch({ name })).then(() => {
      fetchCompanyBranches();
    });
  };
  const handleEditBranch = (id: number, name: string) => {
    setIsLoading(true);
    dispatch(editCompanyBranch({ id, name })).then(() => {
      fetchCompanyBranches();
    });
  };
  const handleDeleteBranch = () => {
    if (deleteModalId !== null) {
      dispatch(deleteCompanyBranch({ id: deleteModalId })).then(() => {
        fetchCompanyBranches();
      });
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const companyBranchData = companyBranches.map((item) => item.id);
    const items = Array.from(companyBranchData);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    setIsLoading(true);
    dispatch(updateBranchOrdering(items)).then(() => {
      fetchCompanyBranches().then(() => {
        dispatch(showSuccessMessage(true));
      });
    });
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          title={t("CompanyBranch.ButtonTitle")}
          onClick={() => setIsCreateModalOpen(true)}
        />
      </ButtonWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <CompanyBranchTable
            companyBranches={companyBranches}
            handleOpenEditModal={setEditModalData}
            handleDeleteModal={setDeleteModalId}
            handleOnDragEnd={handleOnDragEnd}
          />
        </Wrapper>
      )}

      {isCreateModalOpen && (
        <CompanyBranchAndLineManage
          initialValue={{ name: "" }}
          handleClose={() => setIsCreateModalOpen(false)}
          handleSubmitBranch={handleCreateBranch}
        />
      )}
      {deleteModalId && (
        <ConfirmationModal
          description={t("CompanyBranch.ConfirmationModal")}
          handleClose={() => setDeleteModalId(null)}
          handleConfirm={() => {
            handleDeleteBranch();
            setDeleteModalId(null);
          }}
        />
      )}
      {editModalData && (
        <CompanyBranchAndLineManage
          initialValue={editModalData}
          handleClose={() => setEditModalData(null)}
          handleSubmitBranch={(name: string) => {
            handleEditBranch(editModalData.id, name);
          }}
        />
      )}
    </>
  );
};

export default CompanyBranch;
