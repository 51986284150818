import React from "react";
import { Field, Form, Formik } from "formik";
import { Wrapper } from "./CompanyBranchAndLineManage.style";
import { useTranslation } from "react-i18next";
import Modal from "../../../shared/modal/Modal";
import Input from "../../../shared/input/Input";
import Button from "../../../shared/button/Button";

interface CompanyBranchAndLineManageProps {
  initialValue: { name: string };
  handleClose: () => void;
  handleSubmitBranch: (name: string) => void;
}

const CompanyBranchAndLineManage = ({
  initialValue,
  handleClose,
  handleSubmitBranch,
}: CompanyBranchAndLineManageProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      close={handleClose}
      showCloseIcon={true}
      headerContent={""}
      type="small"
      title={
        initialValue.name
          ? t("ManageBranchAndLine.EditText")
          : t("ManageBranchAndLine.CreateText")
      }
    >
      <Formik
        initialValues={{ text: initialValue.name }}
        onSubmit={(values) => {
          handleSubmitBranch(values.text);
          handleClose();
        }}
      >
        {() => (
          <Form>
            <Wrapper>
              <Field
                name="text"
                label={t("ManageBranchAndLine.Name")}
                component={Input}
                type="text"
              />

              <Button
                title={t("ManageBranchAndLine.Submit")}
                type="submit"
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CompanyBranchAndLineManage;
