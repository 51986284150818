import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    list-style-type: none; /* Remove default bullets */
    padding-left: 0;
    background: ${theme.colors.lightGrey};
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
  `
);
export const Item = styled.span<{ color: string }>(
  ({ color }) => css`
    position: relative;
    padding-left: 15px;
    color: ${color};

    &::before {
      content: "•";
      position: absolute;
      left: 0;
      color: ${color};
    }
  `
);
