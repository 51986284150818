import React from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import {
  Wrapper,
  PageController,
  PageNumberWrapper,
  PageNumber,
} from "./Pagination.style";

interface PaginationProps {
  totalPage: number;
  selectedPage: number;
  limit: number;
  siblings: number;
  handlePage: (currentPage: number) => void;
}

export const returnPaginationRange = (
  totalPage: number,
  page: number,
  siblings: number
) => {
  let totalPageNoArray = 7 + siblings;
  if (totalPageNoArray >= totalPage) {
    return _.range(1, totalPage + 1);
  }
  let leftSiblingsIndex = Math.max(page - siblings, 1);
  let showLeftDots = leftSiblingsIndex > 2;

  let rightSiblingsIndex = Math.min(page + siblings, totalPage);
  let showRightDots = rightSiblingsIndex < totalPage - 2;

  if (!showLeftDots && showRightDots) {
    let leftItemsCount = 3 + 2 * siblings;
    let leftRange = _.range(1, leftItemsCount + 1);
    return [...leftRange, "...", totalPage];
  } else if (showLeftDots && !showRightDots) {
    let rightItemsCount = 3 + 2 * siblings;
    let rightRange = _.range(totalPage - rightItemsCount + 1, totalPage + 1);
    return [1, "...", ...rightRange];
  } else {
    let middleRange = _.range(leftSiblingsIndex, rightSiblingsIndex + 1);
    return [1, "...", ...middleRange, "...", totalPage];
  }
};

const Pagination = ({
  totalPage,
  selectedPage,
  siblings,
  handlePage,
}: PaginationProps) => {
  const { t } = useTranslation();

  const handlePrev = () => {
    if (selectedPage - 1 < 1) {
      return;
    }
    handlePage(selectedPage - 1);
  };

  const handleNext = () => {
    if (selectedPage + 1 > totalPage) {
      return;
    }
    handlePage(selectedPage + 1);
  };

  let array = returnPaginationRange(totalPage, selectedPage, siblings);

  return (
    <Wrapper>
      <PageController onClick={handlePrev} disabled={selectedPage === 1}>
        {t("Pagination.PrevButton")}
      </PageController>
      <PageNumberWrapper>
        {array.map((item, index) => (
          <PageNumber
            key={index}
            onClick={() => {
              if (typeof item === "number") {
                handlePage(item);
              }
            }}
            selected={selectedPage === item}
          >
            {item}
          </PageNumber>
        ))}
      </PageNumberWrapper>
      <PageController
        disabled={selectedPage === totalPage}
        onClick={handleNext}
      >
        {t("Pagination.NextButton")}
      </PageController>
    </Wrapper>
  );
};

export default Pagination;
