import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getExpensesInfo } from "../../../../store/dashboard/dashboardAction";

import {
  BarChartGrouper,
  BarChartGrouperTitle,
  ChartTitle,
  GridContainer,
  GroupedChartWrapper,
  Header,
  LastUpdated,
  TabsWrapper,
  Wrapper,
} from "./Expenses.style";

import GroupBarChart from "../../../../components/desktop/charts/groupBar/GroupBarChart";
import DonutChart from "../../../../components/desktop/charts/donutChart/DonutChart";
import {
  dashboardDateFormat,
  generatePeriodTypes,
} from "../../../../helpers/helper";
import { BottomSheetType, ChartGroupTypes } from "../../../../enums/enum";
import BarChartContainer from "../../../../components/desktop/charts/barChart/BarChart";
import ChartFilter from "../../../../components/desktop/chartFilter/ChartFilter";
import {
  Filter,
  FrontSideFilter,
} from "../../../../store/dashboard/dashboard.types";
import ChartHolder from "../../../../components/shared/chartEmptyHolder/ChartEmptyHolder";
import Loader from "../../../../components/shared/loader/Loader";
import ChartFilterButtonMobile from "../../../../components/mobile/filerButton/FilerButtonMobile";
import { setBottomSheet } from "../../../../store/user/userSlice";

import ChartLabels from "../../../../components/desktop/charts/chartLabels/ChartLabels";
import {
  generateExpensesChartData,
  resetGroupeName,
  updateFilter,
  updateFrontSideFilter,
} from "../../../../store/dashboard/dashboardSlice";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../../store/company/companyAction";
import Tab from "../../../../components/shared/tabs/tab/Tab";

enum ExpensesTabs {
  GROUPED = "GROUPED",
  BUSINESS_LINE = "BUSINESS_LINE",
  BRANCHES = "BRANCHES",
}

const Expenses = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loadFilterData, setLoadFilterData] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ExpensesTabs.GROUPED);

  const filter = useAppSelector((state) => state.dashboard.filter);

  const frontSideFilter = useAppSelector(
    (state) => state.dashboard.frontSideFilter
  );

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const groupedChartData = useAppSelector(
    (state) => state.dashboard.expenses.groupedChartData
  );

  const donutChartData = useAppSelector(
    (state) => state.dashboard.expenses.donutChartData
  );

  const barsData = useAppSelector((state) => state.dashboard.expenses.barsData);

  const groupedChartDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedExpenses.groupedChartData
  );

  const donutChartDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedExpenses.donutChartData
  );

  const barsDataForGroup = useAppSelector(
    (state) => state.dashboard.groupedExpenses.barsData
  );

  const groupedChartDataForBranch = useAppSelector(
    (state) => state.dashboard.branchExpenses.groupedChartData
  );

  const donutChartDataForBranch = useAppSelector(
    (state) => state.dashboard.branchExpenses.donutChartData
  );

  const barsDataForBranch = useAppSelector(
    (state) => state.dashboard.branchExpenses.barsData
  );

  const groupedChartDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineExpenses.groupedChartData
  );

  const donutChartDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineExpenses.donutChartData
  );

  const barsDataForBusinessLine = useAppSelector(
    (state) => state.dashboard.businessLineExpenses.barsData
  );

  const loadingDashboard = useAppSelector((state) => state.dashboard.loading);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const expensesData = useAppSelector((state) => state.dashboard.expensesData);

  const expensesGroupFilterOptions = useAppSelector(
    (state) => state.dashboard.expensesGroupFilterOptions
  );

  const lastUpdatedTime = expensesData?.lastUpdateTime;

  useEffect(() => {
    dispatch(generateExpensesChartData());
  }, [dispatch, expensesData, frontSideFilter]);

  useEffect(() => {
    setLoadFilterData(true);

    const promises = [
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];

    Promise.all(promises).finally(() => {
      setLoadFilterData(false);
    });
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    if (filter.fromDate && filter.toDate && filter.periodType && !isMobile) {
      dispatch(getExpensesInfo(filter));
    }
  }, [filter, selectedCompany, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetGroupeName());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      updateFrontSideFilter({
        group: ChartGroupTypes.Grouped,
        lines: [],
        branches: [],
        groupNames: [],
      })
    );
    setSelectedTab(ExpensesTabs.GROUPED);
  }, [selectedCompany, dispatch]);

  const memoizedGroupChartData = useMemo(() => {
    if (selectedTab === ExpensesTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? groupedChartDataForGroup
        : groupedChartData;
    }

    if (selectedTab === ExpensesTabs.BRANCHES) {
      return groupedChartDataForBranch;
    }

    if (selectedTab === ExpensesTabs.BUSINESS_LINE) {
      return groupedChartDataForBusinessLine;
    }

    return groupedChartData;
  }, [
    selectedTab,
    frontSideFilter.group,
    groupedChartDataForGroup,
    groupedChartDataForBranch,
    groupedChartDataForBusinessLine,
    groupedChartData,
  ]);

  const memoizedDonutChartData = useMemo(() => {
    if (selectedTab === ExpensesTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? donutChartDataForGroup
        : donutChartData;
    }

    if (selectedTab === ExpensesTabs.BRANCHES) {
      return donutChartDataForBranch;
    }

    if (selectedTab === ExpensesTabs.BUSINESS_LINE) {
      return donutChartDataForBusinessLine;
    }

    return donutChartData;
  }, [
    selectedTab,
    frontSideFilter.group,
    donutChartDataForGroup,
    donutChartDataForBranch,
    donutChartDataForBusinessLine,
    donutChartData,
  ]);

  const memoizedLabels = useMemo(() => {
    if (selectedTab === ExpensesTabs.GROUPED) {
      return frontSideFilter.group === ChartGroupTypes.Grouped
        ? groupedChartDataForGroup.labels
        : groupedChartData.labels;
    }

    if (selectedTab === ExpensesTabs.BRANCHES) {
      return groupedChartDataForBranch.labels;
    }

    if (selectedTab === ExpensesTabs.BUSINESS_LINE) {
      return groupedChartDataForBusinessLine.labels;
    }

    return groupedChartData.labels;
  }, [
    selectedTab,
    frontSideFilter.group,
    groupedChartDataForGroup.labels,
    groupedChartDataForBranch.labels,
    groupedChartDataForBusinessLine.labels,
    groupedChartData,
  ]);

  const handleFilter = (values: Filter & FrontSideFilter) => {
    if (
      frontSideFilter.group !== values.group ||
      !_.isEqual(frontSideFilter.lines, values.lines) ||
      !_.isEqual(frontSideFilter.branches, values.branches) ||
      !_.isEqual(frontSideFilter.groupNames, values.groupNames)
    ) {
      setLoadFilterData(true);
      dispatch(
        updateFrontSideFilter({
          group: values.group,
          lines: values.lines,
          branches: values.branches,
          groupNames: values.groupNames,
        })
      );
      setTimeout(() => {
        setLoadFilterData(false);
      }, 500);
    }

    if (
      filter.periodType !== values.periodType ||
      filter.fromDate !== values.fromDate ||
      filter.toDate !== values.toDate
    ) {
      dispatch(
        updateFilter({
          periodType: values.periodType,
          fromDate: values.fromDate,
          toDate: values.toDate,
        })
      );
    }
  };

  const handleClick = (tab: ExpensesTabs) => {
    setSelectedTab(tab);
  };

  return (
    <>
      {loadFilterData || loadingDashboard === "pending" ? (
        <Wrapper>
          <Loader />
        </Wrapper>
      ) : (
        <Wrapper>
          {Boolean(companyBranches.length || companyBusinessLine.length) && (
            <Header>
              <TabsWrapper>
                <Tab
                  title={t("Expenses.ByGroup")}
                  isClicked={selectedTab === ExpensesTabs.GROUPED}
                  handleClick={() => {
                    handleClick(ExpensesTabs.GROUPED);
                  }}
                />
                {Boolean(companyBranches.length) && (
                  <Tab
                    title={t("Expenses.ByBranches")}
                    isClicked={selectedTab === ExpensesTabs.BRANCHES}
                    handleClick={() => {
                      handleClick(ExpensesTabs.BRANCHES);
                    }}
                  />
                )}
                {Boolean(companyBusinessLine.length) && (
                  <Tab
                    title={t("Expenses.ByBusinessLine")}
                    isClicked={selectedTab === ExpensesTabs.BUSINESS_LINE}
                    handleClick={() => {
                      handleClick(ExpensesTabs.BUSINESS_LINE);
                    }}
                  />
                )}
              </TabsWrapper>
              {lastUpdatedTime && (
                <LastUpdated>
                  last updated: {dashboardDateFormat(lastUpdatedTime)}
                </LastUpdated>
              )}
            </Header>
          )}
          {isMobile ? (
            <ChartFilterButtonMobile
              handleClick={() => {
                dispatch(setBottomSheet(BottomSheetType.FILTER));
              }}
            />
          ) : (
            <ChartFilter
              filterData={filter}
              frontSideFilter={frontSideFilter}
              hasGroupBy={selectedTab === ExpensesTabs.GROUPED}
              options={generatePeriodTypes()}
              groupOption={expensesGroupFilterOptions}
              branchOptions={companyBranches}
              lineOptions={companyBusinessLine}
              handleSubmit={(values) => {
                handleFilter(values);
              }}
            />
          )}
          {memoizedGroupChartData?.data.length ? (
            <>
              <GroupedChartWrapper isMobile={isMobile}>
                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("Expenses.GroupBarChartTitle")}
                  </ChartTitle>
                  <GroupBarChart
                    stackId="a"
                    chartData={memoizedGroupChartData}
                  />
                </div>
                {isMobile && <ChartLabels labels={memoizedLabels} />}

                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("Expenses.DoughnutChartTitle")}
                  </ChartTitle>
                  <DonutChart chartData={memoizedDonutChartData} />
                </div>
              </GroupedChartWrapper>
              {!isMobile && <ChartLabels labels={memoizedLabels} />}
              <GridContainer
                hasGrid={frontSideFilter.group !== ChartGroupTypes.Grouped}
                isMobile={isMobile}
              >
                {selectedTab === ExpensesTabs.GROUPED && (
                  <>
                    {frontSideFilter.group === ChartGroupTypes.Grouped
                      ? barsDataForGroup?.map((item: any, index: number) => (
                          <div key={index}>
                            {item.data.length && item.labels?.length ? (
                              <>
                                <ChartTitle isMobile={isMobile}>
                                  {item.labels[0]}
                                </ChartTitle>
                                <BarChartContainer chartData={item} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))
                      : barsData?.map((item, index: number) => (
                          <div key={index}>
                            <BarChartGrouperTitle>
                              {item.label}
                            </BarChartGrouperTitle>
                            <BarChartGrouper key={index}>
                              {item.data.map(
                                (itemData: any, itemIndex: any) => (
                                  <div key={itemIndex}>
                                    {itemData.data.length ? (
                                      <>
                                        <ChartTitle isMobile={isMobile}>
                                          {itemData.labels[0]}
                                        </ChartTitle>
                                        <BarChartContainer
                                          chartData={itemData}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )
                              )}
                            </BarChartGrouper>
                          </div>
                        ))}
                  </>
                )}
                {selectedTab === ExpensesTabs.BRANCHES &&
                  barsDataForBranch?.map((item, index: number) => (
                    <div key={index}>
                      {item.data.length ? (
                        <>
                          <ChartTitle isMobile={isMobile}>
                            {item.labels[0]}
                          </ChartTitle>
                          <BarChartContainer chartData={item} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}

                {selectedTab === ExpensesTabs.BUSINESS_LINE &&
                  barsDataForBusinessLine?.map((item, index: number) => (
                    <div key={index}>
                      {item.data.length ? (
                        <>
                          <ChartTitle isMobile={isMobile}>
                            {item.labels[0]}
                          </ChartTitle>
                          <BarChartContainer chartData={item} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
              </GridContainer>
            </>
          ) : (
            <ChartHolder title="" />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Expenses;
