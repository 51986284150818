import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, InputWrapper, Title, Wrapper } from "./Mapping.style";
import SearchInput from "../../../components/shared/searchInput/SearchInput";
import { useAppDispatch, useAppSelector } from "../../../store";

import {
  createAccountMappingGroup,
  deleteMappingItem,
  deleteMappingGroup,
  getMappingList,
  editMappingItem,
  accountsAutoMapping,
  changeGroupsOrder,
  editMappingGroup,
  changeItemsOrder,
  createMappingItem,
  createAccountMappingWithGroup,
} from "../../../store/mapping/mappingAction";

import { useTranslation } from "react-i18next";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../store/user/userSlice";
import UnmappedGroup from "../../../components/desktop/mapping/unmappedGroup/UnmappedGroup";
import MappingLoading from "../../../components/desktop/mapping/mappingLoading/MappingLoading";
import MappingTypes from "../../../components/desktop/mapping/MappingTypes";
import DeleteConfirmationModal from "../../../components/desktop/mapping/deleteConfirmationModal/DeleteConfirmationModal";
import AddGroupModal from "../../../components/desktop/mapping/addGroupModal/AddGroupModal";
import UnmappedModal from "../../../components/desktop/mapping/unmappedModal/UnmappedModal";
import EditMappingItemModal from "../../../components/desktop/mapping/editMappingItemModal/EditMappingItemModal";
import {
  getCompanyBranch,
  getCompanyBusinessLine,
} from "../../../store/company/companyAction";
import {
  AccountMappingGroup,
  AccountMappingType,
  MappingItemRequest,
  MappingItemType,
  MappingItemWithGroupRequest,
} from "../../../store/mapping/mapping.types";
import { ActionType } from "../../../store/type";
import { filterDataByName } from "../../../helpers/mappingHelper";
import AddMappingItem from "../../../components/desktop/mapping/addMappingItem/AddMappingItem";

enum CashFlowType {
  Receive = 1,
  Paid = 2,
}

const Mapping = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [openTypeId, setOpenTypeId] = useState<number | null>(null);
  const [openGroupId, setOpenGroupId] = useState<number | null>(null);

  const [openAddMappingItemModalData, setOpenAddMappingItemModalData] =
    useState<{
      accountId: string;
      name: string;
    } | null>(null);

  const [openModal, setOpenModal] = useState(false);

  const [groupInformation, setGroupInformation] = useState<{
    id?: null | number;
    name?: string;
    typeId?: null | number;
    isCreated?: boolean;
  }>({
    name: "",
    id: null,
    typeId: null,
    isCreated: false,
  });

  const [mappingItemData, setMappingItemData] =
    useState<MappingItemType | null>(null);

  const [groupOptions, setGroupOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [deletedItemInfo, setDeletedItemInfo] = useState<{
    id?: number | null;
    name: string;
    accountMappingTypeId: number | null;
    groupId: number | null;
  }>({
    id: null,
    name: "",
    accountMappingTypeId: null,
    groupId: null,
  });

  const mappingData = useAppSelector((state) => state.mapping.mappingList);

  const companyBranches = useAppSelector(
    (state) => state.company.companyBranch
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const companyBusinessLine = useAppSelector(
    (state) => state.company.companyBusinessLine
  ).map((item) => ({ value: item.id.toString(), label: item.name }));

  const cashFlowReceive = useAppSelector(
    (state) => state.user.accountMappingCashFlows
  )
    .filter((item) => item.cashFlowDirection === CashFlowType.Receive)
    .map((item) => ({ value: item.id.toString(), label: item.name }));

  const cashFlowPaid = useAppSelector(
    (state) => state.user.accountMappingCashFlows
  )
    .filter((item) => item.cashFlowDirection === CashFlowType.Paid)
    .map((item) => ({ value: item.id.toString(), label: item.name }));

  const portalMappingOption = useAppSelector(
    (state) => state.user.rePortalMappings
  )
    .filter((item) => !item.isSum)
    .map((item) => ({ value: item.id.toString(), label: item.name }));

  const loadStatus = useAppSelector((state) => state.mapping.loading);

  const companyLoadStatus = useAppSelector((state) => state.company.loading);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const fetchMappingList = useCallback(() => {
    return dispatch(getMappingList());
  }, [dispatch]);

  const fetchData = useCallback(() => {
    const promises = [
      dispatch(getMappingList()),
      dispatch(getCompanyBusinessLine()),
      dispatch(getCompanyBranch()),
    ];

    Promise.all(promises);
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedCompany]);

  const mappingDataMemo = useMemo(() => {
    if (searchValue) {
      return {
        accountMappingTypeGroupTypes: filterDataByName(
          mappingData.accountMappingTypeGroupTypes,
          searchValue
        ),
      };
    }
    return mappingData;
  }, [mappingData, searchValue]);

  const submitEditItem = ({
    groupId,
    name,
    accountId,
    creditRePortalMappingId,
    debitRePortalMappingId,
    companyBranchId,
    companyBusinessLineId,
    accountMappingCashFlowCashInId,
    accountMappingCashFlowCashOutId,
  }: {
    groupId: number;
    name: string;
    accountId: string;
    creditRePortalMappingId: number | null;
    debitRePortalMappingId: number | null;
    companyBranchId: number | null;
    companyBusinessLineId: number | null;
    accountMappingCashFlowCashInId: number | null;
    accountMappingCashFlowCashOutId: number | null;
  }) => {
    dispatch(
      editMappingItem({
        accountMappingGroupId: groupId,
        name,
        accountId,
        id: mappingItemData?.id!,
        creditRePortalMappingId,
        debitRePortalMappingId,
        companyBranchId,
        companyBusinessLineId,
        accountMappingCashFlowCashInId,
        accountMappingCashFlowCashOutId,
      })
    ).then(({ error, payload }: ActionType) => {
      if (error?.message) {
        dispatch(showErrorMessage(error.message));
      }
      if (payload) {
        setMappingItemData(null);
        setGroupOptions([]);
        dispatch(showSuccessMessage(true));
        fetchMappingList();
      }
    });
  };

  const handleDeleteGroup = (
    name: string,
    groupId: number,
    accountMappingTypeId: number
  ) => {
    setShowConfirmation(true);
    setDeletedItemInfo({
      name: name,
      accountMappingTypeId,
      groupId,
    });
  };

  const handleEditGroup = (group: AccountMappingGroup) => {
    setGroupInformation({
      name: group.name,
      id: group.id,
    });
  };

  const handleDeleteMappingItem = (
    id: number,
    name: string,
    groupId: number,
    accountMappingTypeId: number
  ) => {
    setShowConfirmation(true);
    setDeletedItemInfo({
      id,
      name: name,
      accountMappingTypeId,
      groupId,
    });
  };

  const handleClose = () => {
    setDeletedItemInfo({
      id: null,
      name: "",
      accountMappingTypeId: null,
      groupId: null,
    });
    setShowConfirmation(false);
  };

  const handleEditItem = (
    mappingItem: MappingItemType,
    accountMappingType: AccountMappingType
  ) => {
    setMappingItemData(mappingItem);
    setGroupOptions(
      accountMappingType.accountMappingGroups.map((item) => ({
        value: item.id.toString(),
        label: item.name,
      }))
    );
  };

  const submitDelete = () => {
    setShowConfirmation(false);
    if (
      deletedItemInfo.id &&
      deletedItemInfo.accountMappingTypeId &&
      deletedItemInfo.groupId
    ) {
      dispatch(
        deleteMappingItem({
          accountMappingTypeId: deletedItemInfo.accountMappingTypeId,
          sectionId: deletedItemInfo.groupId,
          id: deletedItemInfo.id,
        })
      ).then(() => {
        setDeletedItemInfo({
          id: null,
          name: "",
          accountMappingTypeId: null,
          groupId: null,
        });
        dispatch(showSuccessMessage(true));
        fetchMappingList();
      });
    } else if (
      deletedItemInfo.accountMappingTypeId &&
      deletedItemInfo.groupId
    ) {
      dispatch(
        deleteMappingGroup({
          accountMappingTypeId: deletedItemInfo.accountMappingTypeId,
          groupId: deletedItemInfo.groupId,
        })
      ).then(() => {
        setDeletedItemInfo({
          id: null,
          name: "",
          accountMappingTypeId: null,
          groupId: null,
        });
        setShowConfirmation(false);
        dispatch(showSuccessMessage(true));
        fetchMappingList();
      });
    }
  };

  const handleManualMapping = () => {
    setOpenModal(false);
    setOpenAddMappingItemModalData({ accountId: "", name: "" });
  };

  const handleAutoMapping = () => {
    dispatch(accountsAutoMapping()).then(() => {
      fetchMappingList();
    });
  };

  const createGroup = () => {
    dispatch(
      createAccountMappingGroup({
        name: groupInformation.name!,
        accountMappingTypeId: groupInformation.typeId!,
      })
    ).then(() => {
      setGroupInformation((prev) => ({ ...prev, isCreated: true }));
      fetchMappingList();
    });
  };

  const editGroup = () => {
    dispatch(
      editMappingGroup({
        name: groupInformation.name!,
        id: groupInformation.id!,
      })
    ).then(() => {
      setGroupInformation((prev) => ({ ...prev, isCreated: true }));
      fetchMappingList();
    });
  };

  const handleUpdateGroupsOrdering = (
    groupsIds: Array<number>,
    accountMappingTypeId: number
  ) => {
    dispatch(changeGroupsOrder({ accountMappingTypeId, ids: groupsIds })).then(
      () => {
        dispatch(showSuccessMessage(true));
        fetchMappingList();
      }
    );
  };

  const updateGroupMappingItemOrdering = (
    ids: Array<number>,
    groupId: number
  ) => {
    dispatch(
      changeItemsOrder({
        ids,
        groupId,
      })
    ).then(() => {
      dispatch(showSuccessMessage(true));
      fetchMappingList();
    });
  };

  const addMappingItem = (data: MappingItemRequest) => {
    dispatch(createMappingItem(data));
  };

  const addMappingItemWithGroup = (data: MappingItemWithGroupRequest) => {
    dispatch(createAccountMappingWithGroup(data));
  };

  return (
    <Container>
      {loadStatus.list === "pending" ||
      loadStatus.group === "pending" ||
      loadStatus.ordering === "pending" ||
      loadStatus.item === "pending" ||
      companyLoadStatus === "pending" ? (
        <MappingLoading />
      ) : (
        <>
          <Title>{t("Mapping.Title")}</Title>
          {Boolean(mappingData?.unmapped?.length) && (
            <UnmappedGroup
              handleOpenAddMapping={() => {
                setOpenAddMappingItemModalData({
                  accountId: "",
                  name: "",
                });
              }}
              openDetailModal={() => setOpenModal(true)}
              itemNumber={mappingData.unmapped.length}
            />
          )}
          <Wrapper>
            <InputWrapper>
              <SearchInput
                inputSize="large"
                placeholder={t("Mapping.SearchInputContent")}
                value={searchValue}
                handleChange={(e) => setSearchValue(e.target.value)}
              />
            </InputWrapper>
            <MappingTypes
              openTypeId={openTypeId}
              openGroupId={openGroupId}
              mappingData={mappingDataMemo.accountMappingTypeGroupTypes}
              orderingDisable={Boolean(searchValue)}
              handleEditGroup={handleEditGroup}
              handleOpenEditItem={handleEditItem}
              handleOpenAddGroup={(typeId: number) => {
                setGroupInformation({ typeId: typeId });
              }}
              setOpenTypeId={setOpenTypeId}
              setOpenGroupId={setOpenGroupId}
              handleDeleteGroup={handleDeleteGroup}
              handleDeleteMappingItem={handleDeleteMappingItem}
              updateGroupsOrdering={handleUpdateGroupsOrdering}
              updateGroupMappingItemOrdering={updateGroupMappingItemOrdering}
            />
          </Wrapper>
        </>
      )}

      {showConfirmation && (
        <DeleteConfirmationModal
          name={deletedItemInfo.name}
          handleClose={handleClose}
          handleClick={submitDelete}
        />
      )}
      {Boolean(groupInformation.typeId || groupInformation.id) && (
        <AddGroupModal
          isEdit={Boolean(groupInformation.id)}
          loading={loadStatus.group === "pending"}
          isCreated={groupInformation.isCreated!}
          value={groupInformation.name!}
          setValue={(value) =>
            setGroupInformation((prev) => ({ ...prev, name: value }))
          }
          handleSubmit={() => {
            groupInformation.id ? editGroup() : createGroup();
          }}
          handleClose={() => {
            setGroupInformation({
              id: null,
              name: "",
              typeId: null,
              isCreated: false,
            });
          }}
        />
      )}
      {openModal && (
        <UnmappedModal
          loading={
            loadStatus.list === "pending" ||
            loadStatus.autoMapping === "pending"
          }
          unMappedData={mappingData.unmapped}
          handleOpenAddMapping={({ accountId, accountName }) => {
            setOpenModal(false);
            setOpenAddMappingItemModalData({ accountId, name: accountName });
          }}
          handleAutoMapping={handleAutoMapping}
          handleManualMapping={handleManualMapping}
          handleClose={() => setOpenModal(false)}
        />
      )}
      {mappingItemData && (
        <EditMappingItemModal
          loading={loadStatus.item === "pending"}
          initialValues={{
            groupId: mappingItemData?.accountMappingGroupId.toString(),
            name: mappingItemData?.name,
            accountId: mappingItemData?.accountId,
            creditRePortalMappingId:
              mappingItemData.creditRePortalMappingId?.toString() || null,
            debitRePortalMappingId:
              mappingItemData.debitRePortalMappingId?.toString() || null,
            companyBranchId:
              mappingItemData.companyBranchId?.toString() || null,
            companyBusinessLineId:
              mappingItemData.companyBusinessLineId?.toString() || null,
            accountMappingCashFlowCashInId:
              mappingItemData.accountMappingCashFlowCashInId?.toString() ||
              null,
            accountMappingCashFlowCashOutId:
              mappingItemData.accountMappingCashFlowCashOutId?.toString() ||
              null,
          }}
          portalMappingOptions={portalMappingOption}
          companyBranchesOptions={companyBranches}
          companyBusinessLineOptions={companyBusinessLine}
          cashFlowReceiveOptions={cashFlowReceive}
          cashFlowPaidOptions={cashFlowPaid}
          groupOptions={groupOptions}
          handleSubmit={submitEditItem}
          handleClose={() => setMappingItemData(null)}
        />
      )}

      {openAddMappingItemModalData && (
        <AddMappingItem
          fetchingStatus={loadStatus.list}
          createStatus={loadStatus.item}
          initialValue={openAddMappingItemModalData}
          unMappedItem={mappingData.unmapped}
          portalMappingOptions={portalMappingOption}
          companyBranchesOptions={companyBranches}
          companyBusinessLineOptions={companyBusinessLine}
          cashFlowReceiveOptions={cashFlowReceive}
          cashFlowPaidOptions={cashFlowPaid}
          accountMappingTypeGroupTypes={
            mappingData.accountMappingTypeGroupTypes
          }
          closeContent={() => {
            setOpenAddMappingItemModalData(null);
          }}
          updateMapping={(shouldClose?: boolean) => {
            fetchMappingList();
            if (shouldClose) {
              setOpenAddMappingItemModalData(null);
            }
          }}
          handleSubmit={addMappingItem}
          handleSubmitWithGroup={addMappingItemWithGroup}
        />
      )}
    </Container>
  );
};

export default Mapping;
