export const getRangeLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);
  return [fromDate, toDate];
};

export const getRangeCurrentMonth = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
  return [fromDate, toDate];
};

export const getRangeCurrentQuarter = () => {
  const toDate = new Date();
  const currentQuarter = Math.floor(toDate.getMonth() / 3);
  const fromDate = new Date(toDate.getFullYear(), currentQuarter * 3, 1);
  return [fromDate, toDate];
};

export const getRangeCurrentYear = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate.getFullYear(), 0, 1);
  return [fromDate, toDate];
};

export const getRangePreviousMonth = () => {
  const toDate = new Date();
  toDate.setMonth(toDate.getMonth() - 1);
  const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
  const toDateEnd = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
  return [fromDate, toDateEnd];
};

export const getRangePreviousQuarter = () => {
  const toDate = new Date();
  const currentQuarter = Math.floor(toDate.getMonth() / 3);
  const previousQuarter = currentQuarter - 1;
  const fromDate = new Date(toDate.getFullYear(), previousQuarter * 3, 1);
  const toDateEnd = new Date(toDate.getFullYear(), currentQuarter * 3, 0);
  return [fromDate, toDateEnd];
};

export const getRangePreviousYear = () => {
  const toDate = new Date();
  const fromDate = new Date(toDate.getFullYear() - 1, 0, 1);
  const toDateEnd = new Date(toDate.getFullYear() - 1, 11, 31);
  return [fromDate, toDateEnd];
};

export const getRangePreviousYearWithParameter = (year: number) => {
  const toDate = new Date();
  const fromDate = new Date(toDate.getFullYear() - year, 0, 1);
  const toDateEnd = new Date();
  return [fromDate, toDateEnd];
};
