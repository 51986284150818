import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Item, Wrapper } from "./ExpensesReports.style";
import PartyAndPeriod from "./partyAndPeriod/PartyAndPeriod";

const ExpensesReports = () => {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState<"StaffAndPeriod">("StaffAndPeriod");
  return (
    <>
      <Wrapper>
        <Item
          onClick={() => setOpenTab("StaffAndPeriod")}
          clicked={openTab === "StaffAndPeriod"}
        >
          {t("ReportExpenses.StaffAndPeriod")}
        </Item>
      </Wrapper>
      <Container>
        {openTab === "StaffAndPeriod" && <PartyAndPeriod />}
      </Container>
    </>
  );
};

export default ExpensesReports;
