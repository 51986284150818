import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const waveAnimation = keyframes`
 0% {
   background-position: -200px 0;
 }
 100% {
   background-position: calc(200px + 100%) 0;
 }
`;

const animatedBackground = ({ theme }: any) => css`
  background: linear-gradient(
    90deg,
    ${theme.colors.greyShade50} 25%,
    ${theme.colors.greyShade100} 50%,
    ${theme.colors.greyShade50} 75%
  );
  background-size: 200px 100%;
  animation: ${waveAnimation} 2s infinite;
`;

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: 32px 64px;
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyShade50};
  `
);

export const ItemWrapper = styled.div(
  ({ theme }) => css`
    padding-left: 24px;
    height: 48px;
    background: ${theme.colors.grey50};
  `
);

export const Item = styled.div(
  ({ theme }) => css`
    padding: 16px 0;
    height: 100%;
    border-bottom: 1px solid ${theme.colors.greyShade50};
  `
);

export const Line = styled.div<{ width: number }>(
  ({ theme, width }) => css`
    height: 100%;
    border-radius: 4px;
    width: ${width}px;
    ${animatedBackground({ theme })};
  `
);

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.div(
  ({ theme }) => css`
    min-width: 64px;
    height: 64px;
    border-radius: 50%;
    ${animatedBackground({ theme })};
  `
);

export const Title = styled.div(
  ({ theme }) => css`
    width: 194px;
    height: 16px;
    border-radius: 4px;
    margin-top: 16px;
    ${animatedBackground({ theme })};
  `
);

export const SubTitle = styled.div(
  ({ theme }) => css`
    width: 370px;
    height: 8px;
    border-radius: 2px;
    margin-top: 8px;
    ${animatedBackground({ theme })};
  `
);

export const SubTitleUnderLine = styled.div(
  ({ theme }) => css`
    width: 138px;
    height: 8px;
    border-radius: 2px;
    margin: 6px 0 24px;
    ${animatedBackground({ theme })};
  `
);
