// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    endpoints: {
      init: "Initial/Init/Init",
      auth: {
        login: "/Core/Authorization/Login",
        resetPassword: "/Core/Authorization/PasswordRecover",
        createPassword: "/Core/Authorization/SetPassword",
        chooseCompany: "/Core/Authorization/ChooseLoggedInCompany",
        refresh: "/Core/Authorization/Refresh",
      },
      company: {
        getCompany: "Core/Company/GetById",
        updateCompany: "Core/Company/Update",
        createCompany: "Core/Company/Create",
        getCompanyBranch: "/Core/CompanyBranch/GetList",
        createCompanyBranch: "/Core/CompanyBranch/Create",
        editCompanyBranch: "/Core/CompanyBranch/Update",
        deleteCompanyBranch: "/Core/CompanyBranch/Delete",
        updateBranchOrdering: "/Core/CompanyBranch/UpdateOrderings",
        getCompanyBusinessLine: "/Core/CompanyBusinessLine/GetList",
        createCompanyBusinessLine: "/Core/CompanyBusinessLine/Create",
        editCompanyBusinessLine: "/Core/CompanyBusinessLine/Update",
        deleteCompanyBusinessLine: "/Core/CompanyBusinessLine/Delete",
        updateBusinessLineOrdering: "/Core/CompanyBusinessLine/UpdateOrderings",
      },
      user: {
        create: "/Core/Client/Create",
        updateDefaultCompany: "/Core/Client/UpdateDefaultCompany",
        getList: "/Core/Client/GetList",
        clientUpdate: "/Core/ClientCompany/Update",
        checkEmail: "/Core/Client/CheckEmailExistence",
        userInfoUpdate: "/Core/Client/Update",
      },
      excelFile: {
        GenerateUploadUrl: "/Reporting/ExcelFile/GeneratePreSignedUrlForUpload",
        getList: "/Reporting/ExcelFile/GetList",
        getPreSignedUrl: "/Reporting/ExcelFile/GetPreSignedUrl",
      },
      faq: { getList: "/Shared/FAQ/GetList" },
      chart: {
        expenses: "/Chart/ExpensesChart/Get",
        profitLoss: "/Chart/ProfitLossChart/Get",
        revenue: "/Chart/RevenueChart/Get",
        cashFlow: "/Chart/CashFlowChart/Get",
      },
      reporting: {
        getList: "/Reporting/AccountMapping/GetList",
        autoMapping: "/Reporting/AccountMapping/MapUnmappedAccounts",
        createAccountMappingGroup:
          "/Reporting/AccountMapping/CreateAccountMappingGroup",
        createAccountMappingWithGroup:
          "/Reporting/AccountMapping/CreateAccountMappingWithGroup",
        createMappingItem: "/Reporting/AccountMapping/CreateAccountMapping",
        editMappingGroup: "/Reporting/AccountMapping/UpdateAccountMappingGroup",
        editMappingItem: "/Reporting/AccountMapping/UpdateAccountMapping",
        deleteMappingGroup:
          "/Reporting/AccountMapping/DeleteAccountMappingGroup",
        deleteMappingItem: "/Reporting/AccountMapping/DeleteAccountMapping",
        changeOrderingGroups:
          "/Reporting/AccountMapping/UpdateAccountMappingGroupOrdering",
        changeOrderingItems:
          "/Reporting/AccountMapping/UpdateAccountMappingOrdering",
        getReportalReport: "/Reporting/RePortalReport/GetReport",
        getRevenueServices:
          "/RevenueService/RevenueServiceCompanyEmployee/GetList",
        getRevenuePeriod:
          "/Reporting/Report/GetRevenueByClientsAndPeriodReport",
        getRevenueStream:
          "/Reporting/Report/GetRevenueByClientsAndStreamsReport",
        getWorkingOutComeDetailedReport:
          "/Reporting/Report/GetWorkingOutComeDetailedReport",
        getMarketCompanyInfo: "/Market/MarketCompany/GetInfo",
        getMarketAllCompanyInfo: "/Market/MarketCompany/GetAllCompanies",
        getFinanceStandingDetailedReport:
          "/Reporting/Report/getFinanceStandingDetailedReport",
        getRevenueByClientsAndPeriodReportInExcel:
          "/Reporting/Report/GetRevenueByClientsAndPeriodReportInExcel",
        getRevenueByClientsAndStreamsReportInExcel:
          "/Reporting/Report/GetRevenueByClientsAndStreamsReportInExcel",
        getWorkingOutComeDetailedReportInExcel:
          "/Reporting/Report/GetWorkingOutComeDetailedReportInExcel",
        getFinanceStandingDetailedReportInExcel:
          "/Reporting/Report/GetFinanceStandingDetailedReportInExcel",
        getExpenseByCounterPartyAndPeriod:
          "/Reporting/Report/GetExpenseByCounterPartyAndPeriod",
        getEmployeeRemunerationAndCostItemsReport:
          "/Reporting/Report/GetEmployeeRemunerationAndCostItemsReport",
        getEmployeeByStaffAndPeriod:
          "/Reporting/Report/GetEmployeeByStaffAndPeriod",
      },
    },
  },
};
