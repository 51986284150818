import { useTranslation } from "react-i18next";

import { Container, Item, Wrapper } from "./RevenueReports.style";

import Stream from "./stream/Stream";
import Period from "./period/Period";
import { useState } from "react";

const RevenueReports = () => {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState<"RevenuePeriod" | "RevenueStream">(
    "RevenuePeriod"
  );

  return (
    <>
      <Wrapper>
        <Item
          onClick={() => setOpenTab("RevenuePeriod")}
          clicked={openTab === "RevenuePeriod"}
        >
          {t("ReportRevenue.Client&Period")}
        </Item>
        <Item
          onClick={() => setOpenTab("RevenueStream")}
          clicked={openTab === "RevenueStream"}
        >
          {t("ReportRevenue.Client&Stream")}
        </Item>
      </Wrapper>
      <Container>
        {openTab === "RevenuePeriod" && <Period />}
        {openTab === "RevenueStream" && <Stream />}
      </Container>
    </>
  );
};

export default RevenueReports;
