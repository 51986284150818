import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface FAQAboutProps {
  clicked: boolean;
}
export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 150px;
    @media (max-width: ${theme.breakpoints.xl}) {
      gap: 75px;
    }
    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: ${theme.breakpoints.md}) {
      gap: 55px;
    }
  `
);

export const FAQAboutList = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
    white-space: nowrap;
    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: row;
      align-items: center;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      width: calc(100vw - 40px);
    }

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 15px;
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
      border-radius: 10px;
    }
  `
);

export const FAQMainSection = styled.div`
  flex: 4;
`;

export const FAQAboutItem = styled.p<FAQAboutProps>(
  ({ theme, clicked }) => css`
    ${theme.fonts.AvenirDemi}
    color: ${clicked && theme.colors.primary};
    display: inline-block;
    font-size: 16px;
    margin: 0;
    padding: 10px;
    cursor: pointer;
    flex: 1;
  `
);
