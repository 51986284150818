import React from "react";
import {
  TableRow,
  TableRowItem,
  GroupCard,
  GroupCardWrapper,
  GroupImage,
  GroupLine,
  DefaultContent,
  MediumContent,
  SmallContent,
} from "./TableSkeletonRow.style";

const TableSkeletonRow = () => {
  return (
    <tbody>
      <TableRow>
        <GroupCardWrapper>
          <GroupImage />
          <GroupCard>
            <GroupLine />
            <GroupLine />
          </GroupCard>
        </GroupCardWrapper>
        <TableRowItem>
          <DefaultContent />
        </TableRowItem>
        <TableRowItem>
          <DefaultContent />
        </TableRowItem>
        <TableRowItem>
          <MediumContent />
        </TableRowItem>
        <TableRowItem>
          <MediumContent />
        </TableRowItem>
        <TableRowItem>
          <SmallContent />
        </TableRowItem>
      </TableRow>
    </tbody>
  );
};

export default TableSkeletonRow;
