import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: 15px;
    background-color: ${theme.colors.white};
    overflow: hidden;
  `
);

export const Item = styled.div<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    font-size: 14px;
    padding: 20px 14px;
    border-bottom: 1px solid ${theme.colors.lightGrey};
    &:last-child {
      border-radius: 0;
    }
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.greyBorder};
      }
    `}
  `
);
