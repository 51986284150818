import React from "react";
import { isMobile } from "react-device-detect";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { chartColors } from "../../../../constants/color";
import { formatNumber } from "../../../../helpers/dashbordHelper";

interface GroupBarChartProps {
  chartData: { data: Array<number>; labels: Array<string> };
  stackId: string | undefined;
}

const renderCustomLabelForNotStack = (props: any) => {
  const { x, y, width, value } = props;

  const textX = x + width / 2; // Centering the text horizontally
  const textY = y - 10; // Centering the text vertically

  let formattedText = "0";
  if (value >= 1000000 || value <= -1000000) {
    formattedText = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000 || value <= -1000) {
    formattedText = (value / 1000).toFixed(0) + "K";
  } else {
    formattedText = value.toFixed(0);
  }

  if (width < 25) return null;

  return (
    <text
      x={textX}
      y={textY}
      fill="#000"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const renderCustomLabel = (props: any) => {
  const { x, y, width, height, value } = props;

  if (value === 0 || height < 16 || width < 20) {
    return null;
  }

  const textX = x + width / 2; // Centering the text horizontally
  const textY = y + height / 2 + 4; // Centering the text vertically

  let formattedText = "0";

  if (value >= 1000000 || value <= -1000000) {
    formattedText = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000 || value <= -1000) {
    formattedText = (value / 1000).toFixed(0) + "K";
  } else {
    formattedText = value.toFixed(0);
  }

  if (width < 25) return null;

  return (
    <text
      x={textX}
      y={textY}
      fill="#fff"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  let sum = 0;
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={
          isMobile
            ? {
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                padding: "10px",
                position: "absolute",
                left: 0,
                minWidth: "max-content",
              }
            : {
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                padding: "10px",
              }
        }
      >
        <p className="label">{`Period: ${label}`}</p>
        {payload.map((entry: any, index: number) => {
          if (!entry.value) return null;
          sum += +entry.value.toFixed(1);
          return (
            <p key={`tooltip-item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatNumber(+entry.value.toFixed(1))}`} GEL
            </p>
          );
        })}
        <p className="label" style={{ color: "green" }}>
          {`Total: ${formatNumber(+sum.toFixed(1))}`} GEL
        </p>
      </div>
    );
  }

  return null;
};

const renderSumLabel = (props: any) => {
  const { x, y, width, value } = props;

  const textX = x + width / 2; // Centering the text horizontally
  const textY = y - 10; // Centering the text vertically

  let formattedText = "0";
  if (value >= 1000000 || value <= -1000000) {
    formattedText = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000 || value <= -1000) {
    formattedText = (value / 1000).toFixed(0) + "K";
  } else {
    formattedText = value.toFixed(0);
  }

  if (width < 25) return null;

  if (formattedText === "0") {
    return null;
  }

  return (
    <text
      x={textX}
      y={textY}
      fill="#000"
      fontSize={14}
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {formattedText}
    </text>
  );
};

const formatYAxis = (tickItem: number) => {
  if (tickItem >= 1000000 || tickItem <= -1000000) {
    return `${tickItem / 1000000}M`;
  } else if (tickItem >= 10000 || tickItem <= -10000) {
    return `${tickItem / 1000}K`;
  } else {
    return tickItem.toFixed();
  }
};

const GroupBarChart = ({ chartData, stackId }: GroupBarChartProps) => {
  if (!chartData?.data) return null;

  return (
    <ResponsiveContainer width={"100%"} height={isMobile ? 300 : 460}>
      <BarChart data={chartData?.data}>
        <XAxis dataKey="label" reversed />
        <YAxis width={50} padding={{ top: 20 }} tickFormatter={formatYAxis} />

        <Tooltip wrapperStyle={{ zIndex: 1 }} content={<CustomTooltip />} />
        {chartData?.labels.map((key: string, index: number) => (
          <Bar
            key={key}
            dataKey={key}
            stackId={stackId}
            legendType="circle"
            fill={chartColors[index]}
          >
            {stackId && (
              <LabelList
                fill="#000"
                dataKey={key}
                content={renderCustomLabel}
              />
            )}
            {!Boolean(stackId) && (
              <LabelList
                fill="#000"
                dataKey={key}
                content={renderCustomLabelForNotStack}
              />
            )}
            {stackId && index === chartData.labels.length - 1 && (
              <LabelList fill="#000" content={renderSumLabel} />
            )}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GroupBarChart;
