import { DateRangePicker } from "rsuite";
import Button from "../../shared/button/Button";
import DropDown from "../../shared/dropdown/Dropdown";
import moment from "moment";

import {
  ButtonWrapper,
  Calendar,
  DropDownWrapper,
  Filter,
  Title,
  Wrapper,
} from "./ChartFilter.style";

import { ReactComponent as CalendarSvg } from "../../../assets/svgs/calendar.svg";
import { useState } from "react";
import { ChartGroupTypes } from "../../../enums/enum";
import { useTranslation } from "react-i18next";
import {
  Filter as FilterType,
  FrontSideFilter,
} from "../../../store/dashboard/dashboard.types";
import {
  getRangeCurrentMonth,
  getRangeCurrentQuarter,
  getRangeCurrentYear,
  getRangeLastDays,
  getRangePreviousYearWithParameter,
  getRangePreviousMonth,
  getRangePreviousQuarter,
  getRangePreviousYear,
} from "../../../helpers/dateHelper";
import MultiSelect from "../multiSelect/MultiSelect";

type FilterWithGroupType = FilterType & FrontSideFilter;

interface ChartFilterProps {
  options: Array<{ label: string; value: string }>;
  filterData: FilterType;
  frontSideFilter: FrontSideFilter;
  branchOptions?: Array<{ label: string; value: string }>;
  lineOptions?: Array<{ label: string; value: string }>;
  groupOption?: Array<{ label: string; value: string }>;
  hasGroupBy?: boolean;
  handleSubmit: ({
    periodType,
    fromDate,
    toDate,
    lines,
    branches,
    groupNames,
    group,
  }: FilterWithGroupType) => void;
}

const ChartFilter = ({
  options,
  filterData,
  frontSideFilter,
  branchOptions = [],
  lineOptions = [],
  groupOption = [],
  hasGroupBy = true,
  handleSubmit,
}: ChartFilterProps) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState(filterData.periodType);
  const [group, setGroup] = useState(frontSideFilter.group);
  const [dateRange, setDateRange] = useState({
    fromDate: filterData.fromDate,
    toDate: filterData.toDate,
  });
  const [lines, setLines] = useState<Array<{ value: string; label: string }>>(
    frontSideFilter.lines
  );
  const [branches, setBranches] = useState<
    Array<{ value: string; label: string }>
  >(frontSideFilter.branches);

  const [groupNames, setGroupNames] = useState<
    Array<{ value: string; label: string }>
  >(frontSideFilter.groupNames);

  return (
    <Wrapper>
      <Filter>
        <Title>{t("ChartFilter.Title")}</Title>
        {Boolean(groupOption?.length) && (
          <DropDownWrapper>
            <MultiSelect
              title={t("ChartFilter.Groups")}
              options={groupOption}
              selectedValue={groupNames}
              handleSelect={(value) => {
                setGroupNames(value);
              }}
            />
          </DropDownWrapper>
        )}
        {Boolean(branchOptions?.length) && (
          <DropDownWrapper>
            <MultiSelect
              title={t("ChartFilter.Branches")}
              options={branchOptions}
              selectedValue={branches}
              handleSelect={(value) => {
                setBranches(value);
              }}
            />
          </DropDownWrapper>
        )}
        {Boolean(lineOptions?.length) && (
          <DropDownWrapper>
            <MultiSelect
              title={t("ChartFilter.BusinessLine")}
              options={lineOptions}
              selectedValue={lines}
              handleSelect={(value) => {
                setLines(value);
              }}
            />
          </DropDownWrapper>
        )}
      </Filter>
      <Filter>
        <Calendar>
          <DateRangePicker
            caretAs={CalendarSvg}
            showHeader={false}
            character={"-"}
            defaultValue={[
              new Date(dateRange.fromDate),
              new Date(dateRange.toDate),
            ]}
            format="yyyy/MM/dd"
            placeholder="YYYY/MM/DD-YYYY/MM/DD"
            size="lg"
            ranges={[
              {
                label: t("ChartFilter.Last30Days"),
                value: getRangeLastDays(30) as any,
              },
              {
                label: t("ChartFilter.Last90Days"),
                value: getRangeLastDays(90),
              },
              {
                label: t("ChartFilter.Last120Days"),
                value: getRangeLastDays(120),
              },
              {
                label: t("ChartFilter.CurrentMonth"),
                value: getRangeCurrentMonth(),
              },
              {
                label: t("ChartFilter.CurrentQuarter"),
                value: getRangeCurrentQuarter(),
              },
              {
                label: t("ChartFilter.CurrentYear"),
                value: getRangeCurrentYear(),
              },
              {
                label: t("ChartFilter.PreviousMonth"),
                value: getRangePreviousMonth(),
              },
              {
                label: t("ChartFilter.PreviousQuarter"),
                value: getRangePreviousQuarter(),
              },
              {
                label: t("ChartFilter.PreviousYear"),
                value: getRangePreviousYear(),
              },
              {
                label: t("ChartFilter.TwoYear"),
                value: getRangePreviousYearWithParameter(1),
              },
              {
                label: t("ChartFilter.ThreeYear"),
                value: getRangePreviousYearWithParameter(2),
              },
              {
                label: t("ChartFilter.FourYear"),
                value: getRangePreviousYearWithParameter(3),
              },
            ]}
            onChange={(data) => {
              if (data) {
                const [fromDate, toDate] = data;
                try {
                  setDateRange({
                    fromDate: moment(fromDate).format(),
                    toDate: moment(toDate).format(),
                  });
                } catch (error) {}
              }
            }}
          />
        </Calendar>
        {hasGroupBy && group && (
          <DropDownWrapper>
            <DropDown
              title={t("ChartFilter.GroupedDropDown")}
              options={[
                {
                  label: "ChartFilter.Grouped",
                  value: ChartGroupTypes.Grouped.toString(),
                },
                {
                  label: "ChartFilter.Detailed",
                  value: ChartGroupTypes.Detailed.toString(),
                },
              ]}
              selectedValue={group?.toString()}
              handleSelect={(value) => {
                setGroup(+value as ChartGroupTypes);
              }}
            />
          </DropDownWrapper>
        )}

        <DropDownWrapper>
          <DropDown
            title={t("ChartFilter.DateDropDown")}
            options={options}
            selectedValue={period.toString()}
            handleSelect={(value) => {
              setPeriod(+value);
            }}
          />
        </DropDownWrapper>

        <ButtonWrapper>
          <Button
            title={t("ChartFilter.ButtonTitle")}
            type="button"
            onClick={() => {
              handleSubmit({
                group: group ? +group : 0,
                lines,
                groupNames,
                branches,
                periodType: +period,
                ...dateRange,
              });
            }}
          />
        </ButtonWrapper>
      </Filter>
    </Wrapper>
  );
};

export default ChartFilter;
