import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h2(
  ({ theme }) => css`
    font-size: 16px;
    ${theme.fonts.AvenirDemi};
  `
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 40px 0 35px;
    @media (max-width: ${theme.breakpoints.sm}) {
      gap: 10px;
      margin: 20px 0 15px;
    }
  `
);

export const InteractionArea = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-bottom: 20px;
    }
  `
);

export const AlertWrapper = styled.div(
  ({ theme }) => css`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 10px;
    }
  `
);
