import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Item = styled.div<{ isSelected: boolean }>(
  ({ theme, isSelected }) => css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border: 1px solid
      ${isSelected ? theme.colors.primary600 : theme.colors.greyShade200};
    color: ${isSelected ? theme.colors.primary600 : theme.colors.textPrimary};
    border-radius: 8px;
    font-size: 14px;
    padding: 16px;
    line-height: normal;
    &:hover {
      ${!isSelected &&
      css`
        border: 1px solid ${theme.colors.greyShade300};
        background: ${theme.colors.greyShade50};
      `}
    }
  `
);
