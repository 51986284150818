import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CustomInput,
  ErrorIcon,
  ErrorMassage,
  Label,
} from "./Input.style";
import { FieldInputProps, FormikErrors, FormikTouched } from "formik";

interface FormikPart<T> {
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
}

interface CustomInputProps extends FieldInputProps<string> {
  form: FormikPart<string>;
  [key: string]: any;
  label: string;
}

const Input = ({
  field,
  form: { touched, errors },
  label,
  ...props
}: CustomInputProps) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <Box
      onBlur={() => {
        handleBlur();
      }}
      onFocus={() => {
        handleFocus();
      }}
    >
      <Label
        invalid={Boolean(touched[field.name] && errors[field.name])}
        htmlFor={field.name}
        isFocus={focused}
        hasValue={field.value}
      >
        {label}
      </Label>
      <CustomInput
        {...field}
        {...props}
        id={field.name}
        hasValue={field.value}
        isFocused={focused}
        invalid={touched[field.name] && errors[field.name]}
      />
      {touched[field.name] && errors[field.name] ? (
        <ErrorMassage>
          <ErrorIcon /> {t(errors[field.name])}
        </ErrorMassage>
      ) : null}
    </Box>
  );
};

export default Input;
