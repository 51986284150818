import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0;
      align-content: center;
    }
  `
);

export const TableRowItem = styled.td`
  vertical-align: middle;
`;

export const TimeItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
  `
);

export const UserImage = styled.img`
  width: 35px;
  height: 35px;
`;

export const UserName = styled.span(
  ({ theme }) => css`
    font-size: 14px;
    display: block;

    ${theme.fonts.AvenirMedium};
  `
);

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FileName = styled.span(
  ({ theme }) => css`
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
    display: block;
  `
);
export const FileImage = styled.img`
  width: 35px;
  height: 35px;
`;

export const UserEmail = styled.span(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirDemi};
    color: ${theme.colors.grey};
    display: block;
  `
);

export const StatusItemWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

type StatusItemProps = {
  status: "Pending" | "Uploaded" | "Declined" | "Partially uploaded";
};

export const StatusItem = styled.span<StatusItemProps>(
  ({ theme, status }) => css`
    display: block;
    padding: 4px 7px;
    width: fit-content;
    border-radius: 15px;
    font-size: 12px;
    ${theme.fonts.AvenirMedium};
    color: ${status === "Pending" && theme.colors.yellow};
    color: ${status === "Uploaded" && theme.colors.green};
    color: ${status === "Declined" && theme.colors.dangerMain};
    color: ${status === "Partially uploaded" && theme.colors.darkBlue2};

    background-color: ${status === "Pending" && theme.colors.yellowBackground};
    background-color: ${status === "Uploaded" && theme.colors.greenBackground};
    background-color: ${status === "Declined" &&
    theme.colors.dangerBackgroundLight};
    background-color: ${status === "Partially uploaded" &&
    theme.colors.blueBackground};
  `
);
export const CategoryItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
  `
);

export const ButtonItem = styled.td`
  vertical-align: middle;
  text-align: right;
  width: 115px;
`;

export const YearWrapper = styled.td`
  vertical-align: middle;
  min-width: 75px;
`;

export const Loading = styled.span`
  font-size: 14px;
`;
