import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const LoaderWrapper = styled.div`
  height: calc(100vh - 250px);
`;
export const CustomTable = styled.table`
  width: 100%;
  position: relative;
`;
export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0;
      min-width: 100px;
    }
  `
);
export const TableRowItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
  `
);
export const TableRowItemId = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    min-width: 130px !important;
  `
);

export const TableRowItemName = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    min-width: 400px !important;
  `
);
export const NoResult = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    margin: auto;
    margin-top: 50px;
    font-size: 30px;
    ${theme.fonts.HelveticaNeue}
  `
);
