import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  padding: 30px 0;
  width: 100%;
`;

export const TabsWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    max-width: 675px;
    margin-bottom: 70px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0 auto 50px;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 0 auto 30px;
    }
  `
);
