import { css } from "@emotion/react";
import styled from "@emotion/styled";

// TODO colors
export const Wrapper = styled.div`
  background-color: #f6f6f6;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

// TODO color
export const ItemWrapper = styled.div(
  ({ theme }) => css`
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 20px 32px 20px 48px;
    border-bottom: 1px solid #e5e5e5;
    &:hover {
      background-color: #efefef;
    }
  `
);

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// TODO color
export const ActionIconWrapper = styled.div(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5e5e5;
    border-radius: 50%;
    &:hover {
      background: #d6d6d6;
    }
  `
);
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ItemId = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
`;

// TODO fontWeight do not work and color
export const Title = styled.h5(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
  `
);

export const ItemTree = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-left: 10px;
`;
export const ItemTreeElement = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 11px;
    line-height: 14px;
    position: relative;
  `
);
export const ItemTreeIconWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 6px;
`;

export const ItemTreeElementWrapper = styled.div`
  position: relative;
  padding-left: 22px;
  &:first-of-type div div {
    height: 14px;
  }
`;
export const ItemTreeSubElement = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    font-size: 11px;
    line-height: 14px;
  `
);

export const ItemTreeElementContentWrapper = styled.div`
  display: flex;
  gap: 2px;
`;

export const DottedArc = styled.div(
  ({ theme }) => css`
    width: 16px;
    height: 40px;
    border-bottom-left-radius: 10px;
    border-left: 1px dashed ${theme.colors.greyShade300};
    border-bottom: 1px dashed ${theme.colors.greyShade300};
  `
);
