import React from "react";
import {
  BottomSheetType,
  ChartGroupTypes,
  PeriodType,
  RangeType,
} from "../../../enums/enum";
import { formatDate, getTimePeriodText } from "../../../helpers/helper";
import { Filter } from "../../../store/dashboard/dashboard.types";

import {
  BottomWrapper,
  Item,
  LightContent,
  RightArrow,
  RightSide,
  Wrapper,
} from "./ChartFilterMobile.style";
import Button from "../../shared/button/Button";
import { useTranslation } from "react-i18next";

interface ChartFilterMobileProps {
  filter: Filter;
  isChanged: {
    periodType: boolean;
    date: boolean;
    group: boolean;
    line: boolean;
    branch: boolean;
    groupName: boolean;
  };
  companyBusinessLine: Array<{ value: string; label: string }>;
  companyBranches: Array<{ value: string; label: string }>;
  filterRangeType: RangeType;
  filterLines: Array<{ value: string; label: string }>;
  filterBranches: Array<{ value: string; label: string }>;
  filterGroupNames: Array<{ value: string; label: string }>;
  showViewFilter: boolean;
  showBranchFilter: boolean;
  showBusinessLineFilter: boolean;
  chartGroupeType?: ChartGroupTypes;
  handleSubmit: () => void;
  handleClick: (type: BottomSheetType) => void;
}
const ChartFilterMobile = ({
  filter,
  companyBusinessLine,
  companyBranches,
  isChanged,
  chartGroupeType,
  filterRangeType,
  filterLines,
  filterBranches,
  filterGroupNames,
  showViewFilter,
  showBranchFilter,
  showBusinessLineFilter,
  handleSubmit,
  handleClick,
}: ChartFilterMobileProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        {Boolean(showBranchFilter) && (
          <Item
            onClick={() => {
              handleClick(BottomSheetType.BUSINESS_LINE);
            }}
          >
            {t("ChartFilterMobile.BusinessLine")}
            <RightSide>
              <LightContent>
                {filterLines.length || t("ChartFilterMobile.All")}
              </LightContent>
              <RightArrow />
            </RightSide>
          </Item>
        )}
        {Boolean(showBusinessLineFilter) && (
          <Item
            onClick={() => {
              handleClick(BottomSheetType.BRANCH);
            }}
          >
            {t("ChartFilterMobile.Branch")}
            <RightSide>
              <LightContent>
                {filterBranches.length || t("ChartFilterMobile.All")}
              </LightContent>
              <RightArrow />
            </RightSide>
          </Item>
        )}
        <Item
          onClick={() => {
            handleClick(BottomSheetType.DATE_RANGE);
          }}
        >
          {t("ChartFilterMobile.DateRange")}
          <RightSide>
            <LightContent>
              {Boolean(filterRangeType) && (
                <>{t(`ChartFilter.${getTimePeriodText(filterRangeType)}`)}</>
              )}
              {!Boolean(filterRangeType) && (
                <>
                  {formatDate(filter.fromDate.toString())}-
                  {formatDate(filter.toDate.toString())}
                </>
              )}
            </LightContent>
            <RightArrow />
          </RightSide>
        </Item>
        {showViewFilter && chartGroupeType && (
          <Item
            onClick={() => {
              handleClick(BottomSheetType.CHART_VIEW_TYPE);
            }}
          >
            {t("ChartFilterMobile.ChartViewType")}
            <RightSide>
              <LightContent>
                {t(`ChartFilter.${ChartGroupTypes[chartGroupeType]}`)}
              </LightContent>
              <RightArrow />
            </RightSide>
          </Item>
        )}
        <Item
          onClick={() => {
            handleClick(BottomSheetType.DATE_TYPE);
          }}
        >
          {t("ChartFilterMobile.DateType")}
          <RightSide>
            <LightContent>
              {t(`PeriodTypes.${PeriodType[filter.periodType]}`)}
            </LightContent>
            <RightArrow />
          </RightSide>
        </Item>
        <Item
          onClick={() => {
            handleClick(BottomSheetType.GROUP_NAME);
          }}
        >
          {t("ChartFilterMobile.GroupName")}
          <RightSide>
            <LightContent>
              {filterGroupNames.length || t("ChartFilterMobile.All")}
            </LightContent>
            <RightArrow />
          </RightSide>
        </Item>
      </Wrapper>
      <BottomWrapper>
        <Button
          isDisabled={
            !(
              isChanged.date ||
              isChanged.group ||
              isChanged.periodType ||
              isChanged.line ||
              isChanged.branch ||
              isChanged.groupName
            )
          }
          title={t("ChartFilterMobile.Apply")}
          onClick={() => {
            handleSubmit();
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default ChartFilterMobile;
