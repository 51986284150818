import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { PeriodType } from "../../enums/enum";
import axiosInstance from "../../serveces/interceptor";
import config from "../../utils/config";
import {
  GenerateUploadUrlRequest,
  getFileResponse,
  getListResponse,
  ReportFileRequest,
  ReportRequest,
} from "./report.types";

export const GET_LIST = "GET_LIST";
export const GET_FILE_URL = "GET_FILE_URL";
export const UPLOAD_FILE = "UPLOAD_FILE";

export const CLIENT_PERIOD_FILE = "CLIENT_PERIOD_FILE";
export const CLIENT_STREAM_FILE = "CLIENT_STREAM_FILE";
export const OUTCOME_DETAIL_FILE = "OUTCOME_DETAIL_FILE";
export const FINANCE_STANDING_FILE = "FINANCE_STANDING_FILE";
export const GET_EXPENSES_BY_COUNTER_PARTY_AND_PERIOD =
  "GET_EXPENSES_BY_COUNTER_PARTY_AND_PERIOD";
export const GET_EMPLOYEE_REMUNERATION_AND_COST_ITEMS_REPORT =
  "GET_EMPLOYEE_REMUNERATION_AND_COST_ITEMS_REPORT";

export const GET_EMPLOYEE_BY_STAFF_AND_PERIOD =
  "GET_EMPLOYEE_BY_STAFF_AND_PERIOD";

export const GET_REVENUE_PERIOD = "GET_REVENUE_PERIOD";
export const GET_REVENUE_STREAM = "GET_REVENUE_STREAM";
export const GET_WORKING_OUT_COME_DETAILED_REPORT =
  "GET_WORKING_OUT_COME_DETAILED_REPORT";
export const GET_FINANCE_STANDING_DETAILED_REPORT =
  "GET_FINANCE_STANDING_DETAILED_REPORT";

export const getList = createAsyncThunk(
  GET_LIST,
  async ({
    ClientId,
    CompanyId,
    Page,
    ExcelFileType,
    PageSize,
  }: ReportRequest) => {
    const response: AxiosResponse<getListResponse> = await axiosInstance.get(
      config.api.endpoints.excelFile.getList,
      { params: { ClientId, CompanyId, Page, PageSize, ExcelFileType } }
    );
    return response.data;
  }
);

export const getFileUrl = createAsyncThunk(
  GET_FILE_URL,
  async ({ id }: ReportFileRequest) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.get(
      config.api.endpoints.excelFile.getPreSignedUrl,
      { params: { id } }
    );
    return response.data;
  }
);

export const GenerateUploadUrl = createAsyncThunk(
  UPLOAD_FILE,
  async (data: GenerateUploadUrlRequest) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.post(
      config.api.endpoints.excelFile.GenerateUploadUrl,
      data
    );
    return response.data;
  }
);

export const getClientPeriodFileUrl = createAsyncThunk(
  CLIENT_PERIOD_FILE,
  async (data: {
    fromDate: string;
    toDate: string;
    PeriodType: PeriodType;
  }) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.get(
      config.api.endpoints.reporting.getRevenueByClientsAndPeriodReportInExcel,
      { params: data }
    );
    return response.data;
  }
);

export const getClientStreamFileUrl = createAsyncThunk(
  CLIENT_STREAM_FILE,
  async (data: { fromDate: string; toDate: string }) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.get(
      config.api.endpoints.reporting.getRevenueByClientsAndStreamsReportInExcel,
      { params: data }
    );
    return response.data;
  }
);

export const getOutcomeDetailFileUrl = createAsyncThunk(
  OUTCOME_DETAIL_FILE,
  async ({ year }: { year: number }) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.get(
      config.api.endpoints.reporting.getWorkingOutComeDetailedReportInExcel,
      { params: { year } }
    );
    return response.data;
  }
);

export const getFinanceStandingFileUrl = createAsyncThunk(
  FINANCE_STANDING_FILE,
  async ({ year }: { year: number }) => {
    const response: AxiosResponse<getFileResponse> = await axiosInstance.get(
      config.api.endpoints.reporting.getFinanceStandingDetailedReportInExcel,
      { params: { year } }
    );
    return response.data;
  }
);

export const getExpenseByCounterPartyAndPeriod = createAsyncThunk(
  GET_EXPENSES_BY_COUNTER_PARTY_AND_PERIOD,
  async (data: {
    fromDate: string;
    toDate: string;
    PeriodType: PeriodType;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getExpenseByCounterPartyAndPeriod,
      { params: data }
    );
    return {
      data: response.data,
      fromDate: data.fromDate,
      toDate: data.toDate,
      PeriodType,
    };
  }
);
export const getEmployeeByStaffAndPeriod = createAsyncThunk(
  GET_EMPLOYEE_BY_STAFF_AND_PERIOD,
  async (data: {
    fromDate: string;
    toDate: string;
    PeriodType: PeriodType;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getEmployeeByStaffAndPeriod,
      { params: data }
    );
    return {
      data: response.data,
      fromDate: data.fromDate,
      toDate: data.toDate,
      PeriodType,
    };
  }
);
export const getEmployeeRemunerationAndCostItemsReport = createAsyncThunk(
  GET_EMPLOYEE_REMUNERATION_AND_COST_ITEMS_REPORT,
  async (data: { fromDate: string; toDate: string }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getEmployeeRemunerationAndCostItemsReport,
      { params: data }
    );
    return response.data;
  }
);

export const getRevenuePeriod = createAsyncThunk(
  GET_REVENUE_PERIOD,
  async ({
    fromDate,
    toDate,
    PeriodType,
  }: {
    fromDate: string;
    toDate: string;
    PeriodType: PeriodType;
  }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getRevenuePeriod,
      {
        params: {
          fromDate,
          toDate,
          PeriodType,
        },
      }
    );
    return { data: response.data, fromDate, toDate, PeriodType };
  }
);

export const getRevenueStream = createAsyncThunk(
  GET_REVENUE_STREAM,
  async ({ fromDate, toDate }: { fromDate: string; toDate: string }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getRevenueStream,
      {
        params: {
          fromDate,
          toDate,
        },
      }
    );

    return {
      data: response.data,
      fromDate,
      toDate,
    };
  }
);

export const getWorkingOutComeDetailedReport = createAsyncThunk(
  GET_WORKING_OUT_COME_DETAILED_REPORT,
  async ({ year }: { year: number }) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getWorkingOutComeDetailedReport,
      {
        params: { year },
      }
    );
    return response.data;
  }
);

export const getFinanceStandingDetailedReport = createAsyncThunk(
  GET_FINANCE_STANDING_DETAILED_REPORT,
  async (year: number) => {
    const response: AxiosResponse = await axiosInstance.get(
      config.api.endpoints.reporting.getFinanceStandingDetailedReport,
      { params: { year } }
    );
    return response.data;
  }
);
