import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface LogOutProps {
  isLogOut: boolean;
}
export const Wrapper = styled.div<LogOutProps>(
  ({ theme, isLogOut }) => css`
    padding: ${isLogOut ? "26px 24px" : "14px 24px"};
    display: flex;
    align-items: center;
    gap: 8px;
    ${isLogOut && `border-top: 1px solid ${theme.colors.bg002};`}
    &:active {
      background-color: ${theme.colors.grey100};
    }
  `
);

// TODO todo color red color
export const Title = styled.p<LogOutProps>(
  ({ theme, isLogOut }) => css`
    ${theme.fonts.HelveticaLight}
    margin: 0;
    color: ${isLogOut ? "#EC1818" : theme.colors.black005};
    font-size: 13px;
  `
);
