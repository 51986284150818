import React from "react";
import { Title, Wrapper } from "./FilerButtonMobile.style";
import Icon from "../../shared/icon/Icon";

interface ChartFilterMobileProps {
  handleClick: () => void;
}

const ChartFilterButtonMobile = ({ handleClick }: ChartFilterMobileProps) => {
  return (
    <Wrapper onClick={handleClick}>
      <Icon iconName="mobileFilter" height={17} width={17} />
      <Title>Detailed Filters</Title>
    </Wrapper>
  );
};

export default ChartFilterButtonMobile;
