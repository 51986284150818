import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${theme.colors.lightGrey};
    border-radius: 15px;
    margin-bottom: 20px;
  `
);

export const Title = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirLight}
    font-size: 14px;
  `
);
