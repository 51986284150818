import React, { MouseEvent } from "react";
import { CustomButton } from "./Button.style";
import { ReactComponent as LoadingSvg } from "../../../assets/svgs/loadingSvg.svg";
import { isMobile } from "react-device-detect";

export type ButtonType = "filled" | "outlined";
export type ButtonSize = "small" | "large";

interface ButtonProps {
  title: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: "button" | "submit" | "reset";
  size?: ButtonSize;
  buttonType?: ButtonType;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
  title,
  isLoading = false,
  isDisabled = false,
  type = "button",
  size = "large",
  buttonType = "filled",
  onClick,
}) => (
  <CustomButton
    isMobile={isMobile}
    type={type}
    size={size}
    disabled={isDisabled}
    onClick={onClick}
    isLoading={isLoading}
    buttonType={buttonType}
  >
    {isLoading ? (
      <>
        Loading...
        <LoadingSvg className="loading-svg" />
      </>
    ) : (
      title
    )}
  </CustomButton>
);

export default Button;
