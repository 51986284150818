import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) =>
    css`
      width: 100%;
      padding: 45px 0 30px;

      @media (max-width: ${theme.breakpoints.md}) {
        padding: 20px 0;
      }
    `
);

export const DropDownItem = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    width: 250px;
    /* because after 676px it has bad view */
    @media (max-width: 576px) {
      ${isMobile &&
      css`
        width: 100vw;
        padding: 0 20px;
      `}
    }
  `
);

export const Label = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
  `
);
export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    width: 150px;
    grid-column-start: 2;
    grid-row-start: 2;
    justify-self: end;
    @media (max-width: ${theme.breakpoints.sm}) {
      grid-row-start: 1;
    }
  `
);

export const InputField = styled.div(
  ({ theme }) => css`
    grid-column-start: 1;
    grid-row-start: 2;
    @media (max-width: ${theme.breakpoints.sm}) {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  `
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 300px 1fr;
    width: 100%;
    gap: 20px;

    @media (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr 1fr;
    }
  `
);

export const FilterSection = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 40px;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      gap: 20px;
    }
  `
);

export const Results = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 12px;
    color: ${theme.colors.grey};
  `
);
export const DropDownsWrapper = styled.div<{ isMobile: boolean }>(
  ({ isMobile }) => css`
    display: flex;
    gap: 30px;

    ${isMobile &&
    css`
      flex-direction: column;
      gap: 20px;
    `}
  `
);
