import React, { MouseEvent } from "react";
import Icon from "../../shared/icon/Icon";
import { Label, Wrapper } from "./RoundButton.style";

interface IProps {
  label: string;
  onClick: (e: MouseEvent) => void;
}
const RoundButton = ({ label, onClick }: IProps) => {
  return (
    <Wrapper onClick={onClick}>
      <Icon iconName="plus_v2" height={16} width={16} fill="white" />
      <Label> {label}</Label>
    </Wrapper>
  );
};

export default RoundButton;
