import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 660px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 15px;
    @media (max-width: ${theme.breakpoints.xl}) {
      max-width: 660px;
      width: 90%;
      gap: 50px;
    }
  `
);

export const InputWrapper = styled.div`
  width: 100%;
`;
export const InputRow = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: 20px;
    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `
);

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
