import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    padding: 32px 64px 0 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `
);

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
//
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;
export const Title = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  `
);
export const SubTitle = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.grey700};
    font-size: 12px;
    line-height: 18px;
  `
);
export const IconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 64px;
    height: 64px;
    background-color: ${theme.colors.negative600};
  `
);

export const UnMappedSection = styled.div(
  ({ theme }) => css`
    border-radius: 12px;
    border: 1px solid ${theme.colors.greyShade50};
    overflow: auto;
    max-height: 500px;
  `
);

export const ItemWrapper = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    background-color: ${theme.colors.grey50};
    padding-left: 24px;
    height: 48px;
    &:hover {
      background-color: ${theme.colors.grey100};
    }
  `
);
export const Item = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.greyShade50};
    height: inherit;
    padding: 16px 24px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > *:not(last-child) {
      border-bottom: none;
    }
  `
);
export const ItemName = styled.span(
  ({ theme }) => css`
    ${theme.fonts.HelveticaLight}
    color: ${theme.colors.textPrimary};
    font-size: 13px;
    line-height: normal;
    max-width: 310px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
);
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
