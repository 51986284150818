import React from "react";
import { ButtonWrapper, Description, Note, Title } from "./OpenEmailForm.style";
import { useTranslation } from "react-i18next";
import TextButton from "../../../shared/textButton/TextButton";

interface IOpenEmailProps {
  handleNavigate: () => void;
}
const OpenEmailForm = ({ handleNavigate }: IOpenEmailProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t("OpenEmail.Title")}</Title>
      <Description>{t("OpenEmail.Description")}</Description>
      <Note>{t("OpenEmail.Note")}</Note>
      <ButtonWrapper>
        <TextButton
          title={t("OpenEmail.TextButtonContent")}
          isDisable={false}
          handleClick={handleNavigate}
        />
      </ButtonWrapper>
    </>
  );
};

export default OpenEmailForm;
