import { css } from "@emotion/react";

export const fontWeights = {
  extraBold: 800,
  bold: 700,
  semiBold: 600,
  medium: 500,
  normal: 400,
  light: 300,
};

export const FontFamily = {
  AvenirBold: "Avenir-Bold",
  AvenirDemi: "Avenir-Demi",
  AvenirMedium: "Avenir-Medium",
  AvenirLight: "Avenir-Light",
  HelveticaNeue: "Helvetica-Neue",
  HelveticaLight: "Helvetica-light",
};

export const AvenirBold = css`
  font-family: ${FontFamily.AvenirBold};
  font-weight: ${fontWeights.medium};
`;

export const AvenirDemi = css`
  font-family: ${FontFamily.AvenirDemi};
  font-weight: ${fontWeights.bold};
`;

export const AvenirMedium = css`
  font-family: ${FontFamily.AvenirMedium};
  font-weight: ${fontWeights.medium};
`;

export const AvenirLight = css`
  font-family: ${FontFamily.AvenirLight};
  font-weight: ${fontWeights.light};
`;

export const HelveticaNeue = css`
  font-family: ${FontFamily.HelveticaNeue};
  font-weight: ${fontWeights.medium};
`;

export const HelveticaLight = css`
  font-family: ${FontFamily.HelveticaNeue};
  font-weight: ${fontWeights.normal};
`;

export const fonts = {
  AvenirBold,
  AvenirDemi,
  AvenirMedium,
  AvenirLight,
  HelveticaNeue,
  HelveticaLight,
};
