import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    background-color: ${theme.colors.primary600};
    color: ${theme.colors.white};
    border-radius: 100px;
    &:hover {
      background-color: ${theme.colors.primary800};
    }
  `
);

export const Label = styled.span`
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
`;
