import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../../components/desktop/forms/loginForm/LoginForm";
import { AuthURL } from "../../../constants/router";

import { useAppDispatch, useAppSelector } from "../../../store";
import { ActionType } from "../../../store/type";
import { LoginRequest } from "../../../store/user/user.types";
import { login } from "../../../store/user/userAction";

import { Wrapper } from "./Login.style";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.user.loading === "pending");

  const handleSubmit = (values: LoginRequest) => {
    setErrorMessage("");
    dispatch(login(values)).then(({ payload, error }: ActionType) => {
      if (payload?.hasToChangePassword) {
        navigate(`${AuthURL.ResetPassword.path}?token=${payload.token}`);
      }
      if (error?.message) {
        setErrorMessage(error.message);
      }
    });
  };

  const handleNavigate = () => {
    navigate(AuthURL.ForgotPassword.path);
  };

  return (
    <Wrapper>
      <LoginForm
        handleNavigate={handleNavigate}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        errorMessage={errorMessage}
      />
    </Wrapper>
  );
};
export default Login;
