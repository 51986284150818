import React, { useState } from "react";
import Icon, { IconNameOptions } from "../../../shared/icon/Icon";
import { HoverItem, Title, Wrapper } from "./SideBarNavigationItem.style";
import { isMobile } from "react-device-detect";

interface SideBarNavigationItemProps {
  pageName: string;
  iconName: IconNameOptions;
  isSelected: boolean;
  active: boolean;
  handleNavigate: () => void;
}
const SideBarNavigationItem = ({
  pageName,
  iconName,
  isSelected,
  active,
  handleNavigate,
}: SideBarNavigationItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Wrapper
      isMobile={isMobile}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleNavigate}
      isSelected={isSelected}
    >
      <Icon fill={isSelected ? "primary" : "default"} iconName={iconName} />
      <Title isMobile={isMobile} isSelected={isSelected} active={active}>
        {pageName}
      </Title>
      {isHovered && !active && <HoverItem>{pageName}</HoverItem>}
    </Wrapper>
  );
};

export default SideBarNavigationItem;
