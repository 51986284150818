import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface ListItemProps {
  clicked: boolean;
}
export const Item = styled.div<ListItemProps>(
  ({ theme, clicked }) => css`
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.primary};
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid ${clicked ? theme.colors.primary : "none"};
    cursor: pointer;
  `
);
export const Wrapper = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.greyBorder};
    display: flex;
  `
);

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px;
`;
