import moment from "moment";
import { PeriodType } from "../enums/enum";
import {
  CashFlow,
  CashFlowCategoryType,
  CashFlowDirectionType,
  ExpensesData,
  ProfitLostData,
  RevenueData,
} from "../store/dashboard/dashboard.types";
import { formatDateByPeriod } from "./helper";

export const generateDataForCheckboxSelect = (data: Array<RevenueData>) => {
  const groupMap: {
    [key: string]: {
      label: string;
      value: string;
      children: Array<{ label: string; value: string }>;
    };
  } = {};

  data.forEach((item) => {
    const { mappingGroupName, accountName } = item;

    if (!groupMap[mappingGroupName]) {
      groupMap[mappingGroupName] = {
        value: mappingGroupName,
        label: mappingGroupName,
        children: [],
      };
    }

    // Check if the accountName already exists in the children array
    if (
      !groupMap[mappingGroupName].children.some(
        (child: any) => child.value === accountName
      )
    ) {
      groupMap[mappingGroupName].children.push({
        value: accountName,
        label: accountName,
      });
    }
  });

  return Object.values(groupMap);
};

export const getAllAccountNames = (
  data: Array<RevenueData | ExpensesData>
): Array<string> => {
  // Create a map to store the account names with their corresponding group ordering and ordering
  const accountMap = new Map<string, { groupOrder: number; order: number }>();
  data
    .sort((a, b) => a.accountMappingTypeOrdering - b.accountMappingTypeOrdering)
    .forEach((entry) => {
      if (entry.sumAmount !== 0) {
        const name = entry.accountName + ` (${entry.accountId})`;

        if (!accountMap.has(name)) {
          accountMap.set(name, {
            groupOrder: entry.accountMappingGroupOrdering,
            order: entry.accountMappingOrdering,
          });
        }
      }
    });

  // Convert the map to an array and sort it by the group ordering first, then by the ordering value
  const sortedAccountNames = Array.from(accountMap.entries())
    .sort((a, b) => {
      if (a[1].groupOrder === b[1].groupOrder) {
        return a[1].order - b[1].order;
      }
      return a[1].groupOrder - b[1].groupOrder;
    })
    .map(([accountName]) => accountName);

  return sortedAccountNames;
};

export interface GroupedChartDataRecord {
  [date: string]: {
    label: string;
    [key: string]: number | string;
  };
}

export const generateGroupedChartData = (
  data: Array<RevenueData | ExpensesData>,
  keys: Array<string>,
  periodType: PeriodType
) => {
  let GroupedBarData: GroupedChartDataRecord = {};

  data.forEach((item) => {
    const name = item.accountName + ` (${item.accountId})`;

    // Ensure only data matching keys is processed
    if (keys.indexOf(name) > -1) {
      // Initialize the period if not already done
      if (!GroupedBarData[item.period]) {
        GroupedBarData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
        };

        // Initialize all keys for this period in the right order
        keys.forEach((key) => {
          GroupedBarData[item.period][key] = 0;
        });
      }

      // Update the value for the corresponding key
      GroupedBarData[item.period][name] =
        Number(GroupedBarData[item.period][name]) + Number(item.sumAmount);
    }
  });

  return GroupedBarData;
};

export interface BarChartDataRecord {
  data: Array<{ label: string; [key: string]: number | string }>;
  labels: Array<string>;
}

export const generateBarChartData = (
  data: Array<RevenueData | ExpensesData>,
  groupKeys: Array<string>,
  keys: Array<string>,
  periodType: PeriodType
) => {
  let barsData: Array<{ label: string; data: Array<BarChartDataRecord> }> = [];
  groupKeys.forEach((groupKey) => {
    let groupData: {
      label: string;
      data: Array<BarChartDataRecord>;
    } = { label: "", data: [] };
    keys.forEach((key: string) => {
      let barData: any = {};
      data.forEach((item) => {
        const name = item.accountName + ` (${item.accountId})`;
        if (groupKey === item.mappingGroupName) {
          if (name === key) {
            if (!barData[item.period]) {
              barData[item.period] = {
                label: formatDateByPeriod(item.period, +periodType),
              };
            }
            if (!barData[item.period][name]) {
              barData[item.period][name] = 0;
            }

            barData[item.period][name] =
              Number(barData[item.period][name]) + Number(item.sumAmount);
          }
        }
      });

      groupData.label = groupKey;
      if (Object.values(barData).length) {
        groupData.data.push({ data: Object.values(barData), labels: [key] });
      }
    });

    barsData.push(groupData);
  });

  return barsData;
};

export const generateDonutChartData = (
  data: Array<RevenueData>,
  keys: Array<string>
) => {
  // Create a map to store the account names with their corresponding group ordering and ordering
  const accountMap = new Map<
    string,
    { groupOrder: number; order: number; value: number }
  >();

  // Process the data and populate the map
  data.forEach((entry) => {
    const name = entry.accountName + ` (${entry.accountId})`;

    // Check if the account name is in the keys list
    if (keys.indexOf(name) > -1) {
      if (!accountMap.has(name)) {
        // If not in the map, initialize it
        accountMap.set(name, {
          groupOrder: entry.accountMappingGroupOrdering,
          order: entry.accountMappingOrdering,
          value: +entry.sumAmount.toFixed(0),
        });
      } else {
        // If it's already in the map, update the value by adding sumAmount
        const existing = accountMap.get(name);
        if (existing) {
          existing.value += +entry.sumAmount.toFixed(0);
        }
      }
    }
  });

  // Convert the map to an array, ensuring the entries follow the `keys` order
  const sortedAccountData = Array.from(accountMap.entries())
    .sort((a, b) => {
      // First, sort by groupOrder, and if those are equal, sort by order
      if (a[1].groupOrder === b[1].groupOrder) {
        return a[1].order - b[1].order;
      }
      return a[1].groupOrder - b[1].groupOrder;
    })
    .map(([accountName, details]) => ({
      name: accountName,
      value: details.value,
    }));

  // Calculate the total sum of all data entries
  const totalSum = data
    .reduce((acc: number, item) => acc + item.sumAmount, 0)
    .toFixed(0);

  // Return the sorted data and the total sum
  return { groupedData: sortedAccountData, totalSum: +totalSum };
};

export const getAllNamesWithKey = (
  data: Array<RevenueData>,
  key: "mappingGroupName" | "companyBranchName" | "companyBusinessLineName"
): Array<string> => {
  // Create a map to store the account names with their corresponding ordering
  const groupMap = new Map<string, number>();

  data
    .sort((a, b) => a.accountMappingTypeOrdering - b.accountMappingTypeOrdering)
    .forEach((entry) => {
      if (entry.sumAmount !== 0) {
        let ordering: number = 1;
        if (key === "mappingGroupName") {
          ordering = entry.accountMappingGroupOrdering || 1;
        } else if (key === "companyBranchName") {
          ordering = entry.companyBranchOrdering || 1;
        } else if (key === "companyBusinessLineName") {
          ordering = entry.companyBusinessLineOrdering || 1;
        }
        if (entry[key] && !groupMap.has(entry[key]!)) {
          groupMap.set(entry[key]!, ordering);
        }
      }
    });

  // Convert the map to an array and sort it by the ordering value
  const sortedGroupeNames = Array.from(groupMap.entries())
    .sort((a, b) => a[1] - b[1])
    .map(([groupeName]) => groupeName);

  return sortedGroupeNames;
};

export const generateProfitLoseGroupedChartDataForGroups = (
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let GroupedBarData: GroupedChartDataRecord = {};

  data.forEach((item) => {
    if (!GroupedBarData[item.period]) {
      GroupedBarData[item.period] = {
        label: formatDateByPeriod(item.period, +periodType),
        ...item,
      };
    }
  });

  return GroupedBarData;
};

// CashFlow
export const generateCashFlowGroupedChartDataForGroups = (
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  const groupedData: { [key: string]: any } = {};

  data.forEach((cashFlowItem) => {
    const { period, cashFlowCategory, cashFlowDirection, amount } =
      cashFlowItem;
    if (!groupedData[formatDateByPeriod(period, periodType)])
      groupedData[formatDateByPeriod(period, periodType)] = {
        period: formatDateByPeriod(period, periodType),
      };

    const directionKey =
      cashFlowDirection === CashFlowDirectionType.Received
        ? "Received"
        : "Paid";
    const categoryKey = CashFlowCategoryType[cashFlowCategory];

    groupedData[formatDateByPeriod(period, periodType)][
      `${directionKey}-${categoryKey}`
    ] = amount;
  });
  return Object.values(groupedData);
};

// cashFlow
export const generateCashFlowOutcome = (
  keys: Array<string>,
  data: Array<CashFlow>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          ["Net Cash Flow"]:
            item.cashFlowDirection === CashFlowDirectionType.Received
              ? item.amount
              : -item.amount,
        };
      } else {
        barData[item.period]["Net Cash Flow"] =
          item.cashFlowDirection === CashFlowDirectionType.Received
            ? barData[item.period]["Net Cash Flow"] + item.amount
            : barData[item.period]["Net Cash Flow"] - item.amount;
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const generateProfitLossNetIncome = (
  keys: Array<string>,
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          Income: (item["Total Income"] || 0) - (item["Total Expense"] || 0),
        };
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const generateProfitLossNetIncomePercentage = (
  keys: Array<string>,
  data: Array<ProfitLostData>,
  periodType: PeriodType
) => {
  let netIncome: BarChartDataRecord = { data: [], labels: [] };
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (!barData[item.period]) {
        barData[item.period] = {
          label: formatDateByPeriod(item.period, +periodType),
          Income:
            ((item["Total Income"] - item["Total Expense"]) /
              item["Total Income"]) *
              100 && item["Total Income"]
              ? ((item["Total Income"] - item["Total Expense"]) /
                  item["Total Income"]) *
                100
              : 0,
        };
      }
    });
    netIncome = { data: Object.values(barData), labels: [key] };
  });

  return netIncome;
};

export const generateGroupedChartDataWithKeyForGroups = (
  data: Array<RevenueData | ExpensesData>,
  keys: Array<string>,
  periodType: PeriodType,
  elementKey:
    | "mappingGroupName"
    | "companyBranchName"
    | "companyBusinessLineName"
) => {
  let GroupedBarData: GroupedChartDataRecord = {};

  data.forEach((item) => {
    if (!GroupedBarData[item.period]) {
      GroupedBarData[item.period] = {
        label: formatDateByPeriod(item.period, +periodType),
      };

      keys.forEach((key) => {
        GroupedBarData[item.period][key] = 0;
      });
    }

    if (keys.indexOf(item[elementKey]!) > -1) {
      GroupedBarData[item.period][item[elementKey]!] =
        Number(GroupedBarData[item.period][item[elementKey]!]) +
        Number(item.sumAmount);
    }
  });

  return GroupedBarData;
};

export const generateBarChartDataWithKeyForGroups = (
  data: Array<RevenueData>,
  keys: Array<string>,
  periodType: PeriodType,
  elementKey:
    | "mappingGroupName"
    | "companyBranchName"
    | "companyBusinessLineName"
) => {
  let barsData: Array<BarChartDataRecord> = [];
  keys.forEach((key: string) => {
    let barData: any = {};
    data.forEach((item) => {
      if (item[elementKey] === key) {
        if (!barData[item.period]) {
          barData[item.period] = {
            label: formatDateByPeriod(item.period, +periodType),
          };
        }
        if (!barData[item.period][item[elementKey]!]) {
          barData[item.period][item[elementKey]!] = 0;
        }
        barData[item.period][item[elementKey]!] =
          Number(barData[item.period][item[elementKey]!]) +
          Number(item.sumAmount);
      }
    });

    barsData.push({ data: Object.values(barData), labels: [key] });
  });

  return barsData;
};

export const generateDonutChartDataWithKeyForGroups = (
  data: Array<RevenueData>,
  keys: Array<string>,
  elementKey:
    | "mappingGroupName"
    | "companyBranchName"
    | "companyBusinessLineName"
) => {
  let groupedData = keys.map((key) => ({ name: key, value: 0 }));

  data.forEach((item) => {
    if (keys.indexOf(item[elementKey]!) > -1) {
      const group = groupedData.find((g) => g.name === item[elementKey]);
      if (group) {
        group.value += +item.sumAmount.toFixed(0);
      }
    }
  });

  const totalSum = data
    .filter((item) => item[elementKey])
    .reduce((acc: number, item) => acc + item.sumAmount, 0)
    .toFixed(0);

  return { groupedData, totalSum: +totalSum };
};

export const formatNumber = (sum: number) =>
  sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getMonthlyPeriods = (
  fromDate: string,
  toDate: string
): string[] => {
  const start = new Date(fromDate);
  const end = new Date(toDate);
  const result: string[] = [];

  const current = new Date(start.getFullYear(), start.getMonth(), 1);

  while (current <= end) {
    const month = current.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month.toString();
    result.push(`${current.getFullYear()}-${formattedMonth}`);
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

// todo
export const getRangPeriodsByType = (
  startDate: string,
  endDate: string,
  periodType: PeriodType
): string[] => {
  const periods: string[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    periods.push(formatDateByPeriod(moment(currentDate).format(), periodType));

    switch (periodType) {
      case PeriodType.Day:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case PeriodType.Week:
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case PeriodType.Month:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case PeriodType.Quarter:
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case PeriodType.SemiAnnual:
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case PeriodType.Annual:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        throw new Error("Invalid period type");
    }
  }

  return periods;
};
