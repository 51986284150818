import React, { ReactNode } from "react";
import { Wrapper } from "./IconButtonMobile.style";

interface IconButtonMobileProps {
  children: ReactNode;
  handleClick: () => void;
}

const IconButtonMobile = ({ children, handleClick }: IconButtonMobileProps) => {
  return <Wrapper onClick={handleClick}>{children}</Wrapper>;
};

export default IconButtonMobile;
