import React, { useEffect } from "react";
import { ThemeProvider } from "./theme/ThemeProvider";
import AppRouter from "./router/AppRouter";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "./store";

function App() {
  const { i18n } = useTranslation();

  const lang = useAppSelector((state) => state.user.language);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
