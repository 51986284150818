import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const IconWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
`;

export const Title = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  `
);
export const SubTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    font-size: 12px;
    line-height: 18px;
  `
);
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const BodyContainer = styled.div`
  margin-top: 24px;
`;
