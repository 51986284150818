import styled from "@emotion/styled";

export const LoaderIconWrapper = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  svg {
    margin-right: 3px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 480px) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
