import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as DownArrow } from "../../../../assets/svgs/largeDownArrow.svg";

interface WrapperProps {
  open: boolean;
}
export const Wrapper = styled.div<WrapperProps>(
  ({ theme, open }) => css`
    border: 1px solid ${theme.colors.lightGrey};
    padding: 25px 20px 25px 15px;
    background-color: ${open ? theme.colors.lightGrey : theme.colors.white};
    cursor: pointer;
    @media (max-width: ${theme.breakpoints.lg}) {
      &:first-of-type {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      &:last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  `
);

export const Question = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 16px;
  `
);

export const Answer = styled.p<{ opened: boolean }>(
  ({ theme, opened }) => css`
    height: ${opened ? "auto" : "0"};
    opacity: ${opened ? "1" : "0"};
    overflow: hidden;
    ${theme.fonts.AvenirLight}
    font-size: 14px;
    margin-top: ${opened ? "20px" : "0"};
    transition: all 0.3s ease-in-out;
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`;

export const Arrow = styled(DownArrow)<{ opened: boolean }>`
  transition: transform 0.3s ease-in-out;
  fill: black;
  ${({ opened, theme }) =>
    opened &&
    css`
      fill: ${theme.colors.primary};
      transform: rotate(-180deg);
    `}
`;
