import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const CustomTableHeader = styled.thead``;

export const TableTitle = styled.th(
  ({ theme }) => css`
    text-align: left;
    color: ${theme.colors.grey};
    padding-bottom: 20px;
  `
);
