import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { isMobile } from "react-device-detect";

import { useAppDispatch, useAppSelector } from "../../../../store";
import { getCashFlowInfo } from "../../../../store/dashboard/dashboardAction";
import {
  Wrapper,
  ChartTitle,
  GridContainer,
  LastUpdated,
} from "./CashFlow.style";

import {
  dashboardDateFormat,
  generatePeriodTypes,
} from "../../../../helpers/helper";
import { BottomSheetType } from "../../../../enums/enum";
import BarChartContainer from "../../../../components/desktop/charts/barChart/BarChart";
import ChartFilter from "../../../../components/desktop/chartFilter/ChartFilter";

import ChartHolder from "../../../../components/shared/chartEmptyHolder/ChartEmptyHolder";
import Loader from "../../../../components/shared/loader/Loader";
import { Filter } from "../../../../store/dashboard/dashboard.types";

import ChartFilterButtonMobile from "../../../../components/mobile/filerButton/FilerButtonMobile";
import { setBottomSheet } from "../../../../store/user/userSlice";
import GroupChartCashFlow from "../../../../components/desktop/charts/groupchartCashFlow/GroupChartCashFlow";
import { updateFilter } from "../../../../store/dashboard/dashboardSlice";

const CashFlow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const groupedChartData = useAppSelector(
    (state) => state.dashboard.cashFlowInfo.groupedChartData
  );

  const filter = useAppSelector((state) => state.dashboard.filter);

  const frontSideFilter = useAppSelector(
    (state) => state.dashboard.frontSideFilter
  );

  const loadingDashboard = useAppSelector((state) => state.dashboard.loading);

  const cashIn = useAppSelector((state) => state.dashboard.cashFlowInfo.cashIn);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const cashFlowInfoLastDateUpdate = useAppSelector(
    (state) => state.dashboard.cashFlowInfoLastDateUpdate
  );

  useEffect(() => {
    if (filter.fromDate && filter.toDate && filter.periodType && !isMobile) {
      setLoading(true);
      dispatch(getCashFlowInfo(filter)).then(() => {
        setLoading(false);
      });
    }
  }, [filter, selectedCompany, dispatch]);

  const handleFilter = (values: Filter) => {
    if (
      filter.periodType === values.periodType &&
      filter.fromDate === values.fromDate &&
      filter.toDate === values.toDate
    ) {
    } else {
      dispatch(
        updateFilter({
          periodType: values.periodType,
          fromDate: values.fromDate,
          toDate: values.toDate,
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        {isMobile ? (
          <ChartFilterButtonMobile
            handleClick={() => {
              dispatch(setBottomSheet(BottomSheetType.FILTER));
            }}
          />
        ) : (
          <>
            {cashFlowInfoLastDateUpdate && (
              <LastUpdated>
                Last Updated {dashboardDateFormat(cashFlowInfoLastDateUpdate)}
              </LastUpdated>
            )}

            <ChartFilter
              filterData={filter}
              frontSideFilter={frontSideFilter}
              hasGroupBy={false}
              options={generatePeriodTypes()}
              handleSubmit={(values) => {
                handleFilter(values);
              }}
            />
          </>
        )}

        {loading || loadingDashboard === "pending" ? (
          <Loader />
        ) : (
          <>
            <div>
              {Boolean(groupedChartData?.data.length) ? (
                <div>
                  <ChartTitle isMobile={isMobile}>
                    {t("CashFlow.MainTitle")}
                  </ChartTitle>
                  <GroupChartCashFlow
                    stackId={undefined}
                    chartData={groupedChartData}
                  />
                </div>
              ) : (
                <ChartHolder title="" />
              )}
            </div>
            <GridContainer isMobile={isMobile}>
              <div>
                {cashIn.data.length ? (
                  <>
                    <ChartTitle isMobile={isMobile}>
                      {t("CashFlow.BarChartTitle")}
                    </ChartTitle>
                    <BarChartContainer chartData={cashIn} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </GridContainer>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default CashFlow;
