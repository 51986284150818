import React from "react";
import {
  AccountInfo,
  Action,
  Title,
  RadioButton,
  Wrapper,
  ActionButton,
} from "./AccountItem.style";
import Button from "../button/Button";
import TextButton from "../textButton/TextButton";
import { useTranslation } from "react-i18next";

interface ChooseAccountProps {
  title: string;
  id: number;
  selectedOption: number | null;
  defaultOption: number | null;
  handleSelectDefault: (id: number) => void;
  handleSelectOption: (id: number) => void;
}

const ChooseAccount = ({
  title,
  id,
  selectedOption,
  defaultOption,
  handleSelectDefault,
  handleSelectOption,
}: ChooseAccountProps) => {
  const { t } = useTranslation();

  const handleSelect = (itemId: any) => {
    if (selectedOption === null) {
      handleSelectOption(itemId);
    }
  };
  return (
    <Wrapper
      checked={selectedOption === id}
      onClick={(e) => {
        e.stopPropagation();
        handleSelectOption(id);
      }}
    >
      <AccountInfo>
        <Title>{title}</Title>
      </AccountInfo>
      <Action>
        <RadioButton
          type="radio"
          id={id.toString()}
          name="account"
          checked={defaultOption === id}
          onChange={() => {}}
          onClick={(e) => {
            handleSelect(id);
            e.stopPropagation();
            handleSelectDefault(id);
          }}
        />
        <ActionButton
          onClick={(e) => {
            e.stopPropagation();
            handleSelectDefault(id);
          }}
        >
          {defaultOption === id ? (
            <Button title={t("ChooseAccountItem.Unselect")} size="small" />
          ) : (
            <TextButton
              handleClick={() => {
                handleSelect(id);
              }}
              title={t("ChooseAccountItem.SetAsDefault")}
            />
          )}
        </ActionButton>
      </Action>
    </Wrapper>
  );
};

export default ChooseAccount;
