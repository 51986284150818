import React, { useEffect, useState } from "react";

import {
  InputRow,
  Section,
  InputWrapper,
  Wrapper,
} from "./CompanyInformationForm.style";

import { Formik, Form, Field } from "formik";
import { CompanyInfoSchema } from "./schema";

import Input from "../../../shared/input/Input";
import Button from "../../../shared/button/Button";
import DropDown from "../../../shared/dropdown/Dropdown";

import { useTranslation } from "react-i18next";
import { Company } from "../../../../store/company/company.types";
import { Category, CompanyForm } from "../../../../store/user/user.types";

export interface CompanyInfoFormValues {
  name: string;
  company: string;
  email: string;
  mobile: string;
  country: string;
  city: string;
  address: string;
  postalCode: string;
  companyFormId: string | number;
  companyCategoryId: string | number;
}

interface CompanyInfoFormProps {
  handleSubmit: (values: CompanyInfoFormValues) => void;
  companyInfo: Company | null;
  companyForms: Array<CompanyForm>;
  companyCategories: Array<Category>;
  isLoading: boolean;
  formRef: any;
}
const CompanyInfoForm = ({
  companyInfo,
  companyForms,
  companyCategories,
  handleSubmit,
  isLoading,
  formRef,
}: CompanyInfoFormProps) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    country: "",
    city: "",
    address: "",
    postalCode: "",
    companyFormId: "",
    companyCategoryId: "",
  });

  useEffect(() => {
    if (companyInfo) {
      setInitialValues({
        name: companyInfo.name,
        company: companyInfo.identificationCode,
        email: companyInfo.email,
        mobile: companyInfo.mobile || "",
        country: companyInfo.country,
        city: companyInfo.city,
        address: companyInfo.address,
        postalCode: companyInfo.postalCode,
        companyFormId: companyInfo.companyFormId.toString(),
        companyCategoryId: companyInfo.companyCategoryId.toString(),
      });
    }
  }, [companyInfo]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CompanyInfoSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue }) => (
        <Form>
          <Wrapper>
            <Section>
              <InputRow>
                <InputWrapper>
                  <Field
                    name="name"
                    label={t("CompanyInfo.CompanyName")}
                    disabled
                    component={Input}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Field
                    name="company"
                    label={t("CompanyInfo.CompanyId")}
                    disabled
                    component={Input}
                  />
                </InputWrapper>
              </InputRow>
              <InputRow>
                <InputWrapper>
                  <Field
                    name="email"
                    label={t("CompanyInfo.CompanyEmail")}
                    component={Input}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Field
                    name="mobile"
                    label={t("CompanyInfo.CompanyNumber")}
                    component={Input}
                  />
                </InputWrapper>
              </InputRow>
            </Section>
            <Section>
              <InputRow>
                <InputWrapper>
                  <Field
                    name="country"
                    label={t("CompanyInfo.Country")}
                    component={Input}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Field
                    name="city"
                    label={t("CompanyInfo.City")}
                    component={Input}
                  />
                </InputWrapper>
              </InputRow>
              <InputRow>
                <InputWrapper>
                  <Field
                    name="address"
                    label={t("CompanyInfo.Street")}
                    component={Input}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Field
                    name="postalCode"
                    label={t("CompanyInfo.ZipCode")}
                    component={Input}
                  />
                </InputWrapper>
              </InputRow>
            </Section>
            <InputRow>
              <DropDown
                haveToolTip
                toolTipText={t("CompanyInfo.FormToolTipText")}
                title="Form"
                options={companyForms.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                selectedValue={values.companyFormId}
                handleSelect={(value) => {
                  setFieldValue("companyFormId", value);
                }}
              />
              <DropDown
                haveToolTip
                toolTipText={t("CompanyInfo.CategoryToolTipText")}
                title="Category"
                options={companyCategories.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                selectedValue={values.companyCategoryId}
                handleSelect={(value) => {
                  setFieldValue("companyCategoryId", value);
                }}
              />
            </InputRow>

            <Button
              title={t("CompanyInfo.ButtonText")}
              type="submit"
              isDisabled={!dirty}
              isLoading={isLoading}
            />
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyInfoForm;
