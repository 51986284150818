import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import {
  ButtonWrapper,
  InputWrapper,
  Title,
  Wrapper,
} from "./UpdatePersonalDetail.style";
import Input from "../../../../components/shared/input/Input";

import Button from "../../../../components/shared/button/Button";

import { userInfoUpdate } from "../../../../store/user/userAction";
import { PersonalInfoSchema } from "./schema";
import { ActionType } from "../../../../store/type";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../store/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { isMobile } from "react-device-detect";

const UpdatePersonalDetail = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile,
      });
    }
  }, [user]);

  const handleSubmit = (values: {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
  }) => {
    setLoading(true);
    dispatch(
      userInfoUpdate({ ...values, mobileCountryCode: user?.mobileCountryCode })
    ).then(({ error, payload }: ActionType) => {
      setLoading(false);
      if (error?.message) {
        dispatch(showErrorMessage(error.message));
      }
      if (payload) {
        dispatch(showSuccessMessage(true));
      }
    });
  };

  return (
    <Wrapper isMobile={isMobile}>
      <Formik
        enableReinitialize
        validationSchema={PersonalInfoSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          setInitialValues(values);
          resetForm();
        }}
      >
        {({ dirty }) => (
          <Form>
            <>
              <Title>{t("UpdatePersonalDetail.Title")}</Title>
              <InputWrapper>
                <Field
                  type="text"
                  name="firstName"
                  component={Input}
                  label={t("UpdatePersonalDetail.FirstNamePlaceholder")}
                />

                <Field
                  type="text"
                  name="email"
                  disabled
                  component={Input}
                  label={t("UpdatePersonalDetail.EmailPlaceholder")}
                />
                <Field
                  type="text"
                  name="lastName"
                  component={Input}
                  label={t("UpdatePersonalDetail.LastNamePlaceholder")}
                />

                <Field
                  type="text"
                  name="mobile"
                  component={Input}
                  label={t("UpdatePersonalDetail.MobilePhonePlaceholder")}
                />
                <ButtonWrapper>
                  <Button
                    title={t("UpdatePersonalDetail.ButtonText")}
                    type="submit"
                    isLoading={loading}
                    isDisabled={!dirty}
                  />
                </ButtonWrapper>
              </InputWrapper>
            </>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default UpdatePersonalDetail;
