export enum Color {
  PrimaryHover = "primaryHover",
  PrimaryLight = "primaryLight",
  DangerBorder = "dangerBorder",
  DangerMain = "dangerMain",
  DangerBackgroundLight = "dangerBackgroundLight",
  DangerBackground = "dangerBackground",
  Black = "black",

  Grey = "grey",
  GreyBorderDark = "greyBorderDark",
  GreyBorder = "greyBorder",
  LightGrey = "lightGrey",
  Yellow = "yellow",
  YellowBackground = "yellowBackground",
  Green = "green",
  GreenBackground = "greenBackground",
  ModalBackground = "modalBackground",
  DarkBlue = "darkBlue",
  DarkBlue1 = "darkBlue1",
  DarkBlue2 = "darkBlue2",
  BlueBackground = "blueBackground",
  Orange = "orange",
  OrangeBackground = "orangeBackground",
  //
  Bg002 = "bg002",
  White = "white",
  Black005 = "black005",
  Black900 = "black900",
  // dark blue
  DarkBlue001 = "darkBlue001",
  DarkBlue002 = "darkBlue002",
  DarkBlue003 = "darkBlue003",
  DarkBlue004 = "darkBlue004",
  DarkBlue005 = "darkBlue005",
  DarkBlue006 = "darkBlue006",
  // negative
  Negative100 = "negative100",
  Negative200 = "negative200",
  Negative600 = "negative600",
  Negative700 = "negative700",
  // negativeShade
  NegativeShade50 = "negativeShade50",
  NegativeShade100 = "negativeShade100",
  // WraningShade
  WarningShade300 = "warningShade300",
  // Success
  Success600 = "success600",
  // grey
  Grey003 = "grey003",
  Grey50 = "grey50",
  Grey100 = "grey100",
  Grey200 = "grey200",
  Grey300 = "grey300",
  Grey400 = "grey400",
  Grey500 = "grey500",
  Grey600 = "grey600",
  Grey700 = "grey700",
  Grey800 = "grey800",
  Grey900 = "grey900",
  // greyShade
  GreyShade50 = "greyShade50",
  GreyShade100 = "greyShade100",
  GreyShade200 = "greyShade200",
  GreyShade300 = "greyShade300",
  // textPrimary
  TextPrimary = "textPrimary",
  // textSecondary
  TextSecondary = "textSecondary",
  // primary
  Primary = "primary",
  Primary50 = "primary50",
  Primary100 = "primary100",
  Primary200 = "primary200",
  Primary300 = "primary300",
  Primary400 = "primary400",
  Primary500 = "primary500",
  Primary600 = "primary600",
  Primary700 = "primary700",
  Primary800 = "primary800",
  Primary900 = "primary900",
  // primary shade
  PrimaryShade50 = "primaryShade50",
  PrimaryShade100 = "primaryShade100",
  PrimaryShade200 = "primaryShade200",
  PrimaryShade300 = "primaryShade300",
  PrimaryShade400 = "primaryShade400",
  PrimaryShade500 = "primaryShade500",
  PrimaryShade600 = "primaryShade600",
  PrimaryShade700 = "primaryShade700",
  PrimaryShade800 = "primaryShade800",
}
