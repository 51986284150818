import React, { useState } from "react";
import {
  List,
  Item,
  Wrapper,
  ItemLabel,
  BottomWrapper,
} from "./SelectDateTypeMobile.style";
import { RadioButton } from "../../shared/accountItem/AccountItem.style";
import { generatePeriodTypes } from "../../../helpers/helper";
import { BottomSheetType, PeriodType } from "../../../enums/enum";
import Button from "../../shared/button/Button";
import { useTranslation } from "react-i18next";

interface SelectDateTypeMobileProps {
  periodType: PeriodType | null;
  submitPeriodType: (type: PeriodType) => void;
  handleClick: (type: BottomSheetType) => void;
}
const SelectDateTypeMobile = ({
  periodType,
  submitPeriodType,
  handleClick,
}: SelectDateTypeMobileProps) => {
  const { t } = useTranslation();
  const [dateTypePicker, setDateTypePicker] = useState<PeriodType | null>(
    periodType
  );
  const [isChanged, setIsChanged] = useState(false);
  return (
    <>
      <Wrapper>
        <List>
          {generatePeriodTypes().map((item) => (
            <Item
              key={item.value}
              clicked={dateTypePicker?.toString() === item.value}
              onClick={() => {
                setIsChanged(true);
                setDateTypePicker(+item.value);
              }}
            >
              <ItemLabel>{t(item.label)}</ItemLabel>
              <RadioButton
                type="radio"
                id="selectDateType"
                name="dateType"
                checked={dateTypePicker?.toString() === item.value}
                onChange={() => {}}
              />
            </Item>
          ))}
        </List>
      </Wrapper>
      <BottomWrapper>
        <Button
          title={t("ChartFilter.ButtonTitle")}
          isDisabled={!isChanged}
          onClick={() => {
            if (isChanged && dateTypePicker) {
              handleClick(BottomSheetType.FILTER);
              submitPeriodType(dateTypePicker);
            }
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default SelectDateTypeMobile;
