import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import {
  Wrapper,
  TitleWrapper,
  Title,
  SubTitle,
  TabsWrapper,
  TabsRow,
  DropDownWrapper,
} from "./Reportal.style";
import DropDown from "../../../components/shared/dropdown/Dropdown";

import Tab from "../../../components/shared/tabs/tab/Tab";
import Financial from "./financial/Financial";
import WorkOutcome from "./workOutcome/WorkOutcome";
import { generatePreviousYearsData } from "../../../helpers/helper";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getReportalReport } from "../../../store/dashboard/dashboardAction";
import { ReportalReportItem } from "../../../store/dashboard/dashboard.types";

export type ReportalDataType = {
  data: ReportalReportItem[];
  id: number;
  name: string;
  rePortalMappingType: number;
  isSum: boolean;
  mustToSumChildIds: number[];
};

const Reportal = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedYear, setSelectedYear] = useState(
    generatePreviousYearsData(1)[0].value
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const reportalMappings = useAppSelector(
    (state) => state.user.rePortalMappings
  );

  const reportalData = useAppSelector((state) => state.dashboard.reportalData);

  const financialData: Array<ReportalDataType> = useMemo(() => {
    return reportalMappings
      .filter((item) => item.rePortalMappingType === 1)
      .map((item) => {
        return {
          ...item,
          data: reportalData.financeStanding.filter(
            (financeItem) => financeItem.rePortalMappingId === item.id
          ),
        };
      });
  }, [reportalMappings, reportalData, selectedCompany]);

  const workOutcomeData: Array<ReportalDataType> = useMemo(() => {
    return reportalMappings
      .filter((item) => item.rePortalMappingType === 2)
      .map((item) => {
        return {
          ...item,
          data: reportalData.workOutcome.filter(
            (financeItem) => financeItem.rePortalMappingId === item.id
          ),
        };
      });
  }, [reportalMappings, reportalData, selectedCompany]);

  const handleSelectTab = (id: number) => {
    setSelectedTab(id);
  };

  useEffect(() => {
    if (selectedYear) {
      setLoading(true);
      dispatch(getReportalReport({ year: +selectedYear })).then(() => {
        setLoading(false);
      });
    }
  }, [selectedYear, dispatch, selectedCompany]);

  return (
    <Wrapper isMobile={isMobile}>
      <TitleWrapper isMobile={isMobile}>
        <Title>{t("Reportal.Title")}</Title>
        <SubTitle>{t("Reportal.SubTitle")}</SubTitle>
      </TitleWrapper>
      <TabsRow isMobile={isMobile}>
        <TabsWrapper>
          <Tab
            isClicked={selectedTab === 1}
            handleClick={() => {
              handleSelectTab(1);
            }}
            title={t("Reportal.FinancialTitle")}
          />
          <Tab
            isClicked={selectedTab === 2}
            handleClick={() => {
              handleSelectTab(2);
            }}
            title={t("Reportal.WorkOutcomeTitle")}
          />
          <Tab
            isClicked={selectedTab === 3}
            handleClick={() => {
              handleSelectTab(3);
            }}
            title={t("Reportal.FundsTitle")}
          />
        </TabsWrapper>
        <DropDownWrapper isMobile={isMobile}>
          <DropDown
            title="Year"
            options={generatePreviousYearsData(1)}
            selectedValue={selectedYear}
            handleSelect={(value) => {
              setSelectedYear(value);
            }}
          />
        </DropDownWrapper>
      </TabsRow>
      {selectedTab === 1 && (
        <Financial
          financialData={financialData}
          activeYear={selectedYear}
          loading={loading}
        />
      )}
      {selectedTab === 2 && (
        <WorkOutcome
          workOutcomeData={workOutcomeData}
          activeYear={selectedYear}
          loading={loading}
        />
      )}
      {selectedTab === 3 && <div></div>}
    </Wrapper>
  );
};

export default Reportal;
