import * as Yup from "yup";

export const initialValues = {
  password: "",
  repeatPassword: "",
};

export const CreatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "CreatePasswordFormError.PasswordMinError")
    .max(50, "CreatePasswordFormError.PasswordMaxError")
    .required("CreatePasswordFormError.Required"),
  repeatPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), undefined],
      "CreatePasswordFormError.PasswordMatchError"
    )
    .required("CreatePasswordFormError.PasswordRequiredError"),
});
