import React, { useState } from "react";
import { Formik, Form, Field } from "formik";

import PasswordInput from "../../../../components/shared/passwordInput/PasswordInput";
import Button from "../../../../components/shared/button/Button";
import {
  ButtonWrapper,
  InputWrapper,
  Title,
  Wrapper,
} from "./UpdatePassword.style";
import { useTranslation } from "react-i18next";
import { initialValues, validationSchema } from "./schema";

import { userInfoUpdate } from "../../../../store/user/userAction";
import { ActionType } from "../../../../store/type";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../store/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (
    values: { currentPassword: string; newPassword: string },
    actions: any
  ) => {
    setLoading(true);
    const data = {
      currentPassword: values.currentPassword,
      password: values.newPassword,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      mobile: user?.mobile,
      mobileCountryCode: user?.mobileCountryCode,
    };
    dispatch(userInfoUpdate(data)).then(({ error, payload }: ActionType) => {
      setLoading(false);
      if (error?.message) {
        dispatch(showErrorMessage(error.message));
      }
      if (payload) {
        dispatch(showSuccessMessage(true));
      }
    });
    actions.setSubmitting(false);
  };

  return (
    <Wrapper>
      <Title>{t("UpdatePassword.Title")}</Title>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <InputWrapper>
              <Field
                name="currentPassword"
                label={t("UpdatePassword.CurrentPasswordPlaceHolder")}
                component={PasswordInput}
              />

              <Field
                name="newPassword"
                label={t("UpdatePassword.NewPasswordPlaceHolder")}
                component={PasswordInput}
              />

              <Field
                name="confirmNewPassword"
                label={t("UpdatePassword.ConfirmPasswordPlaceHolder")}
                component={PasswordInput}
              />
              <ButtonWrapper>
                <Button
                  title={t("UpdatePassword.ButtonText")}
                  type="submit"
                  isDisabled={false}
                  isLoading={loading}
                />
              </ButtonWrapper>
            </InputWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default UpdatePassword;
