import * as Yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  clientRoleId: "",
};

export const InviteUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, "InviteUserError.FirstNameError")
    .required("InviteUserError.Required"),
  lastName: Yup.string()
    .max(50, "InviteUserError.LastNameError")
    .required("InviteUserError.Required"),
  email: Yup.string()
    .email("InviteUserError.EmailError")
    .required("InviteUserError.Required"),
  clientRoleId: Yup.string()
  .required("InviteUserError.Required"),
});
