import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import {
  ButtonWrapper,
  DropDownItem,
  DropDownsWrapper,
  FilterSection,
  FormWrapper,
  InputField,
  InputWrapper,
  Label,
  Results,
  Wrapper,
} from "./UserManagement.style";
import { User } from "../../../store/user/user.types";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  checkEmail,
  fetchUserList,
  inviteUser,
  updateUser,
} from "../../../store/user/userAction";
import { ActionType } from "../../../store/type";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../store/user/userSlice";
import Input from "../../../components/shared/input/Input";
import Button from "../../../components/shared/button/Button";
import DropDown from "../../../components/shared/dropdown/Dropdown";
import { generateUserStatuses } from "../../../helpers/helper";
import UserManagementTable from "../../../components/desktop/userManagement/userManagementTable/UserManagementTable";
import Pagination from "../../../components/shared/pagination/Pagination";
import InviteUser from "../../../components/desktop/forms/inviteUser/InviteUser";
import ConfirmationModal from "../../../components/shared/confirmationModal/ConfirmationModal";
import UserManagementMobile from "../../../components/mobile/userManagement/UserManagementMobile";

const tableHeaders = [
  "UserManagement.Name",
  "UserManagement.UserRole",
  "UserManagement.Status",
  "UserManagement.Joined",
  "UserManagement.LastActive",
];

const UserManagement = () => {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [disable, setDisable] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [loadingIds, setLoadingIds] = useState<Array<number>>([]);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const [listLoading, setListLoading] = useState(true);
  const [userData, setUserData] = useState<{
    user: User;
    roleId?: string;
    statusId?: string;
  } | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;
  const [inviteErrorMessage, setInviteErrorMessage] = useState("");

  const [inviteUserData, setInviteUserData] = useState<User | null | undefined>(
    undefined
  );

  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.user.userRoles);

  const companyData = useAppSelector((state) => state.user.companyData);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  useEffect(() => {
    const fetchUserListDelayed = () => {
      const params = {
        page: currentPage,
        pageSize,
        ClientRoleId: Number(selectedRole) || null,
        Status: Number(selectedStatus) || null,
        Name: searchTerm,
      };
      setListLoading(true);

      dispatch(fetchUserList(params)).then(() => {
        setListLoading(false);
      });
    };
    let timeoutId: any;
    if (searchTerm) {
      timeoutId = setTimeout(fetchUserListDelayed, 500);
    } else {
      fetchUserListDelayed();
    }

    return () => clearTimeout(timeoutId);
  }, [
    dispatch,
    selectedRole,
    selectedStatus,
    selectedCompany,
    currentPage,
    searchTerm,
  ]);

  const userRolesFilter = useMemo(() => {
    const data = roles?.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }));
    return [{ value: "", label: t("UserRoles.All") }, ...data];
  }, [roles, t]);

  const userRoles = useMemo(() => {
    const data = roles?.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    }));
    return data;
  }, [roles]);

  const handleUpdateRole = (user: User, roleId: string) => {
    setOpenConfirmModal(true);
    setUserData({ user, roleId });
  };

  const handleUpdateStatus = (user: User, statusId: string) => {
    setOpenConfirmModal(true);
    setUserData({ user, statusId });
  };

  const handleConfirm = () => {
    const clientCompanyId = userData?.user.clientCompany?.id;
    const clientCompanyRoleId = userData?.user.clientCompany?.clientRoleId;
    const clientCompanyStatus = userData?.user.clientCompany?.status;
    const updatedRole = userData?.roleId;
    const updatedStatus = userData?.statusId;
    const userId = userData?.user.id;
    if (
      clientCompanyId &&
      clientCompanyRoleId &&
      clientCompanyStatus &&
      userId
    ) {
      setLoadingIds((prev) => [...prev, clientCompanyId]);
      setOpenConfirmModal(false);

      const data = {
        id: clientCompanyId,
        clientRoleId: Number(updatedRole) || Number(clientCompanyRoleId),
        status: Number(updatedStatus) || Number(clientCompanyStatus),
        userId: +userId,
      };
      dispatch(updateUser(data)).then(({ error, payload }: ActionType) => {
        setLoadingIds((prev) =>
          prev.filter((loadingItemId) => loadingItemId !== clientCompanyId)
        );

        if (error?.message) {
          dispatch(showErrorMessage(error.message));
        }
        if (payload) {
          dispatch(showSuccessMessage(true));
        }
      });
    }
  };

  const handleUserInvite = ({
    firstName,
    lastName,
    email,
    clientRoleId,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    clientRoleId: string;
  }) => {
    setLoadingInvite(true);
    setInviteErrorMessage("");
    const data = {
      email,
      firstName,
      lastName,
      clientRoleId: +clientRoleId,
      mobileCountryCode: "995",
    };
    dispatch(inviteUser(data)).then(({ error, payload }: ActionType) => {
      if (error) {
        setInviteErrorMessage(error.message || "something wrong");
      }

      setLoadingInvite(false);
      if (payload) {
        dispatch(showSuccessMessage(true));
        setOpenModal(false);
      }
    });
  };

  const handleCheck = (email: string) => {
    setInviteErrorMessage("");
    setLoadingCheck(true);
    dispatch(checkEmail({ email })).then(({ payload, error }: ActionType) => {
      setLoadingCheck(false);
      if (error?.message) {
        setInviteErrorMessage(error.message);
        setDisable(false);
      } else if (payload) {
        setInviteUserData(payload);
        setDisable(true);
      } else {
        setDisable(false);
        setInviteUserData(null);
      }
    });
  };
  return (
    <Wrapper>
      <Formik initialValues={{ search: "" }} onSubmit={() => {}}>
        {() => (
          <Form>
            <FormWrapper>
              <InputWrapper>
                <Label>{t("UserManagement.FieldTitle")}</Label>
                <InputField>
                  <Field
                    type="text"
                    name="search"
                    component={Input}
                    label={t("UserManagement.FieldLabel")}
                    onKeyUp={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </InputField>
                <ButtonWrapper
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <Button
                    title={t("UserManagement.ButtonText")}
                    type="button"
                  />
                </ButtonWrapper>
              </InputWrapper>
            </FormWrapper>
          </Form>
        )}
      </Formik>
      <FilterSection>
        <Results>
          {t("UserManagement.ResultFirstHalfText")} {companyData.items.length}{" "}
          {t("UserManagement.ResultLastHalfText")}
        </Results>
        <DropDownsWrapper isMobile={isMobile}>
          <DropDownItem isMobile={isMobile}>
            <DropDown
              title={t("UserManagement.UserRollDropDown")}
              options={userRolesFilter}
              selectedValue={selectedRole}
              handleSelect={setSelectedRole}
            />
          </DropDownItem>
          <DropDownItem isMobile={isMobile}>
            <DropDown
              title={t("UserManagement.StatusDropDown")}
              options={generateUserStatuses()}
              selectedValue={selectedStatus}
              handleSelect={setSelectedStatus}
            />
          </DropDownItem>
        </DropDownsWrapper>
      </FilterSection>

      {isMobile ? (
        <UserManagementMobile
          data={companyData.items}
          userStatues={generateUserStatuses(false)}
          userRoles={userRoles}
          loadingIds={loadingIds}
          updateUserRole={handleUpdateRole}
          updateUserStatus={handleUpdateStatus}
          headers={tableHeaders}
        />
      ) : (
        <UserManagementTable
          loadingIds={loadingIds}
          loading={listLoading}
          data={companyData.items}
          userRoles={userRoles}
          tableHeaders={tableHeaders}
          userStatues={generateUserStatuses(false)}
          updateUserRole={handleUpdateRole}
          updateUserStatus={handleUpdateStatus}
        />
      )}

      {Boolean(companyData.count) && (
        <Pagination
          selectedPage={currentPage}
          limit={pageSize}
          siblings={1}
          totalPage={Math.ceil(companyData.count / pageSize)}
          handlePage={(pageIndex) => {
            setCurrentPage(pageIndex);
          }}
        />
      )}
      {openModal && (
        <InviteUser
          userData={inviteUserData}
          userRoles={userRoles}
          loadingInvite={loadingInvite}
          loadingCheck={loadingCheck}
          inviteErrorMessage={inviteErrorMessage}
          handleSubmit={handleUserInvite}
          handleCheck={handleCheck}
          disable={disable}
          handleClose={() => {
            setInviteUserData(undefined);
            setInviteErrorMessage("");
            setOpenModal(false);
          }}
        />
      )}

      {openConfirmModal && (
        // todo
        <ConfirmationModal
          description={`${t("UserManagement.ConfirmationModalFirstHalf")} ${
            userData?.roleId ? "Role" : "Status"
          } ${t("UserManagement.ConfirmationModalSecondHalf")} ${
            userData?.user.firstName + " " + userData?.user.lastName
          }?`}
          handleClose={() => {
            setUserData(null);
            setOpenConfirmModal(false);
          }}
          handleConfirm={handleConfirm}
        />
      )}
    </Wrapper>
  );
};

export default UserManagement;
