import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IsMobile {
  isMobile: boolean;
}
export const Wrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    min-height: 100vh;
    padding: ${isMobile ? "20px 0" : "55px 0"};
    overflow-x: hidden;
    width: 100%;
  `
);

export const TitleWrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: ${isMobile ? "30px" : "70px"};
  `
);

export const Title = styled.h2(
  ({ theme }) => css`
    font-size: 18px;
    ${theme.fonts.AvenirDemi}
    color: ${theme.colors.black};
  `
);

export const SubTitle = styled.h2(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.grey};
  `
);
export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 100%;
`;
export const TabsRow = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${isMobile ? "25px" : "60px"};
    ${isMobile && "flex-direction: column-reverse;"}
  `
);

export const DropDownWrapper = styled.div<IsMobile>(
  ({ isMobile }) => css`
    width: ${isMobile ? "100%" : "165px"};
  `
);
