import React, { useState, useRef } from "react";
import { useFormikContext } from "formik";
import Select, { StylesConfig } from "react-select";
import { FixedSizeList as List } from "react-window";

import { LabelWrapper, Wrapper } from "./SearchableSelect.style";

const heightPerRow = 35; // Adjust the height of each row (item)

const MenuList = (props: any) => {
  const { options, children, maxHeight, getValue } = props;

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * heightPerRow;

  // Adjust height based on the number of filtered items (children)
  const dropdownHeight = Math.min(maxHeight, children.length * heightPerRow);

  return (
    <List
      height={dropdownHeight || 100} // Use dynamic height here
      itemCount={children.length || 0} // Use the number of children (filtered items)
      itemSize={heightPerRow}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => (
        <div style={style}>
          {children[index]} {/* Render each option */}
        </div>
      )}
    </List>
  );
};

const Styles: StylesConfig = {
  option: (styles) => ({
    ...styles,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  menu: (styles) => ({
    ...styles,
    maxWidth: "100%",
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    padding: "5px 10px",
    borderRadius: 15,
    borderColor: state.isFocused ? "#656afa" : "#9298a6", // Only changes on focus
    boxShadow: state.isFocused ? "0 0 0 1px #656afa" : "none", // Optional: add a shadow when focused
    "&:hover": {
      borderColor: state.isFocused ? "#656afa" : "#9298a6", // Ensures hover doesn't change color unless focused
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

const SearchableSelect = ({
  options,
  label,
  onCustomChange,
  onInputChange,
  hasValue,
  hasLazyLoading = false,
  isLoading,
  ...props
}: any) => {
  const { setFieldValue } = useFormikContext();
  const [isFocused, setIsFocused] = useState(false);
  const selectRef = useRef<any>(null); // Create a ref for the Select component

  const handleChange = (item?: { value: number; label: string }) => {
    const newValue = item?.value || null;
    setFieldValue(props.field.name, newValue);

    if (onCustomChange) {
      onCustomChange(newValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleLabelClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      selectRef.current.openMenu();
    }
  };

  return (
    <Wrapper tabIndex={0} onFocus={handleFocus} onBlur={handleBlur}>
      {label && (
        <LabelWrapper
          onClick={handleLabelClick}
          isFocused={isFocused}
          hasValue={hasValue}
        >
          <label
            htmlFor={props.field.name}
            style={{ marginBottom: "5px", display: "block" }}
          >
            {label}
          </label>
        </LabelWrapper>
      )}
      <Select
        {...props}
        ref={selectRef}
        styles={Styles}
        isLoading={isLoading}
        options={options}
        onChange={handleChange}
        components={hasLazyLoading ? { MenuList } : {}}
        getOptionValue={(option: { label: string; value: string }) =>
          option.value
        }
        value={
          options.find((option: any) => option.value === props.field.value) ||
          null
        }
        onInputChange={onInputChange ? onInputChange : () => {}}
        isClearable
        isSearchable
        menuPlacement="bottom"
        menuPortalTarget={document.body}
      />
    </Wrapper>
  );
};

export default SearchableSelect;
