import React, { useState } from "react";
import { DateRangePicker } from "rsuite";

import {
  CalendarWrapper,
  Item,
  List,
  Wrapper,
  RadioButton,
  GlobalStyles,
  BottomWrapper,
} from "./DateRangeMobile.style";

import { ReactComponent as CalendarSvg } from "../../../../assets/svgs/calendar.svg";
import { Global } from "@emotion/react";

import { Filter } from "../../../../store/dashboard/dashboard.types";
import { BottomSheetType, RangeType } from "../../../../enums/enum";
import Button from "../../../shared/button/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface DateRangeMobileProps {
  filter: Filter;
  dateRange: RangeType;
  isChanged: boolean;
  handleDateRange: (type: RangeType) => void;
  updateDate: (fromDate: string, toDate: string) => void;
  handleClick: (type: BottomSheetType) => void;
}

const DateRangeMobile = ({
  filter,
  dateRange,
  handleDateRange,
  updateDate,
  handleClick,
}: DateRangeMobileProps) => {
  const { t } = useTranslation();
  const [dateRangePicker, setDateRangePicker] = useState<RangeType>(dateRange);

  const [dates, setDates] = useState({
    fromDate: filter.fromDate,
    toDate: filter.toDate,
  });

  const [isChanged, setIsChanged] = useState({ date: false, range: false });

  const handleDateRangeUpdate = (type: RangeType) => {
    setDateRangePicker(type);
    setIsChanged((prev) => ({ ...prev, range: true }));
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <CalendarWrapper>
        <DateRangePicker
          showOneCalendar
          caretAs={CalendarSvg}
          showHeader={false}
          character={"-"}
          value={[new Date(dates.fromDate), new Date(dates.toDate)]}
          format="yyyy/MM/dd"
          placeholder="YYYY/MM/DD-YYYY/MM/DD"
          size="lg"
          ranges={[]}
          onChange={(data) => {
            if (data) {
              const [fromDate, toDate] = data;
              setIsChanged((prev) => ({ ...prev, date: true }));
              setDateRangePicker(0);
              setDates({
                fromDate: moment(fromDate).format(),
                toDate: moment(toDate).format(),
              });
            }
          }}
          onClick={(e: any) => {
            e.preventDefault();
          }}
        />
      </CalendarWrapper>

      <Wrapper>
        <List>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.LastDays30);
            }}
            clicked={dateRangePicker === RangeType.LastDays30}
          >
            {t("ChartFilter.Last30Days")}
            <RadioButton
              type="radio"
              id="LastThirty"
              name="chartViewType"
              checked={dateRangePicker === RangeType.LastDays30}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => handleDateRangeUpdate(RangeType.LastDays90)}
            clicked={dateRangePicker === RangeType.LastDays90}
          >
            {t("ChartFilter.Last90Days")}
            <RadioButton
              type="radio"
              id="LastNinety"
              name="chartViewType"
              checked={dateRangePicker === RangeType.LastDays90}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => handleDateRangeUpdate(RangeType.LastDays120)}
            clicked={dateRangePicker === RangeType.LastDays120}
          >
            {t("ChartFilter.Last120Days")}
            <RadioButton
              type="radio"
              id="LastHundredTwenty"
              name="chartViewType"
              checked={dateRangePicker === RangeType.LastDays120}
              onChange={() => {}}
            />
          </Item>
        </List>
        <List>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.CurrentMonth);
            }}
            clicked={dateRangePicker === RangeType.CurrentMonth}
          >
            {t("ChartFilter.CurrentMonth")}
            <RadioButton
              type="radio"
              id="currentMonth"
              name="chartViewType"
              checked={dateRangePicker === RangeType.CurrentMonth}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.CurrentQuarter);
            }}
            clicked={dateRangePicker === RangeType.CurrentQuarter}
          >
            {t("ChartFilter.CurrentQuarter")}
            <RadioButton
              type="radio"
              id="currentQuarter"
              name="chartViewType"
              checked={dateRangePicker === RangeType.CurrentQuarter}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.CurrentYear);
            }}
            clicked={dateRangePicker === RangeType.CurrentYear}
          >
            {t("ChartFilter.CurrentYear")}
            <RadioButton
              type="radio"
              id="currentYear"
              name="chartViewType"
              checked={dateRangePicker === RangeType.CurrentYear}
              onChange={() => {}}
            />
          </Item>
        </List>
        <List>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PrevMonth);
            }}
            clicked={dateRangePicker === RangeType.PrevMonth}
          >
            {t("ChartFilter.PreviousMonth")}
            <RadioButton
              type="radio"
              id="previousMonth"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PrevMonth}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PrevQuarter);
            }}
            clicked={dateRangePicker === RangeType.PrevQuarter}
          >
            {t("ChartFilter.PreviousQuarter")}
            <RadioButton
              type="radio"
              id="previousQuarter"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PrevQuarter}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PrevYear);
            }}
            clicked={dateRangePicker === RangeType.PrevYear}
          >
            {t("ChartFilter.PreviousYear")}
            <RadioButton
              type="radio"
              id="previousYear"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PrevYear}
              onChange={() => {}}
            />
          </Item>
        </List>
        <List>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PastTwoYear);
            }}
            clicked={dateRangePicker === RangeType.PastTwoYear}
          >
            {t("ChartFilter.PastTwoYear")}
            <RadioButton
              type="radio"
              id="pastTwoYear"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PastTwoYear}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PastThreeYear);
            }}
            clicked={dateRangePicker === RangeType.PastThreeYear}
          >
            {t("ChartFilter.PastThreeYear")}
            <RadioButton
              type="radio"
              id="pastThreeYear"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PastThreeYear}
              onChange={() => {}}
            />
          </Item>
          <Item
            onClick={() => {
              handleDateRangeUpdate(RangeType.PastFourYear);
            }}
            clicked={dateRangePicker === RangeType.PastFourYear}
          >
            {t("ChartFilter.PastFourYear")}
            <RadioButton
              type="radio"
              id="pastFourYear"
              name="chartViewType"
              checked={dateRangePicker === RangeType.PastFourYear}
              onChange={() => {}}
            />
          </Item>
        </List>
      </Wrapper>
      <BottomWrapper>
        <Button
          isDisabled={!(isChanged.date || isChanged.range)}
          title={t("ChartFilter.ButtonTitle")}
          onClick={() => {
            if (isChanged.date) {
              updateDate(dates.fromDate, dates.toDate);
            }
            if (isChanged.range) {
              handleDateRange(dateRangePicker);
            }
            handleClick(BottomSheetType.FILTER);
          }}
        />
      </BottomWrapper>
    </>
  );
};

export default DateRangeMobile;
