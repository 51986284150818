import React from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { PageURL } from "../../../constants/router";
import { Item, Wrapper } from "./AdministrationMobile.style";
import { isMobile } from "react-device-detect";

interface AdministrationMobileProps {
  handleClose: () => void;
}
const AdministrationMobile = ({ handleClose }: AdministrationMobileProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Wrapper>
        <Item
          isMobile={isMobile}
          onClick={() => {
            handleClose();
            navigate(PageURL.UserManagement.path);
          }}
        >
          {t("AdministrationMobile.UserManagement")}
        </Item>
        <Item
          isMobile={isMobile}
          onClick={() => {
            handleClose();
            navigate(PageURL.CompanyInfo.path);
          }}
        >
          {t("AdministrationMobile.CompanyInfo")}
        </Item>
      </Wrapper>
    </>
  );
};

export default AdministrationMobile;
