import React from "react";
import CustomIcons from "./IconSvgs";

const colorMap: Record<string, { color: any }> = {
  primary: { color: "#5154C0" },
  default: { color: "#383838" },
  lightBlack: { color: "#454545" },
  danger: { color: "#EC1818" },
  white: { color: "#fff" },
  disabled: { color: "#BDBDBD " },
  grey: { color: "#989898" },
};

export type IconNameOptions = keyof typeof CustomIcons;

export interface CustomIconProps {
  iconName: IconNameOptions;
  width?: number;
  height?: number;
  fill?:
    | "primary"
    | "default"
    | "danger"
    | "white"
    | "disabled"
    | "grey"
    | "lightBlack";
}

const Icon = ({
  iconName,
  width = 24,
  height = 24,
  fill = "default",
}: CustomIconProps) => {
  const IconComponent = CustomIcons[iconName];
  const { color } = colorMap[fill];
  if (!IconComponent) {
    return null;
  }

  return <IconComponent fill={color} width={width} height={height} />;
};

export default Icon;
