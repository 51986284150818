import React, { useEffect, useRef, useState } from "react";
import { Content, ToolTipSvgWrapper, Wrapper } from "./Tooltip.style";

interface TooltipProps {
  text: string;
  click?: () => void;
}

const Tooltip = ({ text, click = () => {} }: TooltipProps) => {
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(320);
  const handleChange = (e: any) => {
    e.stopPropagation();
    setShow((prev) => !prev);
  };
  const toolTipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toolTipRef?.current &&
        !toolTipRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    if (toolTipRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toolTipRef]);

  useEffect(() => {
    const length = text.length;
    if (length < 35) {
      setWidth(200);
    } else {
      setWidth(320);
    }
  }, [text]);

  return (
    <Wrapper ref={toolTipRef}>
      <ToolTipSvgWrapper
        onClick={(event) => {
          handleChange(event);
          if (click) {
            click();
          }
        }}
      />
      {show && <Content width={width}>{text}</Content>}
    </Wrapper>
  );
};

export default Tooltip;
