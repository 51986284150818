import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    position: fixed;
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.grey300};
    z-index: 1000;
  `
);

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const DropDownWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    cursor: pointer;
    padding: 8px;
    gap: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    background-color: ${theme.colors.grey300};
  `
);
export const Content = styled.p(
  ({ theme }) => css`
    margin: 0;
    color: ${theme.colors.black005};
    font-size: 1.4rem;
  `
);

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ArrowWrapper = styled.div`
  height: 24px;
  transition: transform 0.3s ease-in-out;

  &.rotate {
    transform: rotate(180deg);
  }
`;
export const BurgerBarWrapper = styled.div`
  padding-left: 8px;
  height: 24px;
`;
