import { colors } from "../constants/color";

import { fonts } from "../constants/fonts";
import { BREAKPOINTS, spaces } from "../constants/spacint";
import { ITheme } from "../types/theme";

function createTheme(isMobile: boolean): ITheme {
  return {
    isMobile,
    spaces,
    colors,
    fonts,
    breakpoints: BREAKPOINTS,
  };
}

export { createTheme };
