import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface WrapperProps {
  isSelected: boolean;
  isMobile: boolean;
}

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, isSelected, isMobile }) => css`
    position: relative;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${isSelected ? theme.colors.primaryShade100 : "inherit"};
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${isSelected
          ? theme.colors.primaryShade100
          : theme.colors.grey300};
      }
    `}
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: ${isMobile ? "12px 16px" : "16px"};
    }
  `
);

export const Title = styled.span<{
  active: boolean;
  isSelected: boolean;
  isMobile: boolean;
}>(
  ({ theme, active, isMobile, isSelected }) => css`
    color: ${isSelected ? theme.colors.primary600 : "inherit"};
    font-size: 14px;
    padding-left: ${active ? "12px" : "0"};
    overflow: hidden;
    width: ${active ? "219px" : "0"};
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    ${!isMobile &&
    css`
      @media (max-width: ${theme.breakpoints.xl}) {
        width: ${active ? "191px" : "0"};
        font-size: 13px;
      }
    `}
  `
);

export const HoverItem = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 75px;
    z-index: 10;
    padding: 6px 12px;
    border-radius: 8px;
    width: max-content;
    background: ${theme.colors.black005};
    color: ${theme.colors.white};
    font-size: 12px;

    &::before {
      content: "";
      position: absolute;
      left: -6px;
      top: 45%;
      transform: translateY(-50%);
      border-width: 6px;
      border-style: solid;
      border-color: ${theme.colors.black005};
      rotate: 45deg;
    }
  `
);
