import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    transform: translate(0, 100%);
    text-align: center;
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const IconWrapper = styled.div(
  ({ theme }) => css`
    min-width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.success600};
  `
);

export const Title = styled.h5(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  `
);

export const SubTitle = styled.h6(
  ({ theme }) => css`
    color: ${theme.colors.textSecondary};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    ${theme.fonts.HelveticaLight}
  `
);

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  margin-top: 24px;
  gap: 8px;
`;
