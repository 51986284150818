import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface Props {
  isClicked: boolean;
}
export const Wrapper = styled.div<Props>(
  ({ isClicked, theme }) => css`
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    color: ${isClicked ? theme.colors.primary : theme.colors.grey};
    border-bottom: ${isClicked && "2px solid " + theme.colors.primary};
    ${theme.fonts.AvenirDemi}
    font-size: 14px;
  `
);
