import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Container, Item, Wrapper } from "./CompensationReports.style";
import EmployeeByStaffAndPeriod from "./employStaffAndPeriod/EmployStaffAndPeriod";
import EmployeeRemuneration from "./employRemuneration/EmployRemuneration";

const enum CompensationReportsType {
  EMPLOYEE_BY_STAFF_AND_PERIOD = "EMPLOYEE_BY_STAFF_AND_PERIOD",
  EMPLOYEE_REMUNERATION = "EMPLOYEE_REMUNERATION",
}

const CompensationReports = () => {
  const [openTab, setOpenTab] = useState<CompensationReportsType>(
    CompensationReportsType.EMPLOYEE_BY_STAFF_AND_PERIOD
  );

  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Item
          onClick={() =>
            setOpenTab(CompensationReportsType.EMPLOYEE_BY_STAFF_AND_PERIOD)
          }
          clicked={
            openTab === CompensationReportsType.EMPLOYEE_BY_STAFF_AND_PERIOD
          }
        >
          {t("CompensationReports.EmployeeByStaffAndPeriod")}
        </Item>
        <Item
          onClick={() =>
            setOpenTab(CompensationReportsType.EMPLOYEE_REMUNERATION)
          }
          clicked={openTab === CompensationReportsType.EMPLOYEE_REMUNERATION}
        >
          {t("CompensationReports.EmployeeRemuneration")}
        </Item>
      </Wrapper>
      <Container>
        {openTab === CompensationReportsType.EMPLOYEE_BY_STAFF_AND_PERIOD && (
          <EmployeeByStaffAndPeriod />
        )}
        {openTab === CompensationReportsType.EMPLOYEE_REMUNERATION && (
          <EmployeeRemuneration />
        )}
      </Container>
    </>
  );
};

export default CompensationReports;
