import React, { ReactNode } from "react";
import { Footer, Header, Overlay, Title, Wrapper } from "./Modal.style";

import ButtonIcon from "../../shared/buttonIcon/ButtonIcon";

interface ModalProps {
  size: "medium" | "small";
  modalTitle: string;
  children: ReactNode;
  footer: ReactNode;
  close: () => void;
}
const Modal = ({ size, modalTitle, children, footer, close }: ModalProps) => {
  return (
    <>
      <Overlay onClick={close} />
      <Wrapper size={size}>
        <Header size={size}>
          <Title>{modalTitle}</Title>
          <ButtonIcon iconName="closeIcon" handleClick={close} />
        </Header>
        {children}
        <Footer>{footer}</Footer>
      </Wrapper>
    </>
  );
};

export default Modal;
