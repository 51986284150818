import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ReactComponent as OpenEye } from "../../../assets/svgs/openEye.svg";
import { ReactComponent as ClosedEye } from "../../../assets/svgs/closedEye.svg";
import { ReactComponent as Danger } from "../../../assets/svgs/danger.svg";
import { ITheme } from "../../../types/theme";

interface LabelProps {
  invalid: boolean;
  isFocus: boolean;
  hasValue: boolean;
}

interface WrapperProps {
  isFocus: boolean;
  invalid: boolean;
  hasValue: boolean;
}

type Props = {
  invalid: boolean;
  disabled: boolean;
  isFocused: boolean;
  hasValue: boolean;
};

const borderColor = (
  isFocus: boolean,
  invalid: boolean,
  hasValue: boolean,
  theme: ITheme
) => {
  if (invalid) {
    return theme.colors.dangerBorder;
  } else if (isFocus) {
    return theme.colors.primary;
  } else if (hasValue) {
    return theme.colors.greyBorderDark;
  } else if (!hasValue) {
    return theme.colors.greyBorder;
  } else {
    return null;
  }
};

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, isFocus, invalid, hasValue }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${theme.colors.greyBorder};
    border-radius: 15px;
    padding-right: 13px;
    background-color: ${invalid
      ? theme.colors.dangerBackground
      : theme.colors.lightGrey};

    border: 1px solid ${borderColor(isFocus, invalid, hasValue, theme)};
  `
);

export const Label = styled.label<LabelProps>(
  ({ theme, isFocus, invalid, hasValue }) => css`
    position: absolute;
    padding: 0 4px;
    top: 10px;
    left: 15px;
    z-index: 1;
    color: ${invalid
      ? theme.colors.dangerMain
      : isFocus
      ? theme.colors.primary
      : theme.colors.grey};
    cursor: text;
    transition: all 0.2s;
    ${(hasValue || isFocus) &&
    css`
      font-size: 13px;
      top: -11px;
    `}

    &::before {
      box-sizing: border-box;
      content: "";
      display: block;
      position: absolute;
      pointer-events: none;
      top: 11px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: inherit;
      margin: calc(1 * -1px);
      background-color: ${invalid
        ? theme.colors.dangerBackground
        : isFocus
        ? theme.colors.lightGrey
        : theme.colors.lightGrey};
    }
  `
);

export const CustomInput = styled.input<Props>(
  ({ theme, disabled, invalid, isFocused, hasValue }) => css`
    font-size: 14px;
    width: 100%;
    padding: 12px 15px;
    border-radius: 15px;
    letter-spacing: 1px;
    outline: none;
    z-index: 0;
    background-color: ${invalid
      ? theme.colors.dangerBackground
      : theme.colors.lightGrey};
    border: none;
    color: ${invalid ? theme.colors.dangerMain : theme.colors.black};
    ${disabled &&
    css`
      color: ${theme.colors.grey};
      cursor: not-allowed;
    `}
  `
);

export const OpenEyeIcon = styled(OpenEye)`
  cursor: pointer;
`;
export const ClosedEyeIcon = styled(ClosedEye)`
  cursor: pointer;
`;

export const ErrorMassage = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.dangerMain};
    ${theme.fonts.AvenirMedium}
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-top: 12px;
  `
);
export const ErrorIcon = styled(Danger)`
  margin-right: 6px;
`;
