import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../store";

import {
  CustomTable,
  ExportExcelWrapper,
  LoaderWrapper,
  TableRow,
  TableRowItem,
  TableRowItemId,
  TableRowItemName,
} from "./Stream.style";
import Loader from "../../../../../components/shared/loader/Loader";
import TableHeader from "../../../../../components/shared/tableHeader/TableHeader";
import ReportFilter from "../../../../../components/desktop/reportFilter/ReportFilter";
import { formatNumber } from "../../../../../helpers/dashbordHelper";
import moment from "moment";
import Button from "../../../../../components/shared/button/Button";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../../../helpers/helper";
import {
  getClientStreamFileUrl,
  getRevenueStream,
} from "../../../../../store/report/reportAction";

const Stream = () => {
  const dispatch = useAppDispatch();

  const [fileDownloadLoading, setFileDownloadLoading] = useState(false);
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState({
    fromDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });

  const loader = useAppSelector((state) => state.dashboard.loading);

  const revenueStream = useAppSelector((state) => state.report.revenue.stream);

  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  useEffect(() => {
    dispatch(getRevenueStream(dateRange));
  }, [dispatch, dateRange, selectedCompany]);

  const submit = ({
    fromDate,
    toDate,
  }: {
    fromDate?: string;
    toDate?: string;
  }) => {
    if (fromDate && toDate) {
      setDateRange({
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    }
  };

  const handleFileDownload = () => {
    setFileDownloadLoading(true);
    dispatch(getClientStreamFileUrl({ ...dateRange }))
      .then(({ payload }) => {
        downloadFile(payload as string);
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setFileDownloadLoading(false);
        }, 500);
      });
  };

  const headerList = ["RevenueStream.AccountId", "RevenueStream.AccountName"];
  return (
    <>
      <ReportFilter dates={dateRange} handleSubmit={submit} />
      <ExportExcelWrapper>
        <Button
          isLoading={fileDownloadLoading}
          onClick={handleFileDownload}
          title={t("Export.FileExport")}
        ></Button>
      </ExportExcelWrapper>
      {loader === "pending" ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <CustomTable>
            <TableHeader
              headerNames={[...headerList, ...revenueStream.labels]}
            />
            <tbody>
              {revenueStream.data.map((item, index) => (
                <TableRow key={index}>
                  <TableRowItemId>{item.clientAccountId}</TableRowItemId>
                  <TableRowItemName>{item.clientAccountName}</TableRowItemName>
                  {item.amounts.map((el, elIndex) => (
                    <TableRowItem key={elIndex}>
                      {formatNumber(el)}
                    </TableRowItem>
                  ))}
                </TableRow>
              ))}
            </tbody>
          </CustomTable>
        </>
      )}
    </>
  );
};

export default Stream;
