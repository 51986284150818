import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AppLayout from "../layout/app/App.layout";
import AuthLayout from "../layout/auth/Auth.layout";
import Login from "../pages/auth/login/Login";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import OpenEmail from "../pages/auth/openEmail/OpenEmail";
import CreatePassword from "../pages/auth/createPassword/CreatePassword";
import { AuthURL, PageURL } from "../constants/router";
import FAQ from "../pages/app/faq/FAQ";

import Revenue from "../pages/app/dashboard/revenue/Revenue";
import Expenses from "../pages/app/dashboard/expenses/Expenses";
import ProfitLoss from "../pages/app/dashboard/profitLoss/ProfitLoss";
import Reportal from "../pages/app/reportal/Reportal";
import CashFlow from "../pages/app/dashboard/cashFlow/CashFlow";
import RevenueServices from "../pages/app/dashboard/revenueServices/RevenueServices";
import Reports from "../pages/app/reports/Reports";
import CompanyTabs from "../pages/app/companyTabs/CompanyTabs";

import ImportData from "../pages/app/importData/ImportData";
import Mapping from "../pages/app/mapping/Mapping";
import UpdatePersonalDetail from "../pages/app/personalInfo/updatePersonalDetail/UpdatePersonalDetail";
import UpdatePassword from "../pages/app/personalInfo/updatePassword/UpdatePassword";
import UserManagement from "../pages/app/userManagement/UserManagement";
import Market from "../pages/app/market/Market";
import CreateCompany from "../pages/app/createCompany/CraeteCompany";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={AuthURL.Root.path} element={<AuthLayout />}>
          <Route index element={<Navigate replace to={AuthURL.Login.path} />} />
          <Route path={AuthURL.Login.path} element={<Login />} />
          <Route
            path={AuthURL.ForgotPassword.path}
            element={<ResetPassword />}
          />
          <Route path={AuthURL.CheckEmail.path} element={<OpenEmail />} />
          <Route
            path={AuthURL.ResetPassword.path}
            element={<CreatePassword />}
          />
        </Route>


        <Route path={PageURL.Root.path} element={<AppLayout />}>
          <Route
            index
            element={<Navigate replace to={PageURL.Revenue.path} />}
          />
          <Route path={PageURL.ImportData.path} element={<ImportData />} />
          <Route path={PageURL.Mapping.path} element={<Mapping />} />

          <Route path={PageURL.FAQ.path} element={<FAQ />} />
          <Route
            path={PageURL.UserManagement.path}
            element={<UserManagement />}
          />
          <Route path={PageURL.CompanyInfo.path} element={<CompanyTabs />} />

          <Route
            path={PageURL.PersonalInfo.path}
            element={<UpdatePersonalDetail />}
          />
          <Route
            path={PageURL.UpdatePassword.path}
            element={<UpdatePassword />}
          />
          <Route
            path={PageURL.CreateCompany.path}
            element={<CreateCompany />}
          />

          <Route path={PageURL.Revenue.path} element={<Revenue />} />
          <Route path={PageURL.Expenses.path} element={<Expenses />} />
          <Route path={PageURL.ProfitLoss.path} element={<ProfitLoss />} />
          <Route path={PageURL.Reportal.path} element={<Reportal />} />
          <Route path={PageURL.CashFlow.path} element={<CashFlow />} />
          <Route
            path={PageURL.RevenueServices.path}
            element={<RevenueServices />}
          />
          <Route path={PageURL.Reports.path} element={<Reports />} />

          <Route path={PageURL.Market.path} element={<Market />} />

          <Route
            path={PageURL.PermissionDenied.path}
            element={<div>PermissionDenied</div>}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate replace to={PageURL.notFound.path} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
