import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import CompanyInfoForm, {
  CompanyInfoFormValues,
} from "../../../../components/desktop/forms/companyInformation/CompanyInformationForm";
import { useAppDispatch, useAppSelector } from "../../../../store";

import {
  getCompanyInfo,
  updateCompany,
} from "../../../../store/company/companyAction";
import { ActionType } from "../../../../store/type";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../store/user/userSlice";
import { SubTitle, Title, Wrapper } from "./CompanyInfo.style";

const CompanyInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<CompanyInfoFormValues>>();

  const companyInfo = useAppSelector((state) => state.company.company);

  const companyForms = useAppSelector((state) => state.user.companyForms);
  const selectedCompany = useAppSelector((state) => state.user.selectedCompany);

  const companyCategories = useAppSelector(
    (state) => state.user.companyCategories
  );

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCompanyInfo());
  }, [dispatch, selectedCompany]);

  const handleSave = (value: CompanyInfoFormValues) => {
    setLoading(true);
    const data = {
      ...value,
      companyCategoryId: +value.companyCategoryId,
      companyFormId: +value.companyFormId,
      mobile: value.mobile ?? "",
      mobileCountryCode: companyInfo?.mobileCountryCode ?? null,
    };

    dispatch(updateCompany(data)).then(({ error }: ActionType) => {
      if (error?.message) {
        dispatch(showErrorMessage(error.message));
      } else {
        if (formRef?.current) {
          formRef.current.resetForm({ values: data });
        }
        dispatch(showSuccessMessage(true));
      }
      setLoading(false);
    });
  };
  return (
    <Wrapper>
      <Title>{t("CompanyInfo.Title")}</Title>
      <SubTitle>{t("CompanyInfo.SubTitle")}</SubTitle>
      <CompanyInfoForm
        formRef={formRef}
        companyForms={companyForms}
        companyCategories={companyCategories}
        companyInfo={companyInfo}
        handleSubmit={handleSave}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default CompanyInfo;
