import React from "react";
import {
  Description,
  ButtonWrapper,
  Title,
  AlertWrapper,
} from "./ResetPasswordForm.style";
import Input from "../../../shared/input/Input";
import Button from "../../../shared/button/Button";

import { Formik, Form, Field } from "formik";

import { useTranslation } from "react-i18next";
import TextButton from "../../../shared/textButton/TextButton";
import Alert from "../../../shared/alert/Alert";
import { initialValues, ResetPasswordSchema } from "./schema";

interface ResetPasswordFormProps {
  isLoading: boolean;
  errorMessage: string;
  handleSubmit: (values: any) => void;
  handleNavigate: () => void;
}

const ResetPasswordForm = ({
  isLoading,
  errorMessage,
  handleSubmit,
  handleNavigate,
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Title>{t("ResetPassword.Title")}</Title>
      <Description>{t("ResetPassword.Description")}</Description>
      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Field
              name="email"
              label={t("ResetPassword.EmailPlaceholder")}
              component={Input}
            />
            <ButtonWrapper>
              <TextButton
                title={t("ResetPassword.TextButtonContent")}
                isDisable={false}
                handleClick={handleNavigate}
              />
              <Button
                title={t("ResetPassword.ButtonText")}
                type="submit"
                isDisabled={false}
                isLoading={isLoading}
              />
            </ButtonWrapper>
            {errorMessage && (
              <AlertWrapper>
                <Alert status="Error" message={errorMessage} />
              </AlertWrapper>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
