import { AccountMappingTypeGroupTypes } from "../store/mapping/mapping.types";

export const filterDataByName = (
  accountMappingTypeGroupTypes: Array<AccountMappingTypeGroupTypes>,
  filterName: string
) => {
  const filteredTypes = accountMappingTypeGroupTypes
    .map((groupType) => ({
      ...groupType,
      accountMappingTypes: groupType.accountMappingTypes
        .map((mappingType) => ({
          ...mappingType,
          accountMappingGroups: mappingType.accountMappingGroups
            .filter(
              (group) =>
                group.name.includes(filterName) ||
                group.accountMappings.some(
                  (mapping) =>
                    mapping.name
                      .toUpperCase()
                      .includes(filterName.toUpperCase()) ||
                    mapping.accountId.includes(filterName)
                )
            )
            .map((group) => ({
              ...group,
              accountMappings: group.accountMappings.filter(
                (mapping) =>
                  mapping.name
                    .toUpperCase()
                    .includes(filterName.toUpperCase()) ||
                  mapping.accountId.includes(filterName)
              ),
            })),
        }))
        .filter((mappingType) => mappingType.accountMappingGroups.length > 0),
    }))
    .filter((groupType) => groupType.accountMappingTypes.length > 0);

  return filteredTypes;
};
