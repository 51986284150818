import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Title = styled.h4(
  ({ theme }) => css`
    font-size: 16px;
    ${theme.fonts.AvenirDemi};
  `
);
export const Description = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium};
    color: ${theme.colors.grey};
    font-size: 12px;
    margin: 10px 0 50px;
  `
);

export const Note = styled.p(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirMedium};
    text-align: center;
  `
);
export const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
