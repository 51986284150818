import { ISpaces } from "../types/theme";

export const spaces: ISpaces = {
  small: "0.5em",
  medium: "1em",
  large: "2em",
};

export const BREAKPOINTS = {
  xs: "376px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1366px",
};
