import React from "react";
import { Wrapper } from "./Tab.style";

interface TabProps {
  title: string;
  isClicked: boolean;
  handleClick: () => void;
}
const Tab = ({ title, isClicked, handleClick }: TabProps) => {
  return (
    <>
      <Wrapper isClicked={isClicked} onClick={handleClick}>
        {title}
      </Wrapper>
    </>
  );
};

export default Tab;
