import { ChartGroupTypes, PeriodType } from "../../enums/enum";
export type RevenueData = {
  accountId: string;
  accountName: string;
  period: string;
  sumAmount: number;
  accountMappingGroupOrdering: number;
  accountMappingTypeOrdering: number;
  accountMappingOrdering: number;
  companyBranchId: number | null;
  companyBranchName: string | null;
  companyBusinessLineId: number | null;
  companyBusinessLineName: string | null;
  companyBranchOrdering: null | number;
  companyBusinessLineOrdering: null | number;
  mappingGroupId: number;
  mappingGroupName: string;
};
export type Revenue = {
  data: Array<RevenueData>;
  lastUpdateTime: string;
};

export type Expenses = {
  data: Array<RevenueData>;
  lastUpdateTime: string;
};
export type ExpensesData = {
  accountId: string;
  accountName: string;
  period: string;
  sumAmount: number;
  accountMappingGroupOrdering: number;
  accountMappingTypeOrdering: number;
  accountMappingOrdering: number;
  companyBranchId: number | null;
  companyBranchName: string | null;
  companyBusinessLineId: number | null;
  companyBusinessLineName: string | null;
  companyBranchOrdering: null | number;
  companyBusinessLineOrdering: null | number;
  mappingGroupId: number;
  mappingGroupName: string;
};

export type ProfitLostResponse = {
  data: Array<ProfitLostResponseData>;
  lastUpdateTime: string;
};
export type ProfitLostResponseData = {
  period: string;
  totalExpense: number;
  totalProfit: number;
};

export type ProfitLostData = {
  period: string;
  ["Total Income"]: number;
  ["Total Expense"]: number;
};

export type CashFlow = {
  period: string;
  cashFlowCategory: CashFlowCategoryType;
  cashFlowDirection: CashFlowDirectionType;
  amount: number;
};

export enum CashFlowDirectionType {
  Received = 1,
  Paid = 2,
}

export enum CashFlowCategoryType {
  None = 0,
  Operating = 1,
  Investment = 2,
  Financing = 3,
}
export type ReportalReportItem = {
  amount: number;
  rePortalMappingId: number;
  year: number;
};

export type Filter = {
  periodType: PeriodType;
  fromDate: string;
  toDate: string;
};

export type FrontSideFilter = {
  group: ChartGroupTypes;
  lines: Array<{ value: string; label: string }>;
  branches: Array<{ value: string; label: string }>;
  groupNames: Array<{ value: string; label: string }>;
};

export type RevenueServicesItems = {
  birthDate: string;
  citizenship: string;
  companyId: number;
  createDate: string;
  createdAt: string;
  fullName: string;
  gender: number;
  genderTxt: string;
  id: number;
  isCorrect: boolean;
  isCorrectTxt: string;
  isCorrectedIcon: string;
  isForeigner: boolean;
  lastChangeDate: string;
  orgFullName: string;
  orgTin: string;
  phone: string;
  providerId: number;
  status: number;
  statusTxt: string;
  tin: string;
  updatedAt: string;
  workType: number;
  workTypeText: string;
};

export type RevenueServices = {
  count: number;
  items: Array<RevenueServicesItems>;
  page: number;
  pageSize: number;
};

export type PeriodFilterType = {
  fromDate: string;
  toDate: string;
};

export type StreamFilterType = {
  fromDate: string;
  toDate: string;
};

export type MarketDataInfo = {
  name: string;
  amount: number;
  isConsolidated: boolean;
  isSum: boolean;
};

export type MarketData = {
  identificationCode: number | null;
  name: string | null;
  companyCategoryId: number | null;
  companyFormId: number | null;
  data: Record<number, Array<MarketDataInfo>> | null | undefined;
};
