import { useAppDispatch, useAppSelector } from "../../../store";
import {
  chooseCompany,
  updateDefaultCompany,
} from "../../../store/user/userAction";
import ChooseAccountForm from "../../../components/desktop/forms/choseAccountForm/ChoseAccountForm";
import { selectCompany } from "../../../store/user/userSlice";
import { useState } from "react";
import { resetLineAndBranch } from "../../../store/dashboard/dashboardSlice";

const ChoseAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const companies = useAppSelector((state) => state.user.user?.companies) || [];

  const handleSubmit = (
    defaultCompanyId: number | null,
    selectedCompanyId: number | null
  ) => {
    setIsLoading(true);
    dispatch(chooseCompany({ CompanyId: selectedCompanyId || 0 })).then(() => {
      dispatch(selectCompany({ selectedCompanyId }));
      dispatch(updateDefaultCompany({ defaultCompanyId }));
      dispatch(resetLineAndBranch());
      setIsLoading(false);
    });
  };

  return (
    <ChooseAccountForm
      loading={isLoading}
      onSubmit={handleSubmit}
      companies={companies}
    />
  );
};

export default ChoseAccount;
