import React, { useEffect, useState } from "react";
import {
  Circle,
  HeaderWrapper,
  Item,
  ItemWrapper,
  Line,
  SubTitle,
  SubTitleUnderLine,
  Title,
  Wrapper,
} from "./UnmappedModalLoading.style";

const UnmappedModalLoading = () => {
  const [numberArray, setNumberArray] = useState<number[]>([]);

  useEffect(() => {
    const tempArray = [];
    for (let index = 0; index < 10; index++) {
      tempArray.push(Math.floor(Math.random() * (450 - 100 + 1)) + 100);
    }
    setNumberArray(tempArray);
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Circle />
        <Title />
        <SubTitle />
        <SubTitleUnderLine />
      </HeaderWrapper>
      {numberArray.map((number, index) => (
        <ItemWrapper key={index}>
          <Item>
            <Line width={number} />
          </Item>
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

export default UnmappedModalLoading;
