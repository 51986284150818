import { Color } from "../enums/color";

export const colors: { [key in Color]: string } = {
  [Color.PrimaryHover]: "#8488fc",
  [Color.PrimaryLight]: "#e8e9ff",
  [Color.DangerBorder]: "#D80027",
  [Color.DangerMain]: "#D80027",
  [Color.DangerBackgroundLight]: "#fcdee0",
  [Color.DangerBackground]: "#fce8ea",
  [Color.Black]: "#000000",

  [Color.Grey]: "#9298A6",
  [Color.GreyBorderDark]: "#D9DBE9",
  [Color.GreyBorder]: "#f2f3f6",
  [Color.LightGrey]: "#F7F9FC",
  [Color.Yellow]: "#FFB800",
  [Color.YellowBackground]: "#fff8eb ",
  [Color.Green]: "#00BC4B",
  [Color.GreenBackground]: "#edfaed",
  [Color.ModalBackground]: "#00000033",
  [Color.DarkBlue]: "#181B33",
  [Color.DarkBlue1]: "#292F4B",
  [Color.DarkBlue2]: "#456BFF",
  [Color.BlueBackground]: "#ecf0ff",
  [Color.Orange]: " #e36414",
  [Color.OrangeBackground]: "#fce8df",
  //
  [Color.Bg002]: "#F7F7F7",
  [Color.Black005]: "#383838",
  [Color.White]: "#FFF",
  [Color.Black900]: "#0f0f0f",
  // DarkBlue
  [Color.DarkBlue001]: "#181B33",
  [Color.DarkBlue002]: "",
  [Color.DarkBlue003]: "",
  [Color.DarkBlue004]: "",
  [Color.DarkBlue005]: "",
  [Color.DarkBlue006]: "",
  // negative
  [Color.Negative100]: "#FFE4E5",
  [Color.Negative200]: "#FECDD2",
  [Color.Negative600]: "#DF1C41",
  [Color.Negative700]: "#BF1137",
  // negativeShades
  [Color.NegativeShade50]: "#DF1C4114",
  [Color.NegativeShade100]: "#DF1C411F",
  // warning
  [Color.WarningShade300]: "#F2AE404D",
  // success
  [Color.Success600]: "#1A9F41",
  // grey
  [Color.Grey003]: "#EBEBEB",
  [Color.Grey50]: "#FAFAFA",
  [Color.Grey100]: "#EFEFEF",
  [Color.Grey200]: "#E6E6E6",
  [Color.Grey300]: "#DCDCDC",
  [Color.Grey400]: "#D2D2D2",
  [Color.Grey500]: "#BDBDBD",
  [Color.Grey600]: "#989898",
  [Color.Grey700]: "#7C7C7C",
  [Color.Grey800]: "",
  [Color.Grey900]: "",
  // grey shades
  [Color.GreyShade50]: "#65656514",
  [Color.GreyShade100]: "#6565651F",
  [Color.GreyShade200]: "#65656533",
  [Color.GreyShade300]: "#6565654D",
  // textPrimary
  [Color.TextPrimary]: "#242424",
  // textSecondary
  [Color.TextSecondary]: "#888",
  // primary
  [Color.Primary]: "#656af9",
  [Color.Primary50]: "",
  [Color.Primary100]: "#e3e6f6",
  [Color.Primary200]: "#CDD3F0",
  [Color.Primary300]: "",
  [Color.Primary400]: "",
  [Color.Primary500]: "",
  [Color.Primary600]: "#5154C0",
  [Color.Primary700]: "",
  [Color.Primary800]: "#423E8F",
  [Color.Primary900]: "",
  // primaryShade
  [Color.PrimaryShade50]: "",
  [Color.PrimaryShade100]: "#5154C01F",
  [Color.PrimaryShade200]: "",
  [Color.PrimaryShade300]: "",
  [Color.PrimaryShade400]: "",
  [Color.PrimaryShade500]: "",
  [Color.PrimaryShade600]: "",
  [Color.PrimaryShade700]: "",
  [Color.PrimaryShade800]: "",
};

export const chartColors = [
  "#1C1678",
  "#DD5746",
  "#008DD8",
  "#D60060",
  "#009278",
  "#FFC554",
  "#FF5356",
  "#75F88F",
  "#FF75D5",
  "#A18CF8",
  "#5ECCFA",
  "#FF2581",
  "#760DB1",
  "#00C4C9",
  "#3E62E7",
  "#580EA7",
  "#003FE7",
  "#FF8026",
  "#009396",
  "#5C12D9",
  "#FF5E92",
  "#B1D0FF",
  "#99DDCC",
  "#FFD402",
  "#00A4D8",
  "#749101",
  "#FF9505",
  "#40C9A2",
  "#B05CDE",
  "#FE6F5B",
  "#21618C",
  "#A3D86A",
  "#FCB5C1",
  "#FF7A45",
  "#6699CC",
  "#7F3DFF",
  "#42C3E9",
  "#FF3380",
  "#96394B",
  "#7B61FF",
  "#E285FF",
  "#6CEFF1",
  "#FFB940",
  "#223344",
  "#B2FF99",
  "#FF3B30",
  "#FFB5E8",
  "#55C1B2",
  "#8472FF",
  "#FFCC80",
  "#FFCC00",
  "#008888",
  "#6C4F74",
  "#8D4002",
  "#FFDF00",
  "#B02A30",
  "#C3F73A",
  "#7C8A3E",
  "#A79F94",
  "#96ADC8",
  "#FF6F91",
  "#C5D7BD",
  "#5A6BFF",
  "#A4C2A1",
  "#903749",
  "#F2D7A0",
  "#B3C100",
  "#6B5B95",
  "#FFBD69",
  "#E44D93",
  "#40916C",
  "#FF5733",
  "#33FF77",
  "#FFB3BA",
  "#5271FF",
  "#E43F5A",
  "#6A0572",
  "#FF847C",
  "#6D435A",
  "#D69E2E",
  "#DA4167",
  "#243A73",
  "#A13E3F",
  "#4E4C67",
  "#6A0572",
  "#A6A57A",
  "#58A4B0",
  "#D8C99B",
  "#C1666B",
  "#7A5C61",
  "#D0E562",
  "#517664",
  "#5D737E",
  "#BCE784",
  "#5BC0BE",
  "#F3D1F4",
  "#6C6F7D",
  "#B5525C",
  "#FA824C",
  "#F5C6A5",
  "#F2B134",
  "#9D6B53",
  "#FCE883",
  "#778899",
  "#DE1A1A",
  "#142850",
  "#3FEEE6",
  "#5171A5",
  "#F4C7AB",
  "#BC477B",
  "#63A3CB",
  "#7A6C5D",
  "#FFB300",
  "#3B3A36",
  "#FED766",
  "#A23B72",
  "#E5A4CB",
  "#A79D60",
  "#5E3023",
  "#B1F8A6",
  "#ACD8AA",
  "#424642",
  "#E07A5F",
  "#97D8C4",
  "#CF1B1B",
  "#FF595E",
  "#364156",
  "#C23B22",
  "#EFFBFF",
  "#FF9A8B",
  "#96C5F7",
  "#6F4A8E",
  "#83D0C9",
  "#DBB68F",
  "#D72638",
  "#D4AF37",
  "#FFD3B4",
  "#6C757D",
  "#EBF4F5",
  "#FF679A",
  "#FF6D00",
  "#546A7B",
  "#F48C06",
  "#ACBFA4",
  "#D9BF77",
  "#C57B57",
  "#FDA214",
  "#704241",
  "#F3A712",
  "#007380",
  "#51A3A3",
  "#0A2147",
  "#A45EE6",
  "#8AB17D",
  "#C49000",
  "#FDE74C",
  "#D9B44A",
  "#5975A4",
  "#C8553D",
  "#2E294E",
  "#F3A683",
  "#3F612D",
  "#2D6E7E",
  "#E43F6F",
  "#D8B4A0",
  "#73877B",
  "#F5AB99",
  "#9EBC9F",
];
