import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { AccountMappingTypeGroupTypes, UnMappedItem } from "./mapping.types";
import {
  changeItemsOrder,
  changeGroupsOrder,
  createAccountMappingGroup,
  createMappingItem,
  createAccountMappingWithGroup,
  deleteMappingItem,
  deleteMappingGroup,
  editMappingItem,
  editMappingGroup,
  getMappingList,
  accountsAutoMapping,
} from "./mappingAction";
import { LoaderType } from "../type";

export type MappingState = {
  mappingList: {
    accountMappingTypeGroupTypes: Array<AccountMappingTypeGroupTypes>;
    unmapped: Array<UnMappedItem>;
  };
  hasError: boolean;
  loading: {
    autoMapping: LoaderType;
    list: LoaderType;
    group: LoaderType;
    item: LoaderType;
    ordering: LoaderType;
  };
};

const initialState: MappingState = {
  mappingList: { accountMappingTypeGroupTypes: [], unmapped: [] },
  hasError: false,
  loading: {
    autoMapping: "idle",
    list: "idle",
    group: "idle",
    item: "idle",
    ordering: "idle",
  },
};

export const mappingSlice = createSlice({
  name: "mappingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MappingState>) => {
    builder
      .addCase(getMappingList.pending, (state) => {
        state.loading.list = "pending";
      })
      .addCase(getMappingList.fulfilled, (state, { payload }) => {
        state.mappingList.accountMappingTypeGroupTypes =
          payload.accountMappingTypeGroupTypes;
        state.mappingList.unmapped = payload.unMappeds;

        state.loading = {
          autoMapping: "idle",
          list: "succeeded",
          group: "idle",
          item: "idle",
          ordering: "idle",
        };
      })
      .addCase(getMappingList.rejected, (state) => {
        state.loading.list = "failed";
        state.hasError = true;
      });
    builder
      .addCase(createAccountMappingGroup.pending, (state) => {
        state.loading.group = "pending";
      })
      .addCase(createAccountMappingGroup.fulfilled, (state) => {
        state.loading.group = "succeeded";
      })
      .addCase(createAccountMappingGroup.rejected, (state) => {
        state.loading.group = "failed";
        state.hasError = true;
      });
    // createMappingSection
    builder
      .addCase(createAccountMappingWithGroup.pending, (state) => {
        state.loading.item = "pending";
      })
      .addCase(createAccountMappingWithGroup.fulfilled, (state) => {
        state.loading.item = "succeeded";
      })
      .addCase(createAccountMappingWithGroup.rejected, (state) => {
        state.loading.item = "failed";
        state.hasError = true;
      });
    // createMappingItem
    builder
      .addCase(createMappingItem.pending, (state) => {
        state.loading.item = "pending";
      })
      .addCase(createMappingItem.fulfilled, (state) => {
        state.loading.item = "succeeded";
      })
      .addCase(createMappingItem.rejected, (state) => {
        state.loading.item = "failed";
        state.hasError = true;
      });
    builder
      .addCase(editMappingGroup.pending, (state) => {
        state.loading.group = "pending";
      })
      .addCase(editMappingGroup.fulfilled, (state) => {
        state.loading.group = "succeeded";
      })
      .addCase(editMappingGroup.rejected, (state) => {
        state.loading.group = "failed";
        state.hasError = true;
      });

    // editMappingItem
    builder
      .addCase(editMappingItem.pending, (state) => {
        state.loading.item = "pending";
      })
      .addCase(editMappingItem.fulfilled, (state) => {
        state.loading.item = "succeeded";
      })
      .addCase(editMappingItem.rejected, (state) => {
        state.loading.item = "failed";
        state.hasError = true;
      });

    // deleteMappingGroup
    builder
      .addCase(deleteMappingGroup.pending, (state) => {
        state.loading.group = "pending";
      })
      .addCase(deleteMappingGroup.fulfilled, (state) => {
        state.loading.group = "succeeded";
      })
      .addCase(deleteMappingGroup.rejected, (state) => {
        state.loading.group = "failed";
        state.hasError = true;
      });
    // deleteMappingItem
    builder
      .addCase(deleteMappingItem.pending, (state) => {
        state.loading.item = "pending";
      })
      .addCase(deleteMappingItem.fulfilled, (state) => {
        state.loading.item = "succeeded";
      })
      .addCase(deleteMappingItem.rejected, (state) => {
        state.loading.item = "failed";
        state.hasError = true;
      });
    // changeSectionsOrder
    builder
      .addCase(changeGroupsOrder.pending, (state) => {
        state.loading.ordering = "pending";
      })
      .addCase(changeGroupsOrder.fulfilled, (state) => {
        state.loading.ordering = "succeeded";
      })
      .addCase(changeGroupsOrder.rejected, (state) => {
        state.loading.ordering = "failed";
        state.hasError = true;
      });
    // changeItemsOrder
    builder
      .addCase(changeItemsOrder.pending, (state) => {
        state.loading.ordering = "pending";
      })
      .addCase(changeItemsOrder.fulfilled, (state) => {
        state.loading.ordering = "succeeded";
      })
      .addCase(changeItemsOrder.rejected, (state) => {
        state.loading.ordering = "failed";
        state.hasError = true;
      });
    // autoMapping
    builder
      .addCase(accountsAutoMapping.pending, (state) => {
        state.loading.autoMapping = "pending";
      })
      .addCase(accountsAutoMapping.fulfilled, (state) => {
        state.loading.autoMapping = "succeeded";
      })
      .addCase(accountsAutoMapping.rejected, (state) => {
        state.loading.autoMapping = "failed";
        state.hasError = true;
      });
  },
});
export default mappingSlice.reducer;
