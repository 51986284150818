import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid ${theme.colors.grey003};
  `
);

export const MainListWrapper = styled.div(
  ({ theme }) => css`
    height: 56px;
    padding: 16px 32px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.grey003};
    &:hover #button_wrapper {
      opacity: 1;
    }
    &:hover {
      background-color: ${theme.colors.grey50};
    }
  `
);

export const ButtonWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease-in-out;
  &.rotate {
    transform: rotate(-180deg);
  }
`;
export const MainListTitle = styled.h4(
  ({ theme }) => css`
    font-size: 14px;
    line-height: normal;
    color: ${theme.colors.textPrimary};
  `
);
export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
