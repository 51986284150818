import React, { MouseEvent, useState } from "react";
import { t } from "i18next";
import Icon from "../../../shared/icon/Icon";
import Button from "../../../v2/button/Button";

import UnmappedModalLoading from "../unmappedModalLoading/UnmappedModalLoading";

import {
  Header,
  IconWrapper,
  UnMappedSection,
  SubTitle,
  Title,
  Wrapper,
  ItemWrapper,
  Item,
  ItemName,
  ButtonWrapper,
  FooterButtonWrapper,
  TitleWrapper,
} from "./UnmappedModal.style";
import Modal from "../../../v2/modal/Modal";
import RoundButton from "../../../v2/roundButton/RoundButton";

interface UnmappedModalProps {
  loading: boolean;
  unMappedData: Array<{ accountId: string; name: string }>;
  handleAutoMapping: () => void;
  handleManualMapping: () => void;
  handleClose: () => void;
  handleOpenAddMapping: ({
    accountId,
    accountName,
  }: {
    accountId: string;
    accountName: string;
  }) => void;
}
const UnmappedModal = ({
  unMappedData,
  loading,
  handleOpenAddMapping,
  handleAutoMapping,
  handleManualMapping,
  handleClose,
}: UnmappedModalProps) => {
  const [hovered, setHovered] = useState<null | string>(null);

  return (
    <Modal
      size="medium"
      modalTitle={t("UnMappedModal.ModalTitle")}
      footer={
        <FooterButtonWrapper>
          <Button
            disabled={loading}
            label={t("UnMappedModal.AutomaticItemMapButton")}
            size="large"
            variant="text"
            onClick={handleAutoMapping}
          />
          <Button
            disabled={loading}
            label={t("UnMappedModal.MapManualButton")}
            size="large"
            variant="lighter"
            onClick={handleManualMapping}
          />
        </FooterButtonWrapper>
      }
      close={handleClose}
    >
      {loading ? (
        <UnmappedModalLoading />
      ) : (
        <Wrapper>
          <Header>
            <IconWrapper>
              <Icon fill="white" iconName="closeIcon" height={30} width={30} />
            </IconWrapper>
            <TitleWrapper>
              <Title>{t("UnMappedModal.ModalBodyTitle")}</Title>
              <SubTitle>{t("UnMappedModal.ModalBodySubTitle")}</SubTitle>
            </TitleWrapper>
          </Header>
          <UnMappedSection>
            {unMappedData.map((item) => (
              <ItemWrapper
                onMouseEnter={() => setHovered(item.accountId)}
                onMouseLeave={() => setHovered(null)}
                key={item.accountId}
              >
                <Item>
                  <ItemName>
                    {item.accountId} - {item.name}
                  </ItemName>
                  {hovered === item.accountId && (
                    <ButtonWrapper>
                      {/* <Button
                       rounded
                       leftIconName="plus_v2"
                       text={t("UnMappedModal.MapAutomaticButton")}
                       size="small"
                       buttonStyle="lighter"
                       handleClick={() => {}}
                     /> */}
                      {/* todo Round Button */}
                      <RoundButton
                        label={t("UnMappedModal.MapManualButton")}
                        onClick={() => {
                          handleOpenAddMapping({
                            accountId: item.accountId,
                            accountName: item.name,
                          });
                        }}
                      />
                    </ButtonWrapper>
                  )}
                </Item>
              </ItemWrapper>
            ))}
          </UnMappedSection>
        </Wrapper>
      )}
    </Modal>
  );
};

export default UnmappedModal;
