import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface TitleWrapperProps {
  length: number;
}

export const TitleWrapper = styled.div<TitleWrapperProps>(
  ({ length }) => css`
    display: grid;
    grid-template-columns: 4fr ${length}fr;
  `
);

export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
  `
);

export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Content = styled.h3(
  ({ theme }) => css`
    width: 120px;
    text-align: center;
    ${theme.fonts.AvenirMedium}
    font-size: 18px;
    text-align: right;
  `
);
