import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import {
  AccountMappingTypeGroupTypes,
  MappingItemRequest,
  MappingItemWithGroupRequest,
  UnMappedItem,
} from "../../../../store/mapping/mapping.types";
import {
  ProgressLine,
  ProgressWrapper,
  Section,
  Wrapper,
} from "./AddMappingItem.style";
import StepContainer from "./stepContainer/StepContainer";
import ItemGroup from "./steps/itemGroup/ItemGroup";
import ItemGroupType from "./steps/itemGroupType/ItemGroupType";
import ItemInfo from "./steps/itemInfo/ItemInfo";
import ItemType from "./steps/itemType/ItemType";
import Success from "./steps/success/Success";
import { useTranslation } from "react-i18next";
import { LoaderType } from "../../../../store/type";

type PageType =
  | "itemInfo"
  | "itemGroupType"
  | "itemType"
  | "itemGroup"
  | "successPage";

const steps: any = {
  itemInfo: {
    title: "AddMappingItem.ItemInfoTitle",
    subTitle: "AddMappingItem.ItemInfoSubTitle",
  },
  itemGroupType: {
    title: "AddMappingItem.ItemGroupTypeTitle",
    subTitle: "AddMappingItem.ItemGroupTypeSubTitle",
  },
  itemType: {
    title: "AddMappingItem.ItemTypeTitle",
    subTitle: "AddMappingItem.ItemTypeSubTitle",
  },
  itemGroup: {
    title: "AddMappingItem.ItemGroupTitle",
    subTitle: "AddMappingItem.ItemGroupSubTitle",
  },
};

export interface InfoFormValues {
  accountId: string;
  name: string;
  debitRePortalMappingId?: string;
  creditRePortalMappingId?: string;
  companyBranchId?: string;
  companyBusinessLineId?: string;
  accountMappingCashFlowCashInId?: string;
  accountMappingCashFlowCashOutId?: string;
}

interface IProps {
  initialValue: { accountId: string; name: string };
  unMappedItem: Array<UnMappedItem>;
  accountMappingTypeGroupTypes: Array<AccountMappingTypeGroupTypes>;
  portalMappingOptions: Array<{ value: string; label: string }>;
  companyBranchesOptions: Array<{ value: string; label: string }>;
  companyBusinessLineOptions: Array<{ value: string; label: string }>;
  cashFlowReceiveOptions: Array<{ value: string; label: string }>;
  cashFlowPaidOptions: Array<{ value: string; label: string }>;
  fetchingStatus: LoaderType;
  createStatus: LoaderType;
  closeContent: () => void;
  updateMapping: (close?: boolean) => void;
  handleSubmit: (data: MappingItemRequest) => void;
  handleSubmitWithGroup: (data: MappingItemWithGroupRequest) => void;
}

const AddMappingItem = ({
  initialValue,
  unMappedItem,
  accountMappingTypeGroupTypes,
  portalMappingOptions,
  companyBranchesOptions,
  companyBusinessLineOptions,
  cashFlowReceiveOptions,
  cashFlowPaidOptions,
  fetchingStatus,
  createStatus,
  closeContent,
  updateMapping,
  handleSubmit,
  handleSubmitWithGroup,
}: IProps) => {
  const { t } = useTranslation();
  const totalPages = Object.keys(steps).length;
  const [progressNumber, setProgressNumber] = useState(1);

  const [activePage, setActivePage] = useState<PageType>("itemInfo");

  const [infoFormValues, setInfoFormValues] =
    useState<InfoFormValues>(initialValue);

  const [selectGroupeTypeId, setSelectGroupeTypeId] = useState("");
  const [selectTypeId, setSelectTypeId] = useState("");
  const [selectGroupId, setSelectGroupId] = useState("");
  const [newGroupName, setNewGroupName] = useState("");

  useEffect(() => {
    if (fetchingStatus === "succeeded") {
      setProgressNumber(0);
      changePage("itemInfo");
      setInfoFormValues({ accountId: "", name: "" });
      setSelectGroupeTypeId("");
      setSelectTypeId("");
      setSelectGroupId("");
      setNewGroupName("");
    }
  }, [fetchingStatus]);

  useEffect(() => {
    if (createStatus === "succeeded") {
      changePage("successPage");
    }
  }, [createStatus]);

  const groupTypeOptions = useMemo(() => {
    return accountMappingTypeGroupTypes.map((type) => ({
      value: type.id.toString(),
      label: type.name,
    }));
  }, [accountMappingTypeGroupTypes]);

  const typeOptions = useMemo(() => {
    return (
      accountMappingTypeGroupTypes
        .find((type) => type.id === +selectGroupeTypeId)
        ?.accountMappingTypes.map((mappingTypes) => ({
          value: mappingTypes.accountMappingType.id.toString(),
          label: mappingTypes.accountMappingType.name,
        })) || []
    );
  }, [accountMappingTypeGroupTypes, selectGroupeTypeId]);

  const groupOptions = useMemo(() => {
    return (
      accountMappingTypeGroupTypes
        .find((type) => type.id === +selectGroupeTypeId)
        ?.accountMappingTypes.find(
          (mappingTypes) => mappingTypes.accountMappingType.id === +selectTypeId
        )
        ?.accountMappingGroups.map((group) => ({
          value: group.id.toString(),
          label: group.name,
        })) || []
    );
  }, [accountMappingTypeGroupTypes, selectGroupeTypeId, selectTypeId]);

  const changePage = (newPage: PageType, isPreviousPage?: boolean) => {
    if (!isPreviousPage) {
      setProgressNumber((prev) => ++prev);
    }
    setActivePage(newPage);
  };

  const handlePreviousPage = () => {
    switch (activePage) {
      case "itemGroupType":
        changePage("itemInfo", true);
        setProgressNumber(1);
        break;
      case "itemType":
        changePage("itemGroupType", true);
        setProgressNumber(2);
        break;
      case "itemGroup":
        changePage("itemType", true);
        setProgressNumber(3);
        break;
      default:
        changePage("itemGroup", true);
        setProgressNumber(4);
        break;
    }
  };

  const handleAddMapping = () => {
    if (newGroupName) {
      handleSubmitWithGroup({
        name: infoFormValues.name,
        accountId: infoFormValues.accountId,
        accountMappingGroupName: newGroupName,
        creditRePortalMappingId: infoFormValues?.creditRePortalMappingId
          ? +infoFormValues.creditRePortalMappingId
          : null,
        debitRePortalMappingId: infoFormValues?.debitRePortalMappingId
          ? +infoFormValues?.debitRePortalMappingId
          : null,
        companyBranchId: infoFormValues?.companyBranchId
          ? +infoFormValues.companyBranchId
          : null,
        companyBusinessLineId: infoFormValues.companyBusinessLineId
          ? +infoFormValues.companyBusinessLineId
          : null,
        accountMappingCashFlowCashInId:
          infoFormValues.accountMappingCashFlowCashInId
            ? +infoFormValues.accountMappingCashFlowCashInId
            : null,
        accountMappingCashFlowCashOutId:
          infoFormValues.accountMappingCashFlowCashOutId
            ? +infoFormValues.accountMappingCashFlowCashOutId
            : null,
        accountMappingTypeId: +selectTypeId,
      });
    } else {
      handleSubmit({
        name: infoFormValues.name,
        accountId: infoFormValues.accountId,
        accountMappingGroupId: +selectGroupId,
        creditRePortalMappingId: infoFormValues?.creditRePortalMappingId
          ? +infoFormValues.creditRePortalMappingId
          : null,
        debitRePortalMappingId: infoFormValues?.debitRePortalMappingId
          ? +infoFormValues?.debitRePortalMappingId
          : null,
        companyBranchId: infoFormValues?.companyBranchId
          ? +infoFormValues.companyBranchId
          : null,
        companyBusinessLineId: infoFormValues.companyBusinessLineId
          ? +infoFormValues.companyBusinessLineId
          : null,
        accountMappingCashFlowCashInId:
          infoFormValues.accountMappingCashFlowCashInId
            ? +infoFormValues.accountMappingCashFlowCashInId
            : null,
        accountMappingCashFlowCashOutId:
          infoFormValues.accountMappingCashFlowCashOutId
            ? +infoFormValues.accountMappingCashFlowCashOutId
            : null,
      });
    }
  };

  const sidebarContentEl = document.getElementById("addMapping");

  if (!sidebarContentEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <Wrapper>
      {activePage !== "successPage" && (
        <ProgressWrapper>
          <ProgressLine
            progress={progressNumber}
            totalPageNumber={totalPages}
          />
        </ProgressWrapper>
      )}

      {activePage !== "successPage" && (
        <Section>
          <StepContainer
            title={t(steps[activePage].title)}
            subTitle={t(steps[activePage].subTitle)}
            handleBack={() =>
              activePage !== "itemInfo" ? handlePreviousPage() : closeContent()
            }
          >
            {activePage === "itemInfo" && (
              <ItemInfo
                initialValue={infoFormValues}
                unMappedItem={unMappedItem}
                portalMappingOptions={portalMappingOptions}
                companyBranchesOptions={companyBranchesOptions}
                companyBusinessLineOptions={companyBusinessLineOptions}
                cashFlowReceiveOptions={cashFlowReceiveOptions}
                cashFlowPaidOptions={cashFlowPaidOptions}
                handleNext={(formValues: InfoFormValues) => {
                  setInfoFormValues(formValues);
                  changePage("itemGroupType");
                }}
              />
            )}
            {activePage === "itemGroupType" && (
              <ItemGroupType
                groupTypeOptions={groupTypeOptions}
                groupeTypeId={selectGroupeTypeId}
                updateGroupeTypeId={(groupTypeId: string) => {
                  if (selectGroupeTypeId !== groupTypeId) {
                    setSelectTypeId("");
                  }
                  setSelectGroupeTypeId(groupTypeId);
                }}
                handleNext={() => {
                  changePage("itemType");
                }}
              />
            )}

            {activePage === "itemType" && (
              <ItemType
                typeOptions={typeOptions}
                typeId={selectTypeId}
                updateTypeId={(typeId: string) => {
                  if (selectTypeId !== typeId) {
                    setSelectGroupId("");
                    setNewGroupName("");
                  }
                  setSelectTypeId(typeId);
                }}
                handleNext={() => changePage("itemGroup")}
              />
            )}
            {activePage === "itemGroup" && (
              <ItemGroup
                groupOptions={groupOptions}
                groupId={selectGroupId}
                groupName={newGroupName}
                createStatus={createStatus}
                updateGroupId={setSelectGroupId}
                updateGroupName={setNewGroupName}
                handleNext={handleAddMapping}
              />
            )}
          </StepContainer>
        </Section>
      )}
      {activePage === "successPage" && (
        <Success
          fetchingStatus={fetchingStatus}
          handleMappingItem={() => {
            updateMapping(false);
          }}
          handleReturnMapping={() => {
            updateMapping(true);
          }}
        />
      )}
    </Wrapper>,
    sidebarContentEl
  );
};

export default AddMappingItem;
