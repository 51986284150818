import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.button<{ size?: "normal" | "small" }>(
  ({ theme, size = "normal" }) => css`
    border-radius: 6px;
    padding: 2px;
    height: ${size === "normal" ? "24px" : "20px"};
    background-color: transparent;
    outline: none;
    &:hover {
      background-color: ${theme.colors.grey100};
    }
    &:focus {
      background-color: ${theme.colors.textPrimary};
    }
  `
);
