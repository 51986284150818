import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ open: string }>(
  ({ open, theme }) => css`
    border-radius: 12px 12px 0 0;
    transition: padding 0.3s ease-in-out;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${theme.colors.white};
    z-index: 99999;
    overflow: hidden;
    animation: ${open !== "IDLE" ? slideUp : slideDown} 0.3s ease-in-out
      forwards;
  `
);

export const TitleWrapper = styled.div(
  ({ theme }) => css`
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${theme.colors.grey200};
  `
);

export const Title = styled.h5(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.black005};
    font-size: 14px;
  `
);

export const SideBarWrapper = styled.div<{ open: string }>(
  ({ theme, open }) => css`
    position: fixed;
    top: 65px;
    width: 100%;
    height: calc(100vh - 65px);
    background-color: ${theme.colors.white};
    z-index: 1000;
    overflow: hidden;
    animation: ${open !== "IDLE" && slideFromDown} 0.3s ease-in-out forwards;
  `
);

const slideFromDown = keyframes`
0% {
 transform: translateY(-100%);
}
100%{
 transform: translateY(0);
}
`;

const slideUp = keyframes`
from {
 transform: translateY(100%);
}
to {
 transform: translateY(0);
}
`;

const slideDown = keyframes`
from {
 transform: translateY(0);
}
to {
 transform: translateY(100%);
}
`;

export const Overlay = styled.div(
  ({ theme }) => css`
    opacity: 0.3;
    background-color: ${theme.colors.black};
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 9999;
  `
);
