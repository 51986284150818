import { ExcelFileStatus, ExcelFileType } from "../enums/enum";

const FileStatuses: {
  [key in ExcelFileStatus]:
    | "Pending"
    | "Declined"
    | "Uploaded"
    | "Partially uploaded";
} = {
  [ExcelFileStatus.Active]: "Pending",
  [ExcelFileStatus.Error]: "Declined",
  [ExcelFileStatus.Uploaded]: "Uploaded",
  [ExcelFileStatus.PartiallyUploaded]: "Partially uploaded",
};

const FileCategories: {
  [key in ExcelFileType]: "Transactions" | "Account" | "Clients";
} = {
  [ExcelFileType.Transaction]: "Transactions",
  [ExcelFileType.Account]: "Account",
  [ExcelFileType.Clients]: "Clients",
};

export const formatStatus = (status: ExcelFileStatus) => {
  return FileStatuses[status];
};

export const fileNameFormat = (name: string) => {
  if (name.length > 15) {
    return name.slice(0, 15) + "....xls";
  } else {
    return name + ".xls";
  }
};

export const formatType = (type: ExcelFileType) => {
  return FileCategories[type];
};

export const formatToMb = (size: number) => {
  return (size / 1000000).toFixed(2);
};
