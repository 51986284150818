import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ReactComponent as CloseSVGIcon } from "../../../../assets/svgs/close.svg";

export const DropDownWrapper = styled.div`
  width: 163px;
`;
export const ChooseFileWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 37px 0;
    border: 1px solid ${theme.colors.greyBorderDark};
    border-radius: 15px;
    margin: 30px 0 25px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 20px 0 25px;
    }
  `
);

export const SvgContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
  `
);

export const Description = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirMedium}
    font-size: 14px;
    margin: 23px 0 12px;
  `
);
export const Span = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.primary};
    cursor: pointer;
  `
);
export const FileType = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.grey};
  `
);

export const ProgressContainer = styled.div(
  ({ theme }) => css`
    padding: 10px 12px;
    border: 1px solid ${theme.colors.greyBorderDark};
    border-radius: 15px;
  `
);
export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  vertical-align: middle;
`;
export const FileImage = styled.img`
  width: 40px;
  height: 40px;
`;
export const FileName = styled.p(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirMedium};
  `
);
export const FileSize = styled.p(
  ({ theme }) => css`
    font-size: 10px;
    ${theme.fonts.AvenirMedium};
    color: ${theme.colors.grey};
  `
);
export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CloseSvg = styled(CloseSVGIcon)`
  margin-top: 8px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 0 60px;
    gap: 30px;
    margin-top: 50px;
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 30px;
      flex-wrap: wrap;
      padding: 0;
      gap: 20px;
    }
  `
);

export const AlertWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
