import { t } from "i18next";
import Icon from "../../../../../shared/icon/Icon";
import Button from "../../../../../v2/button/Button";
import { Item, Wrapper } from "./ItemType.style";

interface Props {
  typeOptions: Array<{ value: string; label: string }>;
  typeId: string;
  handleNext: () => void;
  updateTypeId: (typeId: string) => void;
}
const ItemType = ({ typeId, typeOptions, updateTypeId, handleNext }: Props) => {
  return (
    <Wrapper>
      {typeOptions.map((item) => (
        <Item
          isSelected={item.value === typeId}
          onClick={() => updateTypeId(item.value)}
          key={item.value}
        >
          {item.label}
          {item.value === typeId && (
            <Icon iconName="checkMark" fill="primary" />
          )}
        </Item>
      ))}

      <Button
        disabled={!typeId}
        label={t("AddMapping.NextStep")}
        size="large"
        variant="filled"
        onClick={() => {
          handleNext();
        }}
      />
    </Wrapper>
  );
};

export default ItemType;
