import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface IsMobile {
  isMobile: boolean;
}

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: 30px 0;
    overflow: hidden;
    min-height: 100vh;
  `
);
export const DropDownWrapper = styled.div`
  width: 165px;
`;

export const DonutChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const GridContainer = styled.div<IsMobile>(
  ({ isMobile }) => css`
    display: grid;
    grid-template-columns: 1fr;
    margin-top: ${isMobile ? "50px" : "100px"};
  `
);

export const ChartTitle = styled.p<IsMobile>(
  ({ theme, isMobile }) => css`
    ${theme.fonts.AvenirMedium}
    font-size:${isMobile ? "16px" : "20px"};
    color: ${theme.colors.black};
    margin-bottom: ${isMobile ? "15px" : "30px"};
    text-align: center;
  `
);
export const LastUpdated = styled.div(
  ({ theme }) => css`
    text-align: end;
    padding: 0 20px 20px;
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.textSecondary};
    margin-left: auto;
    font-size: 16px;
  `
);
