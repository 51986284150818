import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CompanySvg } from "../../../assets/svgs/logo.svg";
import { PageURL } from "../../../constants/router";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <CompanySvg
      style={{ cursor: isMobile ? "default" : "pointer" }}
      onClick={() => {
        if (isMobile) return;
        navigate(PageURL.Revenue.path);
      }}
    />
  );
};

export default Logo;
