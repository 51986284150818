import React, { useState } from "react";

import {
  FAQAboutItem,
  FAQAboutList,
  FAQMainSection,
  Wrapper,
} from "./FaqQuestionContent.style";
import QuestionItem from "./questionItem/QuestionItem";

export type FAQArrayData = {
  question: string;
  answer: string;
};
export type FAQ = {
  title: string;
  data: Array<FAQArrayData>;
};

interface FAQProps {
  faqData: Array<FAQ>;
}

const FAQContainer = ({ faqData }: FAQProps) => {
  const [showIndex, setShowIndex] = useState(0);
  const handleChange = (index: number) => {
    setShowIndex(index);
  };

  return (
    <Wrapper>
      <FAQAboutList>
        {faqData?.map((item, index) => (
          <FAQAboutItem
            onClick={() => {
              handleChange(index);
            }}
            key={index}
            clicked={showIndex === index}
          >
            {item.title}
          </FAQAboutItem>
        ))}
      </FAQAboutList>
      <FAQMainSection>
        <QuestionItem
          key={faqData?.[showIndex]?.title}
          data={faqData?.[showIndex]?.data || []}
        />
      </FAQMainSection>
    </Wrapper>
  );
};

export default FAQContainer;
