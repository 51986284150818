import styled from "@emotion/styled";
import { css } from "@emotion/react";

type Props = {
  checked?: boolean;
};
export const Wrapper = styled.div<Props>(
  ({ theme, checked }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    padding: 0px 16px;
    margin: 10px 0;
    box-shadow: ${checked ? "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px " : null};
    border: 1px solid
      ${checked ? theme.colors.primary : theme.colors.greyBorderDark};
    border-radius: 15px;
    cursor: pointer;
    background-color: ${checked
      ? theme.colors.primaryLight
      : theme.colors.white};
  `
);

export const Title = styled.h3(
  ({ theme }) => css`
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  `
);

export const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const ActionButton = styled.div`
  display: inherit;
  max-width: 100px;
`;

export const RadioButton = styled.input(
  ({ theme }) => css`
    cursor: pointer;
    display: grid;
    place-content: center;
    appearance: none;
    font: inherit;
    color: ${theme.colors.primary};
    min-width: 17px;
    width: 17px;
    height: 17px;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    &::before {
      background-color: ${theme.colors.primary};
    }

    &::before {
      content: "";
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }
    &:checked {
      border: 3.5px solid currentColor;
    }
    &:checked::before {
      transform: scale(1);
    }
  `
);
