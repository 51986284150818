import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    text-align: center;
    img {
      width: 500px;
    }
    @media (max-width: ${theme.breakpoints.xl}) {
      display: none;
    }
  `
);

export const Title = styled.h2(
  ({ theme }) => css`
    max-width: 400px;
    font-size: 22px;
    line-height: 28px;
    margin: 50px 0 30px;
    ${theme.fonts.AvenirDemi};
    cursor: default;
  `
);

export const Description = styled.p(
  ({ theme }) => css`
    max-width: 525px;
    font-size: 16px;
    color: ${theme.colors.grey};
    ${theme.fonts.AvenirMedium};
    line-height: 24px;
    cursor: default;
  `
);
