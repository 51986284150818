import React from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../../helpers/helper";
import { User } from "../../../../store/user/user.types";

import DropDown from "../../../shared/dropdown/Dropdown";
import TableItemLoader from "../../../shared/tableItemLoader/TableItemLoader";

import {
  DropDownItem,
  TableRow,
  TableRowItem,
  TimeItem,
  UserEmail,
  UserName,
} from "./UserManagementTableBody.style";

interface RecordItemsProps {
  data: Array<User>;
  userRoles: Array<{ value: string; label: string }>;
  userStatues: Array<{ value: string; label: string }>;
  loadingIds: Array<number>;
  updateUserRole: (user: User, roleId: string) => void;
  updateUserStatus: (user: User, statusId: string) => void;
}
const UserManagementTableBody = ({
  data,
  userStatues,
  userRoles,
  loadingIds,
  updateUserRole,
  updateUserStatus,
}: RecordItemsProps) => {
  const { t } = useTranslation();
  return (
    <tbody>
      {data.map((item) => (
        <TableRow key={item.id}>
          <TableRowItem>
            <UserName>
              {item.firstName} {item.lastName}
            </UserName>
            <UserEmail>{item.email}</UserEmail>
          </TableRowItem>
          <TableRowItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={
                    item?.clientCompany?.clientRoleId?.toString() || ""
                  }
                  handleSelect={(value) => {
                    updateUserRole(item, value);
                  }}
                  title={""}
                  options={userRoles}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagementTable.LoaderText")} />
            )}
          </TableRowItem>
          <TableRowItem>
            {!loadingIds.includes(item?.clientCompany?.id || 0) ? (
              <DropDownItem>
                <DropDown
                  selectedValue={item?.clientCompany?.status.toString() || ""}
                  handleSelect={(value) => {
                    updateUserStatus(item, value);
                  }}
                  title={""}
                  options={userStatues}
                />
              </DropDownItem>
            ) : (
              <TableItemLoader text={t("UserManagementTable.LoaderText")} />
            )}
          </TableRowItem>
          <TimeItem>{formatDate(item.createdAt)}</TimeItem>
          <TimeItem>
            {item.updatedAt === "0001-01-01T00:00:00"
              ? t("UserManagementTable.Never")
              : formatDate(item.updatedAt)}
          </TimeItem>
        </TableRow>
      ))}
    </tbody>
  );
};

export default UserManagementTableBody;
