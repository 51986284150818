import React from "react";
import {
  ButtonWrapper,
  IconWrapper,
  SubTitle,
  Title,
  TitleWrapper,
  Wrapper,
} from "./Success.style";

import { useTranslation } from "react-i18next";
import Icon from "../../../../../shared/icon/Icon";
import Button from "../../../../../v2/button/Button";
import { LoaderType } from "../../../../../../store/type";

interface IProps {
  fetchingStatus: LoaderType;
  handleMappingItem: () => void;
  handleReturnMapping: () => void;
}

const Success = ({
  fetchingStatus,
  handleMappingItem,
  handleReturnMapping,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <IconWrapper>
          <Icon iconName="checkMark" height={30} width={30} fill="white" />
        </IconWrapper>
        <div>
          <Title>{t("WizardSuccessPage.Title")}</Title>
          <SubTitle>{t("WizardSuccessPage.SubTitle")}</SubTitle>
        </div>
      </TitleWrapper>
      <ButtonWrapper>
        <Button
          loading={fetchingStatus === "pending"}
          label={t("WizardSuccessPage.MapAnotherItemButton")}
          size="large"
          variant="filled"
          onClick={handleMappingItem}
        />
        <Button
          label={t("WizardSuccessPage.BackToMapping")}
          size="large"
          variant="text"
          onClick={handleReturnMapping}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Success;
