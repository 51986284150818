import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.fonts.HelveticaNeue};
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    padding: 0 24px 24px;
  `
);
export const Title = styled.h3(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: 18px;
    line-height: normal;
    margin-bottom: 13px;
  `
);
