import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AuthWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    background-color: ${theme.colors.lightGrey};
    @media (max-width: ${theme.breakpoints.xl}) {
      justify-content: center;
    }
  `
);

export const FormWrapper = styled.div(
  ({ theme }) => css`
    height: 800px;
    display: flex;
    width: 670px;
    align-items: center;
    background-color: ${theme.colors.primary};
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    padding-left: 90px;
    transition: all 0.3s ease-in-out;
    @media (max-width: ${theme.breakpoints.xl}) {
      width: 100%;
      justify-content: center;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-left: 0;
      height: 100vh;
      padding: 25px;
    }
  `
);

export const FormContainer = styled.div(
  ({ theme }) => css`
    width: 420px;
    background-color: ${theme.colors.white};
    border-radius: 15px;
    padding: 45px 50px;
    text-align: center;
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 20px 15px;
    }
  `
);
