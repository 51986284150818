import { css } from "@emotion/react";
import styled from "@emotion/styled";
interface Clicked {
  clicked: boolean;
}
export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    border-radius: 15px;
    background-color: ${theme.colors.white};
    max-height: 400px;
    overflow: auto;
  `
);

export const GlobalStyles = css`
  .rs-stack-item {
    width: 100%;
  }

  .rs-picker-popup {
    z-index: 10000;
    width: calc(100% - 70px);
  }

  .rs-btn-sm {
    width: 100%;
  }

  .rs-picker-toggle {
    width: 100%;
  }

  .rs-picker {
    width: 100%;
  }
`;

export const List = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
    ${theme.fonts.AvenirMedium}
    color: ${theme.colors.black};
    font-size: 14px;
    padding: 20px 14px;
    gap: 10px;
    border-bottom: 1px solid ${theme.colors.lightGrey};

    &:last-child {
      border-radius: 0;
    }
  `
);
export const Item = styled.div<Clicked>(
  ({ theme, clicked }) => css`
    display: flex;
    flex: 1;
    align-items: baseline;
    justify-content: space-between;
    border: 1px solid
      ${clicked ? theme.colors.primary : theme.colors.greyBorder};
    border-radius: 15px;
    padding: 13px 12px;
    background-color: ${clicked ? theme.colors.primaryLight : "none"};
  `
);

export const CalendarWrapper = styled.div(
  ({ theme }) => css`
    padding: 20px 15px;
    width: 100%;

    background-color: ${theme.colors.white};
    & div {
      width: 100%;
    }
    & .rs-picker-popup {
      z-index: 1000;
    }
  `
);

export const RadioButton = styled.input(
  ({ theme }) => css`
    cursor: pointer;
    display: grid;
    place-content: center;
    appearance: none;
    font: inherit;
    color: ${theme.colors.primary};
    min-width: 17px;
    width: 17px;
    height: 17px;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    &::before {
      background-color: ${theme.colors.primary};
    }

    &::before {
      content: "";
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }
    &:checked {
      border: 3.5px solid currentColor;
    }
    &:checked::before {
      transform: scale(1);
    }
  `
);

export const BottomWrapper = styled.div`
  padding: 14px 14px 20px;
`;
