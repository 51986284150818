import OpenEmailForm from "../../../components/desktop/forms/openEmailForm/OpenEmailForm";
import { useNavigate } from "react-router-dom";
import { AuthURL } from "../../../constants/router";

const OpenEmail = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(AuthURL.Login.path);
  };

  return <OpenEmailForm handleNavigate={handleNavigate} />;
};
export default OpenEmail;
