import { css } from "@emotion/react";
import styled from "@emotion/styled";
export const TableRowItem = styled.td`
  vertical-align: middle;
`;

export const DropDownItem = styled.div`
  width: 140px;
`;
export const TableRow = styled.tr(
  ({ theme }) => css`
    margin: 20px 0 30px;
    border-top: 1px solid ${theme.colors.greyBorderDark};

    td {
      padding: 20px 0;
    }
  `
);
export const TimeItem = styled.td(
  ({ theme }) => css`
    vertical-align: middle;
    font-size: 14px;
    ${theme.fonts.AvenirMedium};
  `
);
export const UserEmail = styled.span(
  ({ theme }) => css`
    font-size: 12px;
    ${theme.fonts.AvenirDemi};
    color: ${theme.colors.grey};
    display: block;
  `
);
export const UserImage = styled.img`
  width: 35px;
  height: 35px;
`;

export const UserName = styled.span(
  ({ theme }) => css`
    font-size: 14px;
    display: block;
    ${theme.fonts.AvenirMedium};
  `
);
