import React from "react";
import { CompanyBusinessLineType } from "../../../../../store/company/company.types";
import Options from "../../../../shared/options/Options";
import Icon from "../../../../shared/icon/Icon";
import { Title, Wrapper } from "./CompanyBusinessLIneTable.style";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

interface CompanyBusinessLIneTableProps {
  companyBusinessLines: Array<CompanyBusinessLineType>;
  handleOpenEditModal: (object: CompanyBusinessLineType) => void;
  handleDeleteModal: (id: number) => void;
  handleOnDragEnd: (result: DropResult) => void;
}
const CompanyBusinessLIneTable = ({
  companyBusinessLines,
  handleOpenEditModal,
  handleDeleteModal,
  handleOnDragEnd,
}: CompanyBusinessLIneTableProps) => {
  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="ROOT">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {companyBusinessLines.map((item, index) => (
                <Draggable
                  draggableId={item.id.toString()}
                  key={item.id}
                  index={index}
                >
                  {(provided) => (
                    <>
                      <Wrapper
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div>
                          <Icon iconName="drag" height={17} width={17} />
                        </div>
                        <Title>{item.name}</Title>
                        <Options
                          handleDelete={() => handleDeleteModal(item.id)}
                          handleEdit={() => handleOpenEditModal(item)}
                          showDeleteItem={true}
                          isMobile={false}
                        />
                      </Wrapper>
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CompanyBusinessLIneTable;
