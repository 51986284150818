import React from "react";
import { Content, RightSide, Title, Wrapper } from "./ReportalItem.style";
import { isMobile } from "react-device-detect";

interface ReportalItemProps {
  title: string;
  firstAmount: number;
  secondAmount: number;
  length: number;
  thirdAmount?: number;
  hasBorder: boolean;
}
const ReportalItem = ({
  title,
  firstAmount,
  secondAmount,
  thirdAmount,
  length,
  hasBorder,
}: ReportalItemProps) => {
  const dotedNumber = (number?: number) => {
    return number?.toLocaleString();
  };
  return (
    <Wrapper hasBorder={hasBorder} isMobile={isMobile} length={length}>
      <Title>{title}</Title>
      <RightSide>
        <Content>{dotedNumber(firstAmount)}</Content>
        <Content>{dotedNumber(secondAmount)}</Content>
        {length === 3 && <Content>{dotedNumber(thirdAmount)}</Content>}
      </RightSide>
    </Wrapper>
  );
};

export default ReportalItem;
