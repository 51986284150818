import { useNavigate } from "react-router-dom";
import ResetPasswordForm from "../../../components/desktop/forms/resetPasswordForm/ResetPasswordForm";

import { useAppDispatch, useAppSelector } from "../../../store";
import { resetPassword } from "../../../store/user/userAction";
import { useState } from "react";
import { AuthURL } from "../../../constants/router";

const ResetPassword = () => {
  const isLoading = useAppSelector((state) => state.user.loading === "pending");

  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = ({ email }: { email: string }) => {
    setErrorMessage("");
    dispatch(resetPassword({ email })).then((action: any) => {
      if (action?.error?.message) {
        setErrorMessage(action.error.message);
      } else {
        navigate(AuthURL.CheckEmail.path);
      }
    });
  };

  const handleNavigate = () => {
    navigate(AuthURL.Login.path);
  };

  return (
    <ResetPasswordForm
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      handleNavigate={handleNavigate}
      errorMessage={errorMessage}
    />
  );
};

export default ResetPassword;
