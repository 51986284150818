import React from "react";
import { ReportItem } from "../../../store/report/report.types";
import ExcelImage from "../../../assets/images/excel.png";

import {
  fileNameFormat,
  formatStatus,
  formatType,
} from "../../../helpers/importDataHelper";
import {
  ButtonItem,
  FileImage,
  FileWrapper,
  Loading,
  StatusItem,
  TableBodyWrapper,
  TableContent,
  TableContentRow,
  TableHeaderItem,
  TableUserContainer,
  UserEmail,
  UserWrapper,
  Wrapper,
} from "./ImportDataMobile.style";
import { ExcelFileStatus } from "../../../enums/enum";
import Tooltip from "../../shared/tooltip/Tooltip";

import TextButton from "../../shared/textButton/TextButton";
import { useTranslation } from "react-i18next";
import TableItemLoader from "../../shared/tableItemLoader/TableItemLoader";
import { formatDate } from "../../../helpers/helper";

interface ImportDataMobileProps {
  data: Array<ReportItem>;
  tableHeaders: Array<string>;
  loadingIds: Array<number>;
  handleDownload: (id: number) => void;
}

const ImportDataMobile = ({
  data,
  tableHeaders,
  loadingIds,
  handleDownload,
}: ImportDataMobileProps) => {
  const { t } = useTranslation();
  return (
    <TableBodyWrapper>
      {data.map((item) => {
        return (
          <Wrapper key={item.id}>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[0])}</TableHeaderItem>
              <TableUserContainer>
                <UserWrapper>
                  {item.client.firstName}
                  {item.client.lastName}
                </UserWrapper>
                <UserEmail>{item.client.email}</UserEmail>
              </TableUserContainer>
            </TableContentRow>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[1])}</TableHeaderItem>
              <TableContent>{formatDate(item.createdAt)}</TableContent>
            </TableContentRow>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[2])}</TableHeaderItem>
              <StatusItem status={formatStatus(item.status)}>
                <p>{formatStatus(item.status)}</p>
              </StatusItem>
              {item.status === ExcelFileStatus.Error ||
                (item.status === ExcelFileStatus.PartiallyUploaded && (
                  <Tooltip text={item.errorText} />
                ))}
            </TableContentRow>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[3])}</TableHeaderItem>
              <FileWrapper>
                <FileImage
                  src={ExcelImage}
                  alt={t("ImportDataTableBody.ExcelImageAlt")}
                />
                <div>{fileNameFormat(item.path)}</div>
              </FileWrapper>
            </TableContentRow>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[4])}</TableHeaderItem>
              <TableContent>{Boolean(item.year) && item.year}</TableContent>
            </TableContentRow>
            <TableContentRow>
              <TableHeaderItem>{t(tableHeaders[5])}</TableHeaderItem>
              <TableContent>{formatType(item.type)}</TableContent>
            </TableContentRow>
            <ButtonItem>
              {loadingIds.includes(item.id) ? (
                <Loading>
                  <TableItemLoader />
                </Loading>
              ) : (
                <TextButton
                  title={t("ImportDataTableBody.TextButtonTitle")}
                  isDisable={false}
                  handleClick={() => {
                    handleDownload(item.id);
                  }}
                  iconName={"download"}
                />
              )}
            </ButtonItem>
          </Wrapper>
        );
      })}
    </TableBodyWrapper>
  );
};

export default ImportDataMobile;
