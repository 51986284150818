import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CustomTextButton = styled.button<{
  fontSize: number;
  isMobile: boolean;
}>(
  ({ theme, fontSize, isMobile }) => css`
    width: fit-content;
    color: ${theme.colors.primary};
    background-color: inherit;
    border: none;
    cursor: pointer;
    font-size: ${fontSize}px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    ${theme.fonts.AvenirMedium};
    ${!isMobile &&
    css`
      &:hover {
        color: ${theme.colors.primaryHover};
      }
    `}
    &:active {
      color: ${theme.colors.primary};
    }
  `
);
