import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{ hasWarning: boolean }>(
  ({ theme, hasWarning }) => css`
    display: flex;
    align-items: center;
    border-radius: 100px;
    gap: 4px;
    font-size: 12px;
    line-height: normal;
    padding: ${hasWarning ? "4px 8px 4px 4px" : "4px 8px"};
    ${theme.fonts.HelveticaNeue}
    color: ${theme.colors.textPrimary};
    background: ${hasWarning
      ? theme.colors.warningShade300
      : theme.colors.grey100};
  `
);
