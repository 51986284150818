import React, { useMemo, useState } from "react";

import {
  ActionWrapper,
  CheckWrapper,
  CompanyContent,
  CompanyItem,
  CompanyWrapper,
  IconWrapper,
  NoResultSubTitle,
  NoResultTitle,
  NoResultWrapper,
  SearchWrapper,
} from "./SwitchCompanyMobile.style";

import { useAppSelector } from "../../../store";
import { userCompany } from "../../../store/user/user.types";
import SearchInput from "../../shared/searchInput/SearchInput";
import Icon from "../../shared/icon/Icon";
import { useTranslation } from "react-i18next";

interface SwitchCompanyMobileProps {
  selectedCompany: userCompany | null;
  defaultCompany: number | null | undefined;
  handleSelect: (companyId: number) => void;
  handleSelectDefault: (id: number | null) => void;
  handleClose: () => void;
}
const SwitchCompanyMobile = ({
  selectedCompany,
  defaultCompany,
  handleSelect,
  handleSelectDefault,
  handleClose,
}: SwitchCompanyMobileProps) => {
  const companies = useAppSelector((state) => state.user.user?.companies);
  const [searchValue, setSearchValue] = useState("");

  const filteredCompanies = useMemo(() => {
    if (searchValue && companies?.length) {
      return companies.filter(
        (company) =>
          company.name.toUpperCase().includes(searchValue.toUpperCase()) ||
          company.identificationCode
            .toUpperCase()
            .includes(searchValue.toUpperCase())
      );
    }
    return companies || [];
  }, [searchValue, companies]);
  const { t } = useTranslation();
  return (
    <div>
      <SearchWrapper>
        <SearchInput
          placeholder={t("SelectCompany.SearchInput")}
          smallPlaceholder
          value={searchValue}
          handleChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </SearchWrapper>
      <CompanyWrapper hasItem={Boolean(filteredCompanies.length)}>
        {filteredCompanies.length ? (
          <>
            {filteredCompanies?.map((company, index) => (
              <CompanyItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelect(company.id);
                  handleClose();
                }}
                active={selectedCompany?.id === company.id}
              >
                <CompanyContent active={selectedCompany?.id === company.id}>
                  {company.name}
                </CompanyContent>
                <ActionWrapper>
                  <CheckWrapper>
                    {selectedCompany?.id === company.id ? (
                      <IconWrapper>
                        <Icon fill="primary" iconName="checkMark" />
                      </IconWrapper>
                    ) : null}
                  </CheckWrapper>
                  <div>
                    {defaultCompany === company.id ? (
                      <IconWrapper>
                        <Icon iconName="favFilled" />
                      </IconWrapper>
                    ) : (
                      <IconWrapper
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectDefault(company.id);
                        }}
                      >
                        <Icon iconName="fav" />
                      </IconWrapper>
                    )}
                  </div>
                </ActionWrapper>
              </CompanyItem>
            ))}
          </>
        ) : (
          <NoResultWrapper>
            <Icon iconName="noResult" height={56} width={56} />
            <NoResultTitle>{t("SelectCompany.NotFound")}</NoResultTitle>
            <NoResultSubTitle>
              {t("SelectCompany.NotFoundDescription")}
            </NoResultSubTitle>
          </NoResultWrapper>
        )}
      </CompanyWrapper>
    </div>
  );
};

export default SwitchCompanyMobile;
