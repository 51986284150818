import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const waveAnimation = keyframes`
 0% {
   background-position: -200px 0;
 }
 100% {
   background-position: calc(200px + 100%) 0;
 }
`;

const animatedBackground = ({ theme }: any) => css`
  background: linear-gradient(
    90deg,
    ${theme.colors.greyShade50} 25%,
    ${theme.colors.greyShade100} 50%,
    ${theme.colors.greyShade50} 75%
  );
  background-size: 300px 100%;
  animation: ${waveAnimation} 3s infinite;
`;

export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  `
);
export const Header = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.grey100};
    padding: 24px;
  `
);

export const InputWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 100px;
    background: ${theme.colors.greyShade50};
    width: 100%;
    height: 56px;
    ${animatedBackground({ theme })};
  `
);

export const Section = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MainHeaderTitle = styled.div(
  ({ theme }) => css`
    width: 131px;
    height: 31px;
    border-radius: 4px;
    background: ${theme.colors.greyShade50};
    ${animatedBackground({ theme })};
  `
);

export const MainHeader = styled.div`
  padding: 32px 0 24px;
`;
export const Title = styled.div(
  ({ theme }) => css`
    margin: 12px 0;
    width: 196px;
    height: 20px;
    border-radius: 4px;
    background: ${theme.colors.greyShade50};
    ${animatedBackground({ theme })};
  `
);

export const BodyWrapper = styled.div(
  ({ theme }) => css`
    border-radius: 8px;
    border: 1px solid ${theme.colors.greyShade100};
  `
);

export const ItemWrapper = styled.div(
  ({ theme }) => css`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.greyShade100};
  `
);

export const Item = styled.div<{ length: number }>(
  ({ length, theme }) => css`
    width: ${length}px;
    height: 16px;
    border-radius: 4px;
    background: ${theme.colors.greyShade50};
    ${animatedBackground({ theme })};
  `
);
