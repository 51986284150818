import React from "react";

import { isMobile } from "react-device-detect";

import { Wrapper, Container } from "./Header.style";

import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Languages } from "../../../enums/enum";
import { updateLanguage } from "../../../store/user/userSlice";
import UserInfoDropdown from "../userInfoDropdown/UserInfoDropdown";
import HeaderMobile from "../../mobile/header/HeaderMobile";
import Logo from "../../shared/logo/Logo";

const Header = () => {
  const dispatch = useAppDispatch();
  const handleChangeLanguage = (lang: Languages) => {
    dispatch(updateLanguage(lang));
  };
  const language = useAppSelector((state) => state.user.language);

  return (
    <>
      {!isMobile ? (
        <Wrapper>
          <Logo />
          <Container>
            <UserInfoDropdown />
            <LanguageSwitcher
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          </Container>
        </Wrapper>
      ) : (
        <HeaderMobile />
      )}
    </>
  );
};

export default Header;
