import React from "react";
import Select, { MultiValue } from "react-select";

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: "#F7F9FC",
    height: "50px",
    borderRadius: "15px",
    borderColor: state.isFocused ? "#D9DBE9" : "#D9DBE9",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: "#D9DBE9",
    },
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    height: "inherit",
    overflow: "auto",
    flexWrap: "noWrap",
  }),
};

interface MultiSelectDropdownProps {
  title: string;
  options: Array<{ value: string; label: string }>;
  selectedValue: Array<{ value: string; label: string }>;
  handleSelect: (data: Array<{ value: string; label: string }>) => void;
}

const MultiSelect = ({
  title,
  options,
  selectedValue,
  handleSelect,
}: MultiSelectDropdownProps) => {
  const handleChange = (
    selectedOptions: MultiValue<{ value: string; label: string }>
  ) => {
    handleSelect(selectedOptions as Array<{ value: string; label: string }>);
  };

  return (
    <Select
      isMulti
      placeholder={title}
      value={selectedValue}
      styles={customStyles}
      onChange={handleChange}
      options={options}
      closeMenuOnSelect={false}
      isSearchable={false}
      hideSelectedOptions={false}
    />
  );
};

export default MultiSelect;
