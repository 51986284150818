import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../button/Button";
import {
  ButtonWrapper,
  CustomPopUp,
  Overlay,
  PopUpContent,
} from "./ConfirmationModal.style";

interface ConfirmationModalProps {
  description: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmationModal = ({
  description,
  handleClose,
  handleConfirm,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Overlay onClick={handleClose} />
      <CustomPopUp>
        <PopUpContent>{description}</PopUpContent>
        <ButtonWrapper>
          <Button
            title={t("ConfirmationModal.OutlinedButtonText")}
            buttonType="outlined"
            onClick={handleClose}
          />
          <Button
            title={t("ConfirmationModal.ButtonText")}
            onClick={handleConfirm}
          />
        </ButtonWrapper>
      </CustomPopUp>
    </>
  );
};

export default ConfirmationModal;
