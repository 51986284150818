import React from "react";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  Content,
  Title,
  ContentWrapper,
  IconWrapper,
  SubContent,
  Icon,
} from "./ChartEmptyHolder.style";

interface ChartHolderProps {
  title: string;
}
const ChartHolder = ({ title }: ChartHolderProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>{title}</Title>
      <ContentWrapper>
        <IconWrapper>
          <Icon />
        </IconWrapper>
        <Content>{t("ChartEmptyHolder.Content")}</Content>
        <SubContent>{t("ChartEmptyHolder.SubContent")}</SubContent>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ChartHolder;
