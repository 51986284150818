import { Title, Wrapper } from "./MappingTypes.style";
import {
  AccountMappingGroup,
  AccountMappingType,
  AccountMappingTypeGroupTypes,
  MappingItemType,
} from "../../../store/mapping/mapping.types";
import MappingType from "./mappingType/MappingType";
interface MappingSection {
  openTypeId: number | null;
  openGroupId: number | null;
  mappingData: Array<AccountMappingTypeGroupTypes>;
  orderingDisable: boolean;
  setOpenGroupId: (state: number | null) => void;
  setOpenTypeId: (state: number | null) => void;
  handleOpenAddGroup: (value: number) => void;
  handleEditGroup: (group: AccountMappingGroup) => void;
  handleOpenEditItem: (
    mappingItem: MappingItemType,
    accountMappingType: AccountMappingType
  ) => void;
  handleDeleteGroup: (
    name: string,
    groupId: number,
    accountMappingTypeId: number
  ) => void;
  handleDeleteMappingItem: (
    id: number,
    name: string,
    sectionId: number,
    accountMappingTypeId: number
  ) => void;
  updateGroupsOrdering: (
    groupsIds: Array<number>,
    accountMappingTypeId: number
  ) => void;
  updateGroupMappingItemOrdering: (ids: Array<number>, groupId: number) => void;
}
const MappingTypes = ({
  openTypeId,
  openGroupId,
  mappingData,
  orderingDisable,
  setOpenTypeId,
  setOpenGroupId,
  handleEditGroup,
  handleOpenEditItem,
  handleOpenAddGroup,
  handleDeleteGroup,
  handleDeleteMappingItem,
  updateGroupsOrdering,
  updateGroupMappingItemOrdering,
}: MappingSection) => {
  return (
    <Wrapper>
      {mappingData.map((data) => (
        <div key={data.id}>
          <Title>{data.name}</Title>
          <MappingType
            openTypeId={openTypeId}
            openGroupId={openGroupId}
            accountMappingTypes={data.accountMappingTypes}
            orderingDisable={orderingDisable}
            setOpenTypeId={setOpenTypeId}
            setOpenGroupId={setOpenGroupId}
            handleEditGroup={handleEditGroup}
            handleOpenEditItem={handleOpenEditItem}
            handleOpenAddGroup={handleOpenAddGroup}
            handleDeleteGroup={handleDeleteGroup}
            handleDeleteMappingItem={handleDeleteMappingItem}
            updateGroupsOrdering={updateGroupsOrdering}
            updateGroupMappingItemOrdering={updateGroupMappingItemOrdering}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default MappingTypes;
