import React, { ReactNode } from "react";
import { CustomPopUp, Title, Overlay, Header, CloseIcon } from "./Modal.style";

interface ModalProps {
  title: string;
  type: "medium" | "small";
  headerContent?: ReactNode;
  children: ReactNode;
  showCloseIcon?: boolean;
  close: () => void;
}

const Modal = ({
  title,
  type,
  headerContent,
  children,
  showCloseIcon = true,
  close,
}: ModalProps) => {
  return (
    <>
      <Overlay onClick={close} />
      <CustomPopUp type={type}>
        <Header>
          <Title>{title}</Title>
          {headerContent}
          {showCloseIcon && <CloseIcon onClick={close} />}
        </Header>
        {children}
      </CustomPopUp>
    </>
  );
};

export default Modal;
