import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div``;
export const MoreIconWrapper = styled.div(
  ({ theme }) => css`
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover {
      background-color: ${theme.colors.greyShade50};
    }
  `
);
export const MoreContainer = styled.div(
  ({ theme }) => css`
    width: 169px;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 28px;
    padding: 8px 0;
    border-radius: 8px;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  `
);
export const MoreContainerItem = styled.div(
  ({ theme }) => css`
    padding: 9px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
      background-color: ${theme.colors.grey100};
    }
  `
);

export const MoreContainerItemContent = styled.div<{ isDelete?: boolean }>(
  ({ theme, isDelete = false }) => css`
    ${theme.fonts.HelveticaLight}
    color:${isDelete ? theme.colors.negative600 : theme.colors.textPrimary};
    font-size: 12px;
    line-height: normal;
  `
);
