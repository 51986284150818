import React from "react";
import { Item, Wrapper } from "./ChartLabels.style";

import { chartColors } from "../../../../constants/color";

interface ChartLabelsProps {
  labels: Array<string>;
}

const ChartLabels = ({ labels }: ChartLabelsProps) => {
  if (!labels?.length) return null;

  return (
    <Wrapper>
      {labels.map((key: string, index: number) => (
        <Item key={index} color={chartColors[index]}>
          {key}
        </Item>
      ))}
    </Wrapper>
  );
};

export default ChartLabels;
