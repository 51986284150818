import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as ArrowSvg } from "../../../assets/svgs/downArrow.svg";

export const Wrapper = styled.div`
  width: 100%;
`;

export const DropDownField = styled.div`
  position: relative;
  cursor: pointer;
`;
interface Props {
  opened: boolean;
}

export const HeaderWrapper = styled.div<Props>(
  ({ theme, opened }) => css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${theme.colors.greyBorderDark};
    border-bottom-left-radius: ${opened ? "0px" : "15px"};
    border-bottom-right-radius: ${opened ? "0px" : "15px"};
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding: 13px 12px;
  `
);

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.p(
  ({ theme }) => css`
    ${theme.fonts.AvenirLight}
    font-size: 14px;
  `
);

export const OptionsWrapper = styled.div<Props>(
  ({ opened, theme }) => css`
    display: ${opened ? "block" : "none"};
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    border-radius: 0 0 ${!opened ? "0  0" : "15px 15px"};
    background-color: ${theme.colors.white};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
    padding-bottom: 4px;
    animation: dropDownMenu 0.1s ease-in-out forwards;
    transform-origin: center center;
    @keyframes dropDownMenu {
      0% {
        scale: 0.7;
      }

      100% {
        scale: 1;
      }
    }
  `
);

export const OptionItem = styled.p<{ isMobile: boolean }>(
  ({ theme, isMobile }) => css`
    padding: 10px 0 10px 14px;
    margin-top: 0px;
    ${!isMobile &&
    css`
      &:hover {
        background-color: ${theme.colors.lightGrey};
      }
    `}
  `
);

export const Arrow = styled(ArrowSvg)`
  transition: transform 0.3s ease-in-out;
  &.rotate {
    transform: rotate(-180deg);
  }
`;
