import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: ${theme.colors.grey100};
    border-radius: 100px;
    gap: 8px;
  `
);

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 12px;
  z-index: 1;
  cursor: pointer;
`;

export const Language = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.black005};
    font-size: 14px;
    line-height: 20px;
  `
);

// todo not depend of  language key
export const Selected = styled.div<{ language: "ge" | "en" }>(
  ({ theme, language }) => css`
    position: absolute;
    left: 4px;
    width: 70px;
    height: 30px;
    padding: 4px;
    border-radius: 100px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s cubic-bezier(0.12, 0.62, 1, 1);
    transform: ${language === "en" ? "translateX(0)" : "translateX(68px)"};
  `
);
