import React from "react";
import { LoaderIcon, Wrapper } from "./TableItemLoader.style";

interface LoaderProps {
  text?: string;
}

const Loader = ({ text = "Downloading..." }: LoaderProps) => {
  return (
    <Wrapper>
      <LoaderIcon className="loading-svg" />
      {text}
    </Wrapper>
  );
};

export default Loader;
