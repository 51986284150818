import React, { useEffect, useRef, useState } from "react";
import { Languages } from "../../../enums/enum";
import { isMobile } from "react-device-detect";

import {
  Wrapper,
  ContentWrapper,
  ArrowWrapper,
  Content,
  DropDownContent,
  DropDownItem,
  DropDownTitle,
} from "./LanguageSwitcher.style";
import Icon from "../../shared/icon/Icon";

interface LanguageSwitcherProps {
  language: any;
  handleChangeLanguage: (lang: Languages) => void;
}
const LanguageSwitcher = ({
  language,
  handleChangeLanguage,
}: LanguageSwitcherProps) => {
  const [open, setOpen] = useState(false);

  const languageSwitcherRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        languageSwitcherRef?.current &&
        !languageSwitcherRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (languageSwitcherRef?.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [languageSwitcherRef]);

  return (
    <Wrapper
      isMobile={isMobile}
      ref={languageSwitcherRef}
      opened={open}
      onClick={() => setOpen((prev) => !prev)}
    >
      <Icon iconName="language" />
      <ContentWrapper>
        <Content>{language.toUpperCase()}</Content>
        <ArrowWrapper className={open ? "rotate" : ""}>
          <Icon iconName="arrowDown" />
        </ArrowWrapper>
      </ContentWrapper>
      {open && (
        <DropDownContent>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => {
              handleChangeLanguage(Languages.En);
            }}
          >
            <Icon iconName="en" />
            <DropDownTitle>English</DropDownTitle>
          </DropDownItem>
          <DropDownItem
            isMobile={isMobile}
            onClick={() => {
              handleChangeLanguage(Languages.Ge);
            }}
          >
            <Icon iconName="ge" />
            <DropDownTitle>ქართული</DropDownTitle>
          </DropDownItem>
        </DropDownContent>
      )}
    </Wrapper>
  );
};

export default LanguageSwitcher;
