import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactComponent as ChartHolderSvg } from "../../../assets/svgs/chartHolder.svg";

export const Wrapper = styled.div`
  padding: 30px 25px;
  width: 100%;
  height: 100%;
`;

export const Title = styled.h3(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 18px;
    color: ${theme.colors.black};
  `
);
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  height: 100%;
`;

export const IconWrapper = styled.div(
  ({ theme }) => css`
    padding: 20px 15px 10px;
    background-color: ${theme.colors.primary};
    border-radius: 20px;
  `
);

export const Icon = styled(ChartHolderSvg)`
  width: 28px;
  height: 36px;
`;

export const Content = styled.h2(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 20px;
    color: ${theme.colors.black};
    margin: 40px 0 25px;
  `
);

export const SubContent = styled.h4(
  ({ theme }) => css`
    ${theme.fonts.AvenirDemi}
    font-size: 16px;
    color: ${theme.colors.grey};
  `
);
