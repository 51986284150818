import { useState } from "react";
import { DateRangePicker } from "rsuite";
import Button from "../../shared/button/Button";

import {
  ButtonWrapper,
  Calendar,
  Filter,
  Title,
  Wrapper,
  DropDownWrapper,
  YearsDropDownWrapper,
} from "./ReportFilter.style";

import { ReactComponent as CalendarSvg } from "../../../assets/svgs/calendar.svg";
import { useTranslation } from "react-i18next";
import DropDown from "../../shared/dropdown/Dropdown";
import { generatePeriodTypes } from "../../../helpers/helper";
import { PeriodType } from "../../../enums/enum";
import {
  getRangeCurrentMonth,
  getRangeCurrentQuarter,
  getRangeCurrentYear,
  getRangeLastDays,
  getRangePreviousMonth,
  getRangePreviousQuarter,
  getRangePreviousYear,
} from "../../../helpers/dateHelper";
import Dropdown from "../../shared/dropdown/Dropdown";
import moment from "moment";

interface ReportFilterProps {
  dates?: { fromDate: string; toDate: string };
  periodType?: PeriodType;
  years?: Array<{ label: string; value: string }>;
  activeYear?: number;
  handleSubmit: ({
    fromDate,
    toDate,
    periodType,
    year,
  }: {
    fromDate?: string;
    toDate?: string;
    periodType?: PeriodType;
    year?: number;
  }) => void;
}

const ReportFilter = ({
  dates,
  periodType,
  years,
  activeYear,
  handleSubmit,
}: ReportFilterProps) => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({
    fromDate: dates?.fromDate,
    toDate: dates?.toDate,
  });

  const [period, setPeriod] = useState(periodType);
  const [year, setYear] = useState(activeYear);

  return (
    <Wrapper>
      <Title>{t("ReportFilter.Title")}</Title>
      <Filter>
        {Boolean(period) && (
          <DropDownWrapper>
            <DropDown
              title={t("ChartFilter.DateDropDown")}
              options={generatePeriodTypes()}
              selectedValue={(period || "").toString()}
              handleSelect={(value) => {
                setPeriod(value as any);
              }}
            />
          </DropDownWrapper>
        )}
        {dates && (
          <Calendar>
            <DateRangePicker
              caretAs={CalendarSvg}
              showHeader={false}
              character={"-"}
              defaultValue={[new Date(dates.fromDate), new Date(dates.toDate)]}
              placement="bottomEnd"
              format="yyyy/MM/dd"
              placeholder="YYYY/MM/DD-YYYY/MM/DD"
              size="lg"
              ranges={[
                {
                  label: t("ReportFilter.Last30Days"),
                  value: getRangeLastDays(30) as any,
                },
                {
                  label: t("ReportFilter.Last90Days"),
                  value: getRangeLastDays(90),
                },
                {
                  label: t("ReportFilter.Last120Days"),
                  value: getRangeLastDays(120),
                },
                {
                  label: t("ReportFilter.CurrentMonth"),
                  value: getRangeCurrentMonth(),
                },
                {
                  label: t("ReportFilter.CurrentQuarter"),
                  value: getRangeCurrentQuarter(),
                },
                {
                  label: t("ReportFilter.CurrentYear"),
                  value: getRangeCurrentYear(),
                },
                {
                  label: t("ReportFilter.PreviousMonth"),
                  value: getRangePreviousMonth(),
                },
                {
                  label: t("ReportFilter.PreviousQuarter"),
                  value: getRangePreviousQuarter(),
                },
                {
                  label: t("ReportFilter.PreviousYear"),
                  value: getRangePreviousYear(),
                },
              ]}
              onChange={(data) => {
                if (data) {
                  const [fromDate, toDate] = data;
                  try {
                    setDateRange({
                      fromDate: moment(fromDate).format(),
                      toDate: moment(toDate).format(),
                    });
                  } catch (error) {}
                }
              }}
            />
          </Calendar>
        )}
        {years && year && (
          <YearsDropDownWrapper>
            <Dropdown
              title={year.toString()}
              options={years}
              selectedValue={year.toString()}
              handleSelect={(value) => setYear(+value)}
            />
          </YearsDropDownWrapper>
        )}

        <ButtonWrapper>
          <Button
            title={t("ReportFilter.ButtonTitle")}
            type="button"
            onClick={() => {
              handleSubmit({
                fromDate: dateRange.fromDate,
                toDate: dateRange.toDate,
                periodType: period,
                year: year ?? undefined,
              });
            }}
          />
        </ButtonWrapper>
      </Filter>
    </Wrapper>
  );
};

export default ReportFilter;
