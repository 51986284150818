import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div(
  ({ theme }) => css`
    ${theme.fonts.HelveticaNeue}
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.grey100};
    border-bottom: 1px solid ${theme.colors.grey300};
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 16px 32px;
    }
  `
);

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
